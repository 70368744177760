import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import AreasSide from './AreasSide';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../Hooks/useStores';
import theWallDailyImg from '../../../../Resources/Defi/TheWallDaily.svg';
import ApprovedInfoPanel from '../../ApprovedInfoPanel';
import MyAreaEdit from '../../../MyPlaces/MyAreaEdit';
import { RemoteDataState } from '../../../../Utils/RemoteData';
import closeIcon from '../../../../Resources/Icons/close.svg';
import s from '../../../MyPlaces/MyPlaces.scss';

const Areas = () => {
  const history = useHistory();
  const { wallStore, tgDailyStore } = useStores();

  const closeEdit = () => {
    history.push('/defi/daily/areas');
  };

  return (
    <>
      <div className={cx(s.container, s.noPaddingTop)}>
        <div className={s.left}>
          <Switch>
            <Route path="/defi/daily/areas/:id?">
              <div className={s.sections}>
                <AreasSide />
              </div>
            </Route>
          </Switch>
        </div>
        <div className={s.editAreaAndClusterSection}>
          <Switch>
            <Route path="/defi/daily/areas/:id">
              <div className={s.right}>
                <div className={s.closeIcon} onClick={closeEdit}>
                  <img src={closeIcon} alt="Close" />
                </div>
                <MyAreaEdit />
              </div>
            </Route>
          </Switch>
        </div>
      </div>
      {tgDailyStore.configuration.state === RemoteDataState.SUCCESS && (
        <ApprovedInfoPanel
          contractAddress={wallStore.dailyContractAddress}
          logoImg={theWallDailyImg}
          text="thewall_daily_protocol_requires_permission"
        />
      )}
    </>
  );
};

export default observer(Areas);
