import React, { useContext, useEffect } from 'react';
import s from './TheWall.scss';
import {
  Button,
  ButtonBase,
  CircularProgress,
  Typography
} from '@material-ui/core';
import closeIcon from '../../Resources/Icons/close.svg';
import { oc } from 'ts-optchain';
import { InfoPositionType, SIZE_AREA } from './TheWall';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Hooks/useStores';
import { useObserver } from 'mobx-react-lite';
import AppStateContext from '../../Contexts/AppStateContext';
import Web3 from 'web3';
import { areaInBorderCheck } from '../../Utils/borderUtils';

interface EmptyAreaInfo {
  infoModalState: InfoPositionType;
  closeInfoModal: () => void;
  handleClusterSelect: () => void;
}

const EmptyAreaInfo: React.FC<EmptyAreaInfo> = ({
  infoModalState,
  closeInfoModal,
  handleClusterSelect
}) => {
  const { t } = useTranslation();
  const { wallStore, tgWallStore } = useStores();
  const { readOnly } = useContext(AppStateContext);

  useEffect(() => {
    wallStore.getCryptaurTWG();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBuy = async () => {
    await wallStore.buyEmptyArea(
      oc(infoModalState).boxCoords.x(0) / SIZE_AREA,
      oc(infoModalState).boxCoords.y(0) / SIZE_AREA,
      Number(oc(tgWallStore).user.value.user.coupons('0')),
      oc(tgWallStore).wall.value.areaCost('0')
    );
    closeInfoModal();
  };

  return useObserver(() => {
    const coupons = Number(oc(tgWallStore).user.value.user.coupons('0'));
    const cryptaurTWG = oc(wallStore).cryptaurTWG(0);
    let additionalToken = '';
    if (
      cryptaurTWG > 0 &&
      areaInBorderCheck(
        oc(infoModalState).boxCoords.x(0) / SIZE_AREA,
        oc(infoModalState).boxCoords.y(0) / SIZE_AREA,
        wallStore.wallBorder
      )
    ) {
      additionalToken = '1 CryptaurTWG';
    } else if (coupons) {
      additionalToken = '1 TWG';
    }
    const areaCost = additionalToken
      ? 0
      : parseFloat(
          Web3.utils.fromWei(oc(tgWallStore).wall.value.areaCost('0'))
        );

    return (
      <div
        className={s.infoContainer}
        style={{ top: infoModalState.top, left: infoModalState.left }}
      >
        <div className={s.top}>
          <Typography variant="h6" gutterBottom>
            {t('empty_area')}
          </Typography>
          <ButtonBase disableRipple onClick={closeInfoModal}>
            <img src={closeIcon} className={s.closeIcon} alt="Close" />
          </ButtonBase>
        </div>
        <div className={s.coords}>
          <div>
            <Typography color="textSecondary">X</Typography>
            <Typography>
              {oc(infoModalState).boxCoords.x(0) / SIZE_AREA}
            </Typography>
          </div>
          <div>
            <Typography color="textSecondary">Y</Typography>
            <Typography>
              {oc(infoModalState).boxCoords.y(0) / SIZE_AREA}
            </Typography>
          </div>
        </div>
        <div className={s.buttons}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleBuy}
            disabled={readOnly || wallStore.isCreateRequesting}
          >
            {wallStore.isCreateRequesting ? (
              <CircularProgress size={16} color="secondary" />
            ) : (
              <Typography variant="body2">
                <strong>{t('sell_area')}</strong> |{' '}
                {additionalToken ? additionalToken : `${areaCost} MATIC`}
              </Typography>
            )}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            onClick={handleClusterSelect}
            disabled={readOnly}
          >
            <Typography variant="body2">
              <strong>{t('add_areas_make_cluster')}</strong>
            </Typography>
          </Button>
        </div>
      </div>
    );
  });
};

export default EmptyAreaInfo;
