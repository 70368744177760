/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import cx from 'classnames';
import s from './Search.scss';
import { ButtonBase, Input } from '@material-ui/core';
import searchIcon from '../../../Resources/Icons/search.svg';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import closeIcon from '../../../Resources/Icons/close.svg';

interface SearchProps {
  onSearch: (query: string) => void;
}

const Search: React.FC<SearchProps> = ({ onSearch }) => {
  return useObserver(() => {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const [searchMode, setSearchMode] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
    };

    const handleSearch = () => {
      if (!query) return;
      setSearchMode(true);
      onSearch(query);
    };

    const closeSearch = () => {
      setSearchMode(false);
      setQuery('');
      onSearch('');
    };

    const checkKeyInput = (e: any) => {
      if (e.keyCode === 13) {
        handleSearch();
      }
    };

    // @ts-ignore
    // @ts-ignore
    return (
      <>
        <div className={cx(s.container, searchMode && s.active)}>
          <div className={cx(s.searchContainer, searchMode && s.active)}>
            <Input
              value={query}
              onChange={(e: any) => handleChange(e)}
              onKeyDown={checkKeyInput}
              placeholder={t('search_nickname_address')}
              classes={{
                root: s.input
              }}
            />
            <div className={s.buttons}>
              <ButtonBase centerRipple onClick={handleSearch}>
                <img src={searchIcon} alt={t('search')} />
              </ButtonBase>
            </div>
          </div>
          {searchMode && (
            <div className={s.resultContainer}>
              {t('searching_results')}
              <ButtonBase
                disableRipple
                onClick={closeSearch}
                className={s.closeIcon}
              >
                <img src={closeIcon} alt="Close" />
              </ButtonBase>
            </div>
          )}
        </div>
      </>
    );
  });
};

export default Search;
