import React from 'react';
import { Link } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import s from './DefiItem.scss';

interface DefiItemProps {
  title: string;
  text: string;
  to: string;
  image: any;
}

const DefiItem: React.FC<DefiItemProps> = ({ title, text, to, image }) => {
  return to ? (
    <Link to={to} underline="none" component={RouterLink}>
      <div className={cx(s.main, !text && s.add)}>
        <div>
          {image ? (
            <img src={image} alt="" />
          ) : (
            <div className={s.faQuestionCircle} />
          )}
        </div>
        <div>
          <Typography variant="h5">{title}</Typography>
          {text ? (
            <Typography variant="body2">{text}</Typography>
          ) : (
            <div className={s.backBlur}>
              <Typography variant="h5">The next step</Typography>
              <Typography variant="body2">
                The next generation social network that breaks language barriers
                between its members.
              </Typography>
            </div>
          )}
        </div>
      </div>
    </Link>
  ) : (
    <div className={cx(s.main, s.disabled, !text && s.add)}>
      <div>
        {image ? (
          <img src={image} alt="" />
        ) : (
          <div className={s.faQuestionCircle} />
        )}
      </div>
      <div>
        <Typography variant="h5">{title}</Typography>
        {text ? (
          <Typography variant="body2">{text}</Typography>
        ) : (
          <div className={s.backBlur}>
            <Typography variant="h5">The next step</Typography>
            <Typography variant="body2">
              The next generation social network that breaks language barriers
              between its members.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default DefiItem;
