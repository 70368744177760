import React, { useEffect, useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Typography, Button, ButtonGroup } from '@material-ui/core';
import { oc } from 'ts-optchain';
import { useStores } from '../../../../Hooks/useStores';
import ItemBar from '../../../../Components/Defi/ItemBar/ItemBar';
import DescriptionPanel from '../../../../Components/Defi/DescriptionPanel/DescriptionPanel';
import getAddress from '../../../../Utils/getAddress';
import { UPDATE_INTERVAL } from '../TheWallTgDaily';
import { RemoteDataState } from '../../../../Utils/RemoteData';
import AppStateContext from '../../../../Contexts/AppStateContext';
import s from './Dashboard.scss';
import sDefi from '../../Defi.scss';

type itemDashboardType = {
  title: string;
  shortTitle: string;
  key: string;
  note: string;
  value: string | number;
};

interface BottomItemProps {
  itemDashboard: itemDashboardType;
  value: itemDashboardType;
  setValue(itemDashboard: itemDashboardType): void;
}

const ButtonItem: React.FC<BottomItemProps> = ({
  itemDashboard,
  value,
  setValue
}) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => setValue(itemDashboard)}
      variant={value.key === itemDashboard.key ? 'contained' : 'outlined'}
      color={value.key === itemDashboard.key ? 'primary' : 'default'}
    >
      {t(itemDashboard.shortTitle)}
    </Button>
  );
};

const totalItemsData: itemDashboardType[] = [
  {
    title: 'daily_total_areas_in_pull',
    shortTitle: 'daily_total_areas_in_pull',
    key: '0',
    note: '',
    value: '-'
  },
  {
    title: 'daily_total_withdrawn_profit',
    shortTitle: 'daily_total_withdrawn_profit_short',
    key: '1',
    note: 'matic',
    value: '-'
  },
  {
    title: 'daily_total_profitIn_pool',
    shortTitle: 'daily_total_profitIn_pool_short',
    key: '2',
    note: 'matic',
    value: '-'
  }
];

const userItemsData: itemDashboardType[] = [
  {
    title: 'daily_my_areas_in_pull',
    shortTitle: 'daily_my_areas_in_pull_short',
    key: '0',
    note: '',
    value: '-'
  },
  {
    title: 'daily_my_withdrawn_profit',
    shortTitle: 'daily_my_withdrawn_profit_short',
    key: '1',
    note: 'matic',
    value: '-'
  },
  {
    title: 'daily_myl_profitIn_pool',
    shortTitle: 'daily_myl_profitIn_pool_short',
    key: '2',
    note: 'matic',
    value: '-'
  }
];

interface DashboardProps {}
const Dashboard: React.FC<DashboardProps> = () => {
  const { t } = useTranslation();
  const { wallStore, tgDailyStore } = useStores();
  const [totalItems, setTotalItems] = useState(totalItemsData);
  const [currentValue, setCurrentValue] = useState(totalItems[1]);
  const [userItems, setUserItems] = useState(userItemsData);
  const [userCurrentValue, setUserCurrentValue] = useState(userItems[1]);
  const [updateDashboard, setUpdateDashboard] = useState(0);
  const readOnly =
    useContext(AppStateContext).readOnly || !wallStore.networkVersionTrue;

  useEffect(() => {
    setTimeout(() => {
      setUpdateDashboard(i => i + 1);
    }, UPDATE_INTERVAL);
  }, [updateDashboard]);

  useEffect(() => {
    if (tgDailyStore.configuration.state === RemoteDataState.SUCCESS) {
      tgDailyStore.getStatistics(); // for Total funds in the pool, for Areas in pool and for Total withdrawn income
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgDailyStore.configuration.state, updateDashboard]);

  useEffect(() => {
    if (!readOnly && wallStore.theWallDailyContact) {
      tgDailyStore.getProviderData(getAddress()); // for My withdrawn income and for My areas in the pool
      wallStore.getDailyBalance(); // for calculate My funds in the pool
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wallStore.theWallDailyContact,
    wallStore.currentdAddress,
    updateDashboard,
    readOnly
  ]);

  useEffect(() => {
    if (tgDailyStore.statistics.state !== RemoteDataState.SUCCESS) return;
    const poolsize = tgDailyStore.statistics.value?.poolsize;
    const balance = tgDailyStore.statistics.value?.balance;
    const profitTotal = tgDailyStore.statistics.value?.profitWithdrawn;

    setTotalItems(data => {
      return data.map(i => {
        if (i.key === '0') {
          return {
            ...i,
            value: Number(poolsize) >= 0 ? String(poolsize) : '-'
          };
        }
        if (i.key === '1') {
          return {
            ...i,
            value:
              Number(profitTotal) >= 0
                ? Math.round(Number(profitTotal) / Math.pow(10, 14)) /
                  Math.pow(10, 4)
                : '-'
          };
        }
        if (i.key === '2') {
          return {
            ...i,
            value:
              Number(balance) >= 0
                ? Math.round(Number(balance) / Math.pow(10, 14)) /
                  Math.pow(10, 4)
                : '-'
          };
        }
        return i;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgDailyStore.statistics.state]);

  useEffect(() => {
    if (!readOnly && wallStore.theWallDailyContact && wallStore.dailyTotalLPT) {
      // for My funds in the pool
      wallStore.getDailyUserAreasInPool().then(userBalance => {
        if (userBalance || userBalance === 0) {
          setUserItems(data => {
            return data.map(i => {
              if (i.key === '2') {
                return {
                  ...i,
                  value:
                    Math.round(userBalance / Math.pow(10, 14)) / Math.pow(10, 4)
                };
              }
              return i;
            });
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wallStore.theWallDailyContact,
    wallStore.currentdAddress,
    wallStore.dailyTotalLPT,
    updateDashboard,
    readOnly
  ]);

  useEffect(() => {
    if (tgDailyStore.providerData.state !== RemoteDataState.SUCCESS || readOnly)
      return;

    const profitUser = oc(tgDailyStore).providerData.value.profitWithdrawn('');
    const areasCount = oc(tgDailyStore).providerData.value.areas(0);

    setUserItems(data => {
      return data.map(i => {
        if (i.key === '0') {
          return {
            ...i,
            value: areasCount
          };
        }
        if (i.key === '1') {
          return {
            ...i,
            value:
              Number(profitUser) >= 0
                ? Math.round(Number(profitUser) / Math.pow(10, 14)) /
                  Math.pow(10, 4)
                : '-'
          };
        }
        return i;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgDailyStore.providerData.state, readOnly]);

  useEffect(() => {
    setCurrentValue(i => {
      const item = totalItems.find(j => j.key === i.key);
      if (item) {
        return item;
      }
      return i;
    });
  }, [totalItems]);

  useEffect(() => {
    setUserCurrentValue(i => {
      const item = userItems.find(j => j.key === i.key);
      if (item) {
        return item;
      }
      return i;
    });
  }, [userItems]);

  return (
    <div className={s.main}>
      <div className={s.left}>
        <div className={s.pageBar}>
          {totalItems.map(i => (
            <ItemBar
              title={t(i.title)}
              value={i.value}
              note={t(i.note)}
              key={i.key}
            />
          ))}
        </div>
        {!readOnly && (
          <div className={s.pageBar}>
            {userItems.map(i => (
              <ItemBar
                title={t(i.title)}
                value={i.value}
                note={t(i.note)}
                key={i.key}
              />
            ))}
          </div>
        )}
        <div className={s.pageBarMobile}>
          <div className={s.bastBtnGroup}>
            <ButtonGroup>
              {totalItems.map(i => (
                <ButtonItem
                  itemDashboard={i}
                  value={currentValue}
                  setValue={setCurrentValue}
                  key={i.key}
                />
              ))}
            </ButtonGroup>
          </div>
          <Typography variant="h6">
            {currentValue.value} <small>{t(currentValue.note)}</small>
          </Typography>
        </div>
        {!readOnly && (
          <div className={s.pageBarMobile}>
            <div className={s.bastBtnGroup}>
              <ButtonGroup>
                {userItems.map(i => (
                  <ButtonItem
                    itemDashboard={i}
                    value={userCurrentValue}
                    setValue={setUserCurrentValue}
                    key={i.key}
                  />
                ))}
              </ButtonGroup>
            </div>
            <Typography variant="h6">
              {userCurrentValue.value} <small>{userCurrentValue.note}</small>
            </Typography>
          </div>
        )}
      </div>
      <div className={sDefi.rightPageContent}>
        <DescriptionPanel content={t('daily_dashboard_description')} />
      </div>
    </div>
  );
};

export default observer(Dashboard);
