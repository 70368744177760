import React from 'react';
import s from './Marketplace.scss';
import { observer } from 'mobx-react-lite';
import { Route, Switch, Redirect } from 'react-router-dom';
import { TitleLink } from '../MyPlaces/MyPlaces';
import { useTranslation } from 'react-i18next';
import MarketplaceClusters from './MarketplaceClusters';
import MarketplaceAreas from './MarketplaceAreas';
import {
  BuyErrorDialog,
  BuySuccessDialog,
  RentOutErrorDialog,
  RentOutSuccessDialog
} from '../Dialogs/Dialogs';

const Marketplace = () => {
  const { t } = useTranslation();

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.top}>
          <TitleLink to="/marketplace/clusters" title={t('clusters')} />
          <TitleLink to="/marketplace/areas" title={t('areas')} />
        </div>
        <Switch>
          <Route path="/marketplace/clusters">
            <MarketplaceClusters />
          </Route>
          <Route path="/marketplace/areas">
            <MarketplaceAreas />
          </Route>
          <Route path="/marketplace">
            <Redirect to="/marketplace/clusters" />
          </Route>
        </Switch>
      </div>
      <RentOutErrorDialog />
      <RentOutSuccessDialog />
      <BuySuccessDialog />
      <BuyErrorDialog />
    </div>
  );
};

export default observer(Marketplace);
