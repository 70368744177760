import { action, observable } from 'mobx';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { createRemoteData, handleTgRemoteData } from '../Utils/RemoteData';
import { ethers } from 'ethers';

// const URI = 'https://api.thegraph.com/subgraphs/name/isvirin/the-wall-loan'; // prod
const URI =
  'https://api.thegraph.com/subgraphs/name/isvirin/the-wall-loan-mumbai'; // test

const OFFERS_FIELDS = `id
rev
status
lender
loan_wei
refund_wei
duration
x1
y1
x2
y2
borrower
area_id
deadline`;

class TgLoanStore {
  client = new ApolloClient({
    uri: URI,
    cache: new InMemoryCache()
  });

  @observable configuration = createRemoteData<LoanTgConfigurationType>();
  @observable statistics = createRemoteData<LoanTgStatisticsType>();
  @observable offers = createRemoteData<LoanTgOffersType[]>([]);
  @observable lenderOffers = createRemoteData<LoanTgOffersType[]>([]);
  @observable borrowerOffers = createRemoteData<LoanTgOffersType[]>([]);
  @observable availableOffers = createRemoteData<LoanTgOffersType[]>([]);
  @observable providers: UserTgType[] = [];

  constructor() {
    this.getConfiguration();
  }

  @action
  async getConfiguration() {
    return handleTgRemoteData(
      this.configuration,
      async () =>
        this.client.query({
          query: gql`
            query getLoanConfiguration {
              configurations(id: "0") {
                id
                contract
              }
            }
          `
        }),
      data => {
        const result = (data as { configurations: LoanTgConfigurationType[] })
          .configurations[0];
        return result;
      }
    );
  }

  @action
  async getStatistics() {
    this.client.cache.reset();
    return handleTgRemoteData(
      this.statistics,
      async () =>
        this.client.query({
          query: gql`
            query getLoanStatistics {
              statistics(id: "0") {
                id
                rev
                currently_available_liquidity
                currently_to_refund
                total_borrower_default
                total_loan_amount
                total_revoked_amount
                total_profit
              }
            }
          `
        }),
      data => (data as { statistics: LoanTgStatisticsType }).statistics
    );
  }

  @action
  async getLenderOffers(lender: string) {
    this.client.cache.reset();
    const available: StatusTgLoanOffer = 'Available';
    const accepted: StatusTgLoanOffer = 'Accepted';
    const overdue: StatusTgLoanOffer = 'Overdue';
    return handleTgRemoteData(
      this.lenderOffers,
      async () =>
        this.client.query({
          query: gql`
            query lenderOffers($lender: Bytes, $available: Status, $accepted: Status, $overdue: Status) {
              offers(where: {lender_in: [$lender], status_in: [$available, $accepted, $overdue]}) {
                ${OFFERS_FIELDS}
              }
            }
          `,
          variables: { lender, available, accepted, overdue }
        }),
      data => (data as { offers: LoanTgOffersType[] }).offers
    );
  }

  @action
  async getBorrowerOffers(borrower: String) {
    this.client.cache.reset();
    const accepted: StatusTgLoanOffer = 'Accepted';
    const overdue: StatusTgLoanOffer = 'Overdue';
    return handleTgRemoteData(
      this.borrowerOffers,
      async () =>
        this.client.query({
          query: gql`
            query lenderOffers($borrower: Bytes, $accepted: Status, $overdue: Status) {
              offers(where: {borrower_in: [$borrower], status_in: [$accepted, $overdue]}) {
                ${OFFERS_FIELDS}
              }
            }
          `,
          variables: { borrower, accepted, overdue }
        }),
      data => (data as { offers: LoanTgOffersType[] }).offers
    );
  }

  @action
  async getOffers(params: { orderBy: string; status: StatusTgLoanOffer }) {
    this.client.cache.reset();
    return handleTgRemoteData(
      this.offers,
      async () =>
        this.client.query({
          query: gql`
            query offers($status: Status) {
              offers(where: { status: $status }) {
                ${OFFERS_FIELDS}
              }
            }
          `,
          variables: { status: params.status }
        }),
      data => {
        const offers = (data as { offers: LoanTgOffersType[] }).offers;
        const result = [...offers];
        if (params.orderBy === 'loan') {
          result.sort((a, b) => (+a.loan_wei < +b.loan_wei ? 1 : -1));
          return result;
        }
        if (params.orderBy === 'rate') {
          result.sort((a, b) => {
            if (+a.refund_wei >= 0 && +b.refund_wei >= 0) {
              return (+a.duration * +a.loan_wei) / +a.refund_wei <
                (+b.duration * +b.loan_wei) / +b.refund_wei
                ? 1
                : -1;
            }
            return 1;
          });
          return result;
        }
        return result;
      }
    );
  }

  @action
  async getTopProviders(users: UserTgType[]) {
    const status: StatusTgLoanOffer = 'Available';
    this.client.cache.reset();
    return handleTgRemoteData(
      this.availableOffers,
      async () =>
        this.client.query({
          query: gql`
            query offers($status: Status) {
              offers(where: { status: $status }) {
                lender
                loan_wei
              }
            }
          `,
          variables: { status }
        }),
      data => {
        const offers = (data as { offers: LoanTgOffersType[] }).offers;
        const providers: UserTgType[] = [];
        offers.forEach(i => {
          const provider = providers.find(j => j.id === i.lender);
          if (!provider) {
            const user = users.find(u => u.id === i.lender);
            if (user) {
              const score: BigInt = offers
                .filter(c => c.lender === user.id)
                .reduce((p, c) => p + BigInt(c.loan_wei), BigInt(0));
              providers.push({
                ...user,
                score: +ethers.utils.formatUnits(String(score), 18)
              });
            }
          }
        });
        providers.sort((a, b) => (a.score < b.score ? 1 : -1));
        let rank = 1;
        this.providers = providers.map((i, index) => {
          if (index > 0) {
            rank = i.score < providers[index - 1].score ? rank + 1 : rank;
          }
          return {
            ...i,
            rank
          };
        });
        return offers;
      }
    );
  }
}

export default TgLoanStore;
