import React, { useRef } from 'react';
import Popper from '@material-ui/core/Popper';
import { useTranslation } from 'react-i18next';
import { Typography, Link, Button } from '@material-ui/core';
import closeIcon from '../../Resources/Icons/close.svg';
import cx from 'classnames';
import s from './ExternalNftPopper.scss';

interface ExternalNftPopperProps {
  externalNFT: ExternalTokenTgType;
  option?: string;
}

const ExternalNftPopper: React.FC<ExternalNftPopperProps> = ({
  externalNFT,
  option
}) => {
  const { t } = useTranslation();

  const containerRef = useRef(null);

  const { contract, tokenId } = externalNFT;
  const shortAddres = `${contract.slice(0, 6)}...${contract.slice(-4)}`;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div
      ref={containerRef}
      className={cx(s.popperContainer, option && s[option])}
    >
      <Button onClick={handleClick} variant="outlined">
        {t('attached_nft')}
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={s.content}
        placement={option ? 'top-start' : 'bottom-start'}
        container={containerRef.current}
      >
        <div className={s.closeIcon} onClick={handleClick}>
          <img src={closeIcon} alt="Close" />
        </div>
        <div className={s.row}>
          <Typography variant="body2">{t('contract_address')}</Typography>
          <Link
            href={`https://polygonscan.com/address/${contract}`}
            variant="body2"
            target="_blank"
          >
            {shortAddres}
          </Link>
        </div>
        <div className={s.row}>
          <Typography variant="body2">{t('token_id')}</Typography>
          <Typography variant="body2">{tokenId}</Typography>
        </div>
        {/* <div className={s.row}>
          <Link
            href={externalNFT.uri}
            variant="body2"
            target="_blank"
            className={s.uri}
          >
            {externalNFT.uri}
          </Link>
        </div> */}
        {/* <div className={s.row}>
          <Typography variant="body2">{description}</Typography>
          <div className={s.image}>
            <img src={image} alt="" />
          </div>
        </div> */}
      </Popper>
    </div>
  );
};

export default ExternalNftPopper;
