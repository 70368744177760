/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import s from '../../../Rating/Rating.scss';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RatingItem from '../../../Rating/RatingItem/RatingItem';
import InfiniteScroll from 'react-infinite-scroller';
import AppStateContext, {
  MetamaskState
} from '../../../../Contexts/AppStateContext';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../Hooks/useStores';
import { oc } from 'ts-optchain';
import Search from '../../../Rating/Search/Search';
import { RemoteDataState } from '../../../../Utils/RemoteData';

const Rating = () => {
  const { t } = useTranslation();
  const [filterStr, setFilterStr] = useState('');
  const { wallStore, tgWallStore, tgDailyStore } = useStores();
  const { pluginState } = useContext(AppStateContext);

  useEffect(() => {
    tgWallStore.getUpdateUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tgWallStore.users.state === RemoteDataState.SUCCESS) {
      tgDailyStore.getTopProviders(oc(tgWallStore).users.value.users([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgWallStore.users.state]);

  const users = tgDailyStore.providers;

  const loadMore = async () => {
    await tgWallStore.getUpdateUsers();
  };

  const handleSearch = (query: string) => {
    setFilterStr(query);
  };

  const filterUser = (c: UserTgType) => {
    if (filterStr === '') return true;
    const filterStrLower = filterStr.toLowerCase();
    return (
      (c.id && c.id.toLowerCase().indexOf(filterStrLower) >= 0) ||
      (c.nickname && c.nickname.toLowerCase().indexOf(filterStrLower) >= 0)
    );
  };

  return (
    <>
      <Search onSearch={handleSearch} />
      <div className={s.container} style={{ height: 'calc(100vh - 220px)' }}>
        <Typography variant="h4" classes={{ h4: s.header }}>
          {t('top_providers')}
        </Typography>
        <div className={s.list}>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={false}
            useWindow={false}
            threshold={1000}
            loader={
              <Typography className={s.loader} key={0}>
                Loading ...
              </Typography>
            }
          >
            {users
              .filter(c => filterUser(c))
              .map(c => (
                <RatingItem
                  value={c.score}
                  unit={t('areas').toLowerCase()}
                  address={c.id}
                  index={c.rank}
                  primary={c.rank === 1}
                  key={c.id}
                  nickname={c.nickname}
                  avatarCID={c.avatarCID}
                  my={
                    pluginState === MetamaskState.Connected &&
                    c.id === wallStore.currentdAddress
                  }
                  currentUser={tgWallStore.user.value?.user}
                />
              ))}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default observer(Rating);
