import Konva from 'konva';

export const renderImage = (
  layer: Konva.Layer | Konva.Group,
  url: OriginalImage,
  zoomLevel: number,
  x: number,
  y: number,
  width?: number,
  height?: number,
  imageProps?: any,
  draw: boolean = true
) => {
  return new Promise(resolve => {
    Konva.Image.fromURL(url.path, (node: Konva.Image) => {
      node.setAttrs({
        x,
        y,
        width,
        height,
        strokeWidth: 0,
        hitStrokeWidth: 0,
        stroke: '#001AFF',
        id: url.path,
        scaleY: -1,
        ...imageProps
      });

      layer.add(node);
      draw && layer.draw();
      resolve(node);
    });
  });
};
