import React from 'react';
import { Link } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import s from './Header.scss';

interface HeaderLinkProps {
  to: string;
  title: string;
  exact?: boolean;
}

const HeaderLink: React.FC<HeaderLinkProps> = ({ to, title, exact }) => (
  <Link
    to={to}
    activeClassName={s.active}
    underline="none"
    variant="h6"
    color="textPrimary"
    component={RouterLink}
    exact={exact}
  >
    {title}
  </Link>
);

export default HeaderLink;
