import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HowToSetImageCluster from './Pages/HowToSetImageCluster';
import HowToCombineAreasCluster from './Pages/HowToCombineAreasCluster';
import HowToSetAvatarUsername from './Pages/HowToSetAvatarUsername';
import MetamaskSetupSmartphone from './Pages/MetamaskSetupSmartphone';
import HowToBuyArea from './Pages/HowToBuyArea';
import Polygon from './Pages/Polygon';
import MetamaskSetup from './Pages/MetamaskSetup';
import TwgGuide from './Pages/TwgGuide';
import Smartcontracts from './Pages/Smartcontracts';
import WhereToBuyMaticCoins from './Pages/WhereToBuyMaticCoins';

import InstructionMenu from './InstructionMenu';

const Instructions = () => {
  return (
    <div>
      <Switch>
        <Route path="/instructions/where-to-buy-matic-coins">
          <WhereToBuyMaticCoins />
        </Route>
        <Route path="/instructions/smartcontracts-of-the-wall-global-project">
          <Smartcontracts />
        </Route>
        <Route path="/instructions/how-to-set-avatar-and-username">
          <HowToSetAvatarUsername />
        </Route>
        <Route path="/instructions/how-to-combine-areas-cluster">
          <HowToCombineAreasCluster />
        </Route>
        <Route path="/instructions/how-to-set-image-cluster">
          <HowToSetImageCluster />
        </Route>
        <Route path="/instructions/how-to-buy-area">
          <HowToBuyArea />
        </Route>
        <Route path="/instructions/polygon">
          <Polygon />
        </Route>
        <Route path="/instructions/metamask-setup-smartphone">
          <MetamaskSetupSmartphone />
        </Route>
        <Route path="/instructions/metamask-setup">
          <MetamaskSetup />
        </Route>
        <Route path="/instructions/twg-guide">
          <TwgGuide />
        </Route>
        <Route path="/instructions">
          <InstructionMenu />
        </Route>
      </Switch>
    </div>
  );
};

export default Instructions;
