import React from 'react';
import s from './MyPlaces.scss';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  NavLink as RouterLink,
  Switch,
  Route,
  useHistory,
  Redirect
} from 'react-router-dom';
import MyAreas from './MyAreas';
import MyClusters from './MyClusters';
import MyAreaEdit from './MyAreaEdit';
import MyClusterEdit from './MyClusterEdit';
import closeIcon from '../../Resources/Icons/close.svg';

interface TitleLinkProps {
  to: string;
  title: string;
}

export const TitleLink: React.FC<TitleLinkProps> = ({ to, title }) => {
  return (
    <Link
      to={to}
      activeClassName={s.active}
      underline="none"
      variant="h5"
      color="textPrimary"
      component={RouterLink}
      TypographyClasses={{ h5: s.nav }}
    >
      {title}
    </Link>
  );
};

const MyPlaces = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const closeEdit = () => {
    history.push('/my/areas');
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.top}>
          <TitleLink to="/my/clusters" title={t('clusters')} />
          <TitleLink to="/my/areas" title={t('areas')} />
        </div>
        <div className={s.sections}>
          <Switch>
            <Route path="/my/areas/:id?">
              <MyAreas />
            </Route>
            <Route path="/my/clusters/:id?">
              <MyClusters />
            </Route>
            <Route path="/my">
              <Redirect to="/my/clusters" />
            </Route>
          </Switch>
        </div>
      </div>
      <div className={s.editAreaAndClusterSection}>
        <Switch>
          <Route path="/my/areas/:id">
            <div className={s.right}>
              <div className={s.closeIcon} onClick={closeEdit}>
                <img src={closeIcon} alt="Close" />
              </div>
              <MyAreaEdit />
            </div>
          </Route>
          <Route path="/my/clusters/:id">
            <MyClusterEdit />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default MyPlaces;
