import React, { useContext, useEffect } from 'react';
import s from './TheWall.scss';
import { ButtonBase, Chip, Link, Typography } from '@material-ui/core';
import closeIcon from '../../Resources/Icons/close.svg';
import { oc } from 'ts-optchain';
import cx from 'classnames';
import { useStores } from '../../Hooks/useStores';
import RentButton from '../../Components/RentButton/RentButton';
import { InfoPositionType } from './TheWall';
import { useTranslation } from 'react-i18next';
import AppStateContext from '../../Contexts/AppStateContext';
import { bindToggle } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import ActionsPopup from '../MyPlaces/Popups/ActionsPopup/ActionsPopup';
import ActionsPopupLink from '../MyPlaces/Popups/ActionsPopup/ActionsPopupLink';
import ExternalNftPopper from '../../Components/ExternalNftPopper/ExternalNftPopper';

interface OwnerAreaInfoProps {
  infoModalState: InfoPositionType;
  activeArea?: AreaTgType;
  handleRent: () => void;
  closeInfoModal: () => void;
  allTags: TagTypeTg[];
}

const OwnerAreaInfo: React.FC<OwnerAreaInfoProps> = ({
  infoModalState,
  handleRent,
  activeArea,
  closeInfoModal,
  allTags
}) => {
  const { t } = useTranslation();
  const { readOnly } = useContext(AppStateContext);
  const { tgWallStore } = useStores();
  const userAreasIds = oc(tgWallStore).user.value.user.areas([]);
  const userClustersIds = oc(tgWallStore).user.value.user.clusters([]);

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'editmenu'
  });

  const popupLinkState = usePopupState({
    variant: 'popper',
    popupId: 'editmenu'
  });

  const isRentedOut =
    activeArea?.item.status === 'Rented' &&
    oc(activeArea).item.tenant.id('') !== tgWallStore.userAddress;

  const isMyRentedOut =
    activeArea?.item.status === 'Rented' &&
    oc(activeArea).item.tenant.id('') === tgWallStore.userAddress;

  const clusterId = activeArea?.cluster?.id;
  const areaId = !activeArea || clusterId ? undefined : activeArea.id;

  const item = clusterId ? activeArea?.cluster?.item : activeArea?.item;

  const userResources = Boolean(
    userClustersIds.find(i => i === clusterId) ||
      userAreasIds.find(i => i === areaId) ||
      isMyRentedOut
  );
  let disabled = false;
  if (clusterId) {
    disabled = activeArea?.item.status === 'Rented';
  } else if (areaId) {
    disabled = isRentedOut || activeArea?.item.status === 'Rented';
  }

  useEffect(() => {
    popupState.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId, clusterId]);

  const user = oc(tgWallStore)
    .users.value.users([])
    .find(i => i.id === activeArea?.item.owner.id);

  return (
    <div
      className={cx(s.infoContainer, popupState.isOpen && s.setting)}
      style={{ top: infoModalState.top, left: infoModalState.left }}
    >
      <ButtonBase
        disableRipple
        onClick={closeInfoModal}
        className={s.closeButtonSticky}
      >
        <img src={closeIcon} alt="Close" />
      </ButtonBase>
      {activeArea && (
        <div className={s.titleAreaCluster}>
          <ActionsPopup
            popupState={popupState}
            areaId={areaId}
            clusterId={clusterId}
            disabled={disabled}
            option="infoContainer"
            toSetting={`/my/${clusterId ? `clusters` : `areas`}/${clusterId ||
              areaId}`}
          />
          <ActionsPopupLink
            popupState={popupLinkState}
            areaId={areaId}
            clusterId={clusterId}
          />
          <div className={s.infoArea}>
            <Typography variant="h6" gutterBottom>
              {clusterId
                ? `${t('cluster_for_published')} #${clusterId} `
                : `${t('area_for_published')} #${areaId}`}
            </Typography>
          </div>
          {userResources ? (
            <ButtonBase
              {...bindToggle(popupState)}
              disableRipple
              className={s.btnDots}
            >
              <div className={s.dots}>
                <div className={s.dot} />
                <div className={s.dot} />
                <div className={s.dot} />
              </div>
            </ButtonBase>
          ) : (
            <ButtonBase
              {...bindToggle(popupLinkState)}
              disableRipple
              className={s.btnDots}
            >
              <div className={cx(s.dots, s.notMine)}>
                <div className={s.dot} />
                <div className={s.dot} />
                <div className={s.dot} />
              </div>
            </ButtonBase>
          )}
        </div>
      )}
      {user && (
        <div className={s.user}>
          <Link
            href={`https://polygonscan.com/address/${user.id}`}
            variant="body1"
            target="_blank"
          >
            {user.nickname ||
              `${user.id.slice(0, 6)}...
            ${user.id.slice(-4)}`}
          </Link>{' '}
          <Typography variant="body2">
            {t('rank')}: {user.rank}
          </Typography>
        </div>
      )}
      <div className={cx(s.coords, s.owned)}>
        <div>
          <Typography color="textSecondary">X</Typography>
          <Typography>{oc(activeArea).x('0')}</Typography>
        </div>
        <div>
          <Typography color="textSecondary">Y</Typography>
          <Typography>{oc(activeArea).y('0')}</Typography>
        </div>
      </div>
      <div className={s.tags}>
        {(oc(item).tags([]).length > 0
          ? oc(item).tags([])
          : [t('no_tags')]
        ).map(tag => {
          const fTag = allTags.find(i => i.id === tag);
          if (!fTag || fTag?.censored) return null;
          return <Chip label={tag} key={tag} />;
        })}
      </div>
      <Typography gutterBottom>{oc(item).title(t('no_content'))}</Typography>
      {item?.link ? (
        <Link
          href={item.link}
          variant="body2"
          color="primary"
          gutterBottom
          target="_blank"
          classes={{ root: s.link }}
        >
          {item.link}
        </Link>
      ) : (
        <div />
      )}
      {areaId && activeArea?.externalToken ? (
        <ExternalNftPopper
          externalNFT={activeArea?.externalToken}
          option="infoContainer"
        />
      ) : null}
      {item?.id && (
        <Link
          variant="body2"
          href={`mailto:info@thewall.global?subject=Report%20to%20ID%20${item.id}`}
          className={s.report}
        >
          {t('report')}
        </Link>
      )}
      {activeArea &&
        (activeArea.item.status === 'ForRent' ||
          activeArea.item.status === 'ForSale') && (
          <RentButton
            onClick={handleRent}
            price={oc(activeArea).item.cost('0')}
            duration={oc(activeArea).item.rentDuration('0')}
            statusCode={activeArea.item.status}
            type={activeArea.cluster?.id ? 'cluster' : 'area'}
            className={s.rentButton}
            disabled={
              activeArea.item.owner.id === tgWallStore.userAddress || readOnly
            }
          />
        )}
    </div>
  );
};

export default OwnerAreaInfo;
