import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Web3 from 'web3';
import GetLoanDialog from '../GetLoanDialog';
import ItemBar from '../../../../../Components/Defi/ItemBar/ItemBar';
import { getRefundAmountStringTg } from '../../../../../Utils/defiUtils';
import RowComponent from '../../../../../Components/Defi/RowComponent/RowComponent';
import s from './TopOffersItem.scss';
import sDefi from '../../../Defi.scss';

interface TopOffersItemProps {
  offer: LoanTgOffersType;
  userAreas: AreaTgType[];
  disabled: boolean;
}

const TopOffersItem: React.FC<TopOffersItemProps> = ({
  offer,
  userAreas,
  disabled
}) => {
  const { t } = useTranslation();

  const offerDuration = Math.round((Number(offer.duration) * 10) / 86400) / 10;

  const summary = [
    <ItemBar
      title={t('loan_amount')}
      value={`<span class="wordBreakAll">${Web3.utils.fromWei(
        offer.loan_wei || '0'
      )}</span>`}
      note={t('matic')}
      key="1"
    />,
    <ItemBar
      title={t('refund_amount')}
      value={`${getRefundAmountStringTg(offer, 1)} ${t('for_days', {
        count: offerDuration
      })}`}
      key="2"
    />
  ];
  const details = [
    <div key="1">
      <div>
        <Typography variant="caption">{t('lender')}</Typography>
        <Typography variant="caption">
          <Link
            href={`https://polygonscan.com/address/${offer.lender}`}
            variant="body1"
            target="_blank"
          >
            <span className={sDefi.mobileAddress}>
              {offer.lender.slice(0, 6)}...{offer.lender.slice(-4)}
            </span>
            <span className={sDefi.desktopAddress}>{offer.lender}</span>
          </Link>
        </Typography>
      </div>
      <div>
        <Typography variant="caption">{t('liquidity_zone')}</Typography>
        <Typography variant="caption">{`(${offer.x1}, ${offer.y1}) - (${offer.x2}, ${offer.y2})`}</Typography>
      </div>
    </div>,
    <div key="2">
      <GetLoanDialog offer={offer} userAreas={userAreas} disabled={disabled} />
    </div>
  ];

  return (
    <>
      <div className={s.mainDesktop}>
        <div>
          <ItemBar
            title={t('loan_amount')}
            value={`<span class="wordBreakAll">${Web3.utils.fromWei(
              offer.loan_wei || '0'
            )}</span>`}
            note={t('matic')}
          />
          <ItemBar
            title={t('duration')}
            value={offerDuration}
            note={t('days')}
          />
          <ItemBar
            title={t('refund_amount')}
            value={getRefundAmountStringTg(offer)}
          />
        </div>
        <div>{details}</div>
      </div>
      <div className={s.mainMobile}>
        <RowComponent summary={summary} details={details} />
      </div>
    </>
  );
};

export default TopOffersItem;
