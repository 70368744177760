import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import zoomOutIcon from '../../Resources/Icons/menu.svg';
import clsx from 'clsx';
import { Drawer, List, ListItem, makeStyles } from '@material-ui/core';
import AppStateContext from '../../Contexts/AppStateContext';
import HeaderLink from './HeaderLink';
import BalanceInformer from './BalanceInformer/BalanceInformer';

import s from './Header.scss';

const useStyles = makeStyles({
  list: {
    width: 250,
    paddingLeft: 15,
    paddingTop: 10
  },
  fullList: {
    width: 'auto'
  }
});

const useStylesDrawer = makeStyles({
  paperAnchorRight: {
    left: 0,
    top: 80,
    backgroundColor: 'black'
  }
});

const MobileMenu = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesDrawer = useStylesDrawer();
  const [state, setState] = useState(false);
  const { readOnly } = useContext(AppStateContext);

  const toggleDrawer = (open: boolean) => () => {
    setState(open);
  };

  return (
    <div className={s.mobileMenu}>
      <img
        className={s.icon}
        src={zoomOutIcon}
        alt=""
        onClick={toggleDrawer(true)}
      />
      <Drawer
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
        classes={{ paperAnchorRight: classesDrawer.paperAnchorRight }}
      >
        <div
          className={clsx(classes.list)}
          role="presentation"
          onClick={toggleDrawer(false)}
        >
          <List className={s.listMenu}>
            <ListItem>
              <HeaderLink title={t('wall')} to="/" exact />
            </ListItem>
            {!readOnly && (
              <>
                <ListItem>
                  <HeaderLink title={t('my_places')} to="/my/clusters" />
                </ListItem>
              </>
            )}
            <ListItem>
              <HeaderLink title={t('buy_places')} to="/marketplace/clusters" />
            </ListItem>
            <ListItem>
              <HeaderLink title={t('defi')} to="/defi" />
            </ListItem>
            <ListItem>
              <HeaderLink title={t('rating')} to="/rating" />
            </ListItem>
            <ListItem>
              <HeaderLink title={t('instructions')} to="/instructions" />
            </ListItem>
          </List>
        </div>
        <BalanceInformer desctop={false} />
      </Drawer>
    </div>
  );
};

export default MobileMenu;
