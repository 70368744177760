import React, { useEffect, useContext, useState } from 'react';
import AreasListTgDaily from '../../../../Components/AreasList/AreasListTgDaily';
import { CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../Hooks/useStores';
import { useObserver } from 'mobx-react-lite';
import { oc } from 'ts-optchain';
// import { useParams } from 'react-router-dom';
import { RemoteDataState } from '../../../../Utils/RemoteData';
import { getAreaForDistance } from './NearestPopup/NearestPopup';
import AppStateContext from '../../../../Contexts/AppStateContext';
import { UPDATE_INTERVAL } from '../TheWallTgDaily';
import { STATUS_RENTED } from '../../../../Stores/TgDailyStore';
import s from '../../../MyPlaces/MyPlaces.scss';
import { getCurrentTime } from '../../../../Helpers/helpers';

const Areas = () => {
  const { t } = useTranslation();
  const { wallStore, tgDailyStore, tgWallStore } = useStores();
  const readOnly =
    useContext(AppStateContext).readOnly || !wallStore.networkVersionTrue;
  const [updateDashboard, setUpdateDashboard] = useState(0);
  // const { id } = useParams();

  return useObserver(() => {
    const currentTime = getCurrentTime(wallStore.timeCorrection);
    const getDailyAreasReserve = (address: string) => {
      if (address) {
        tgDailyStore.getAreasReserve(address);
      }
    };

    const getDailyAreasDistance = (x: number, y: number) => {
      tgDailyStore.getAreasDistance(currentTime, x, y);
    };

    useEffect(() => {
      setTimeout(() => {
        setUpdateDashboard(i => i + 1);
      }, UPDATE_INTERVAL);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDashboard]);

    useEffect(() => {
      const { x, y } = getAreaForDistance();
      getDailyAreasDistance(x, y);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDashboard]);

    useEffect(() => {
      if (wallStore.currentdAddress) {
        getDailyAreasReserve(wallStore.currentdAddress);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallStore.currentdAddress, updateDashboard]);

    // Loader
    const isLoadingAreas = tgWallStore.areas.state !== RemoteDataState.SUCCESS;
    const isLoadingReserve =
      isLoadingAreas ||
      tgDailyStore.areasUserReserve.state !== RemoteDataState.SUCCESS;

    const isLoadingDistance =
      isLoadingAreas ||
      tgDailyStore.areasDistance.state !== RemoteDataState.SUCCESS;

    let badData = false;
    const areas = oc(tgWallStore).areas.value.areas([]);
    if (areas.length === 0) return <CircularProgress size={24} />;

    // User reserve
    const dailyAreasReserve = oc(tgDailyStore)
      .areasUserReserve.value([])
      .filter(
        i => i.status === STATUS_RENTED && Number(i.deadline) > currentTime
      )
      .map(i => ({ ...i, areaId: String(i.areaId) }));

    const areasReserveData = dailyAreasReserve.map(i => {
      const dataArea = areas.find(j => j.id === i.areaId);
      if (!dataArea) badData = true;
      return {
        ...areas.find(j => j.id === i.areaId),
        dailyData: i
      };
    });

    // Distance
    const dailyAreasDistance = oc(tgDailyStore)
      .areasDistance.value([])
      .filter(i => i.provider !== wallStore.currentdAddress)
      .map(i => ({ ...i, areaId: String(i.areaId) }));

    const areasDistanceData = dailyAreasDistance.map(i => {
      const dataArea = areas.find(j => j.id === i.areaId);
      if (!dataArea) badData = true;
      return {
        ...areas.find(j => j.id === i.areaId),
        dailyData: i
      };
    });

    if (badData) return null;

    const renderSection = (
      title: string,
      data: AreaTgType[],
      section: 'reserve' | 'nearest'
    ) => {
      const isLoading =
        section === 'reserve' ? isLoadingReserve : isLoadingDistance;
      return (
        <div className={s.section}>
          {data.length === 0 && (
            <Typography variant="h6" className={s.title} gutterBottom>
              {title}
            </Typography>
          )}
          {!isLoading && data.length === 0 && (
            <Typography>{t('no_areas')}</Typography>
          )}
          <div>
            {data.length > 0 && (
              <AreasListTgDaily
                title={title}
                areas={data}
                // isExpanded={!!data.find(c => c.areaId === id)}
                isExpanded={true}
                section={section}
              />
            )}
            {isLoading && (
              <div className={s.loaderSmall}>
                <CircularProgress size={24} />
              </div>
            )}
          </div>
        </div>
      );
    };

    return (
      <div>
        {!readOnly &&
          renderSection(
            t('my_reserve'),
            areasReserveData as AreaTgType[],
            'reserve'
          )}
        {renderSection(
          t('available_areas'),
          areasDistanceData as AreaTgType[],
          'nearest'
        )}
      </div>
    );
  });
};

export default Areas;
