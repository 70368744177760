export const POLYGON_TEST_ID = '0x13881';
export const POLYGON_ID = '0x89';

export const networkConfig: { test: ChainDataType[]; prod: ChainDataType[] } = {
  test: [
    {
      id: POLYGON_TEST_ID,
      rpcUrls: ['https://matic-mumbai.chainstacklabs.com/'],
      blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
      name: 'Polygon Testnet',
      nameForUrl: 'mumbai',
      currency: 'MATIC',
      decimals: 18,
      scanAddress: 'https://mumbai.polygonscan.com/address'
    }
  ],
  prod: [
    {
      id: POLYGON_ID,
      rpcUrls: ['https://polygon-rpc.com/'],
      blockExplorerUrls: ['https://polygonscan.com/'],
      name: 'Polygon',
      nameForUrl: 'polygon',
      currency: 'MATIC',
      decimals: 18,
      scanAddress: 'https://polygonscan.com/address'
    }
  ]
};
