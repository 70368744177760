import { action, observable } from 'mobx';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { createRemoteData, handleTgRemoteData } from '../Utils/RemoteData';
import { distanceSort } from '../Helpers/helpers';

export const STATUS_AVAILABLE = 'Available';
export const STATUS_RENTED = 'Rented';
export const STATUS_WITHDRAWN = 'Withdrawn';

// const URI = 'https://api.thegraph.com/subgraphs/name/isvirin/the-wall-daily';
const URI =
  'https://api.thegraph.com/subgraphs/name/isvirin/the-wall-daily-mumbai';

class TgDailyStore {
  client = new ApolloClient({
    uri: URI,
    cache: new InMemoryCache()
  });

  @observable configuration = createRemoteData<DailyTgConfigurationType>();
  @observable statistics = createRemoteData<DailyTgStatisticsType>();
  @observable areas = createRemoteData<DailyTgAreaType[]>();
  @observable areasDistance = createRemoteData<DailyTgAreaType[]>();
  @observable areasUserReserve = createRemoteData<DailyTgAreaType[]>();
  @observable providerData = createRemoteData<ProviderDataType>();
  @observable topProviders = createRemoteData<ProviderDataType[]>();
  @observable providers: UserTgType[] = [];

  constructor() {
    this.getConfiguration();
  }

  @action
  async getConfiguration() {
    return handleTgRemoteData(
      this.configuration,
      async () =>
        this.client.query({
          query: gql`
            query getConfiguration {
              configurations {
                id
                constLiquidity
                maximumAreasInPool
                rentDurationSeconds
                contract
              }
            }
          `
        }),
      data => {
        const result = (data as { configurations: DailyTgConfigurationType[] })
          .configurations[0];
        return result;
      }
    );
  }

  @action
  async getStatistics() {
    this.client.cache.reset();
    return handleTgRemoteData(
      this.statistics,
      async () =>
        this.client.query({
          query: gql`
            query getStatistics {
              statistics(id: "DailyStat") {
                rev
                balance
                poolsize
                profitWithdrawn
                amountLPT
              }
            }
          `
        }),
      data => (data as { statistics: DailyTgStatisticsType }).statistics
    );
  }

  @action
  async getAreasByAddress(address: string) {
    this.client.cache.reset();
    return handleTgRemoteData(
      this.areas,
      async () =>
        this.client.query({
          query: gql`
            query getAreasByAddress($address: String) {
              areas(where: { provider: $address }) {
                areaId
                deadline
                provider
                rev
                status
                tenant
                x
                y
              }
            }
          `,
          variables: { address }
        }),
      data => (data as { areas: DailyTgAreaType[] }).areas
    );
  }

  @action
  async getAreasReserve(address: string) {
    this.client.cache.reset();
    return handleTgRemoteData(
      this.areasUserReserve,
      async () =>
        this.client.query({
          query: gql`
            query getAreasReserve($address: String) {
              areas(where: { tenant: $address }) {
                areaId
                deadline
                provider
                rev
                status
                tenant
                x
                y
              }
            }
          `,
          variables: { address }
        }),
      data => (data as { areas: DailyTgAreaType[] }).areas
    );
  }

  @action
  async getAreasDistance(currentTime: number, x: number, y: number) {
    this.client.cache.reset();
    return handleTgRemoteData(
      this.areasDistance,
      async () =>
        this.client.query({
          query: gql`
            query getAreasDistance {
              areas {
                areaId
                deadline
                provider
                rev
                status
                tenant
                x
                y
              }
            }
          `
        }),
      data => {
        let result = (data as { areas: DailyTgAreaType[] }).areas;
        result = result.filter(
          i =>
            i.status === STATUS_AVAILABLE ||
            (i.status === STATUS_RENTED && Number(i.deadline) <= currentTime)
        );

        return result.sort((a, b) => {
          return (
            distanceSort(x, Number(a.x), y, Number(a.y)) -
            distanceSort(x, Number(b.x), y, Number(b.y))
          );
        });
      }
    );
  }

  @action
  async getProviderData(address: string) {
    this.client.cache.reset();
    return handleTgRemoteData(
      this.providerData,
      async () =>
        this.client.query({
          query: gql`
            query getProviderData($address: String) {
              provider(id: $address) {
                id
                rev
                profitWithdrawn
                areas
                amountLPT
              }
            }
          `,
          variables: { address }
        }),
      data => (data as { provider: ProviderDataType }).provider
    );
  }

  @action
  async getTopProviders(users: UserTgType[]) {
    this.client.cache.reset();
    return handleTgRemoteData(
      this.topProviders,
      async () =>
        this.client.query({
          query: gql`
            query getTopProviders {
              providers(
                where: { areas_gt: 0 }
                orderBy: areas
                orderDirection: desc
              ) {
                id
                rev
                profitWithdrawn
                areas
                amountLPT
              }
            }
          `
        }),
      data => {
        const result = (data as { providers: ProviderDataType[] }).providers;
        const providers: UserTgType[] = [];
        result.forEach(i => {
          const user = users.find(j => i.id === j.id);
          if (user) {
            providers.push({
              ...user,
              score: i.areas
            });
          }
        });
        // providers.sort((a, b) => (a.score < b.score ? 1 : -1));
        let rank = 1;
        this.providers = providers.map((i, index) => {
          if (index > 0) {
            rank = i.score < providers[index - 1].score ? rank + 1 : rank;
          }
          return {
            ...i,
            rank
          };
        });

        return result;
      }
    );
  }
}

export default TgDailyStore;
