import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import s from './TheWall.scss';
import Konva from 'konva';
// import FPSStats from 'react-fps-stats';
import closeIcon from '../../Resources/Icons/close.svg';
import zoomInIcon from '../../Resources/Icons/zoomIn.svg';
import zoomOutIcon from '../../Resources/Icons/zoomOut.svg';
import {
  Button,
  ButtonBase,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  handleZoom,
  hideHighlightBox,
  initStage,
  showBorderCluster,
  resetClusterBorder,
  renderAreaBorder,
  renderScene
} from './Elements/renderScene';
import Search from '../Search/Search';
import {
  renderGrayLayer,
  renderInactiveGrayLayer
} from './Elements/renderGrayLayer';
import { oc } from 'ts-optchain';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Hooks/useStores';
import EmptyAreaInfo from './EmptyAreaInfo';
import { observer, useObserver } from 'mobx-react-lite';
import { useReaction } from '../../Hooks/useReaction';
import { autorun } from 'mobx';
import { RemoteDataState } from '../../Utils/RemoteData';
import { CreateErrorDialog, CreateSuccessDialog } from '../Dialogs/Dialogs';
import { debounce, orderBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../Hooks/useQuery';
import Web3 from 'web3';
import Cookies from 'js-cookie';
import OwnerAreaInfo from './OwnerAreaInfo';
import { renderSectors, renderAllByScale } from './Elements/renderSectors';

export const getWidthWallBox = () => window.innerWidth;
// The height of the header is taken into account for different screen widths.
export const DESKTOP = window.innerWidth > 600;
export const HEADER_DESCTOP = 80;
export const HEADER_MOBILE = 80;
export const getHeightWallBox = () =>
  window.innerHeight - 1 - (DESKTOP ? HEADER_DESCTOP : HEADER_MOBILE);
export const SIZE_AREA = 10;
export const MAX_SCALE = 20;
export const SCALE_BY = 0.3;
const INFO_CONTAINER_DESKTOP_WIDTH = 375;
const INFO_CONTAINER_DESKTOP_HEIGHT = 330;
const INFO_CONTAINER_MOBILE_WIDTH = 372;
const INFO_CONTAINER_MOBILE_HEIGHT = 300;

export type HandleObjectMouseType = (
  e?: Konva.KonvaEventObject<MouseEvent>,
  boxCoords?: AreaCoordinate,
  isDelete?: boolean
) => void;

export type HandleObjectTouchendType = (
  e?: Konva.KonvaEventObject<TouchEvent>,
  boxCoords?: AreaCoordinate,
  isDelete?: boolean
) => void;

export type HandleWheelMouseType = (
  e?: Konva.KonvaEventObject<MouseEvent>,
  zoom?: Nullable<number>,
  stage?: Konva.Stage
) => void;

export interface InfoPositionType {
  top: number;
  left: number;
  id: string;
  type: InfoModalType;
  boxCoords?: AreaCoordinate;
}

export const getVisibleAreas = (areas: AreaTgType[], stage: Konva.Stage) => {
  return areas.filter(i => {
    const areaY =
      -stage.offsetY() + +i.y * SIZE_AREA + stage.y() / stage.scaleY();
    const areaX =
      -stage.offsetX() + +i.x * SIZE_AREA + stage.x() / stage.scaleX();
    return (
      getHeightWallBox() / stage.scaleY() - SIZE_AREA < areaY &&
      areaY < 0 &&
      getWidthWallBox() / stage.scaleX() > areaX &&
      areaX > -SIZE_AREA
    );
  });
};

export const getVisibleAreasData = (stage: Konva.Stage): VisibleAreas => {
  const widthAreas = Math.abs(
    Math.round(getWidthWallBox() / (SIZE_AREA * stage.scaleX()))
  );
  const heightAreas = Math.abs(
    Math.round(getHeightWallBox() / (SIZE_AREA * stage.scaleY()))
  );
  const x = stage.offsetX() - stage.x() / stage.scaleX();
  const y = stage.offsetY() - stage.y() / stage.scaleY();
  return {
    x: Math.round(x / SIZE_AREA),
    y: Math.round(y / SIZE_AREA) - heightAreas,
    width: widthAreas,
    height: heightAreas
  };
};

enum InfoModalType {
  OWNED,
  EMPTY
}

const TheWall = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const preAreaId = query.get('areaId');
  const preClusterId = query.get('clusterId');
  const search = query.has('search');
  const { wallStore, tgWallStore } = useStores();
  const [loading, setLoading] = useState(true);
  const [firstRenderer, setFirstRendered] = useState(false);
  const [stage, setStage] = useState<Nullable<Konva.Stage>>(null);
  const [firstStage, setFirstStage] = useState(true);
  const [firstGoTo, setFirstGoTo] = useState(true);
  const infoRef = useRef<Nullable<InfoPositionType>>();
  const clusterModeRef = useRef<boolean>(false);
  const [isClusterMode, setClusterMode] = useState(false);
  const [clusterSelected, setClusterSelected] = useState<
    Nullable<AreaCoordinate>
  >(null);
  const [infoModalState, setInfoState] = useState<Nullable<InfoPositionType>>(
    null
  );

  const handleObjectClick = (type: InfoModalType): HandleObjectMouseType => (
    e,
    boxCoords
  ) => {
    const boxCoordsX = oc(boxCoords).x(0) / SIZE_AREA;
    const boxCoordsY = oc(boxCoords).y(0) / SIZE_AREA;
    const activeArea = oc(tgWallStore)
      .areas.value.areas([])
      .find(c => +c.x === boxCoordsX && +c.y === boxCoordsY);
    if (e) {
      const left =
        e.currentTarget.width() - INFO_CONTAINER_DESKTOP_WIDTH - 35 > e.evt.x
          ? e.evt.x + 20
          : e.evt.x - INFO_CONTAINER_DESKTOP_WIDTH - 20;
      const top =
        e.evt.y + INFO_CONTAINER_DESKTOP_HEIGHT - HEADER_DESCTOP + 20 <
        e.currentTarget.height()
          ? e.evt.y - HEADER_DESCTOP + 20
          : e.currentTarget.height() - INFO_CONTAINER_DESKTOP_HEIGHT - 10;
      setInfoState(prev => {
        let id = '';
        if (activeArea) {
          if (activeArea.cluster?.id) {
            id = `cluster_${activeArea.cluster?.id}`;
          } else {
            id = `area_${activeArea.id}`;
          }
        } else {
          id = `boxCoords_${boxCoordsX}-${boxCoordsY}`;
        }

        if (prev && prev.id && prev.id === id) {
          return null;
        }

        return {
          top,
          left,
          id,
          type: activeArea ? 0 : type,
          boxCoords
        };
      });
    }
  };

  const handleObjectTouchend = (
    type: InfoModalType
  ): HandleObjectTouchendType => (e, boxCoords) => {
    const boxCoordsX = oc(boxCoords).x(0) / SIZE_AREA;
    const boxCoordsY = oc(boxCoords).y(0) / SIZE_AREA;
    const activeArea = oc(tgWallStore)
      .areas.value.areas([])
      .find(c => +c.x === boxCoordsX && +c.y === boxCoordsY);

    if (e) {
      const left =
        e.currentTarget.width() < INFO_CONTAINER_MOBILE_WIDTH
          ? 1
          : (e.currentTarget.width() - INFO_CONTAINER_MOBILE_WIDTH) / 2;
      const top =
        e.evt.changedTouches[0].pageY +
          INFO_CONTAINER_MOBILE_HEIGHT -
          HEADER_MOBILE +
          20 <
        e.currentTarget.height()
          ? e.evt.changedTouches[0].pageY - HEADER_MOBILE + 20
          : e.evt.changedTouches[0].pageY - INFO_CONTAINER_MOBILE_HEIGHT - 80;
      setInfoState(prev => {
        let id = '';
        if (activeArea) {
          if (activeArea.cluster?.id) {
            id = `cluster_${activeArea.cluster.id}`;
          } else {
            id = `area_${activeArea.id}`;
          }
        } else {
          id = `boxCoords_${boxCoordsX}-${boxCoordsY}`;
        }

        if (prev && prev.id && prev.id === id) {
          return null;
        }

        return {
          top,
          left,
          id,
          type: activeArea ? 0 : type,
          boxCoords
        };
      });
    }
  };

  const renderClusterBorder = (x: number, y: number, stage: Konva.Stage) => {
    if (tgWallStore.clusters.state !== RemoteDataState.SUCCESS) return false;
    const clusters = oc(tgWallStore).clusters.value.clusters([]);
    if (clusters?.length && stage) {
      const cluster = clusters.find(c =>
        oc(c)
          .areas([])
          .find(
            (i: AreaTgType) => +i.x === x / SIZE_AREA && +i.y === y / SIZE_AREA
          )
      );
      if (cluster && cluster.areas) {
        showBorderCluster(stage, [cluster], cluster.areas, cluster.id, () =>
          setInfoState(null)
        );
        return true;
      }
      resetClusterBorder(stage);
    }
    return false;
  };

  const handleWheel: HandleWheelMouseType = (e, zoom, currentStage) => {
    if (currentStage) {
      resetClusterBorder(currentStage);
    }
    setInfoState(null);
  };

  const controlZoom = (
    stage: Nullable<Konva.Stage>,
    value: boolean,
    manual?: boolean
  ) => {
    setInfoState(null);
    handleZoom(stage, value, manual)();
  };

  useEffect(() => {
    const refAddress = query.get('invite');
    if (refAddress && Web3.utils.isAddress(refAddress)) {
      Cookies.set('referrer', refAddress, { expires: 365, path: '/' });
      localStorage.setItem('referrer', refAddress);
    }

    if (stage && firstGoTo) {
      const clusters = oc(tgWallStore).clusters.value.clusters([]);
      const areas = oc(tgWallStore).areas.value.areas([]);
      if (clusters.length && areas.length) {
        setFirstGoTo(false);
        if (preAreaId) {
          showBorderCluster(stage, [], []);
          renderAreaBorder(
            stage,
            areas.find(i => i.id === preAreaId)
          );
        } else if (preClusterId) {
          renderAreaBorder(stage);
          const cluster = clusters.find(i => i.id === preClusterId);
          if (cluster) {
            showBorderCluster(stage, [cluster], areas, cluster.id);
          }
        }
        renderMapOnce(
          oc(tgWallStore).areas.value.areas([]),
          oc(wallStore).macroblocksData.value([]),
          true
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(
    () =>
      autorun(() => {
        if (
          stage === null &&
          tgWallStore.wall.state === RemoteDataState.SUCCESS &&
          oc(wallStore).macroblocksData.value([]).length
        ) {
          const macroblockScaleList = wallStore.getMacroblockScaleList();
          if (!macroblockScaleList.length) return;

          const wallWidth = +oc(tgWallStore).wall.value.wallWidth('0');
          const wallHeight = +oc(tgWallStore).wall.value.wallHeight('0');

          let defaultScale = 2.8;

          const widthWallBox = getWidthWallBox();
          const heightWallBox = getHeightWallBox();

          const newStage = initStage({
            width: widthWallBox,
            height: heightWallBox,
            offsetY: Math.abs(heightWallBox / 2) / defaultScale,
            offsetX: -Math.abs(widthWallBox / 2) / defaultScale,
            container: 'canvasContainer',
            draggable: true,
            dragBoundFunc: function(pos: any) {
              // @ts-ignore
              const scaleX = this.scaleX() || 0;
              // @ts-ignore
              const maxHeight =
                +oc(tgWallStore).wall.value.wallHeight('0') *
                scaleX *
                SIZE_AREA;
              const maxWidth =
                +oc(tgWallStore).wall.value.wallWidth('0') * scaleX * SIZE_AREA;

              return {
                x: widthWallBox > maxWidth ? this.x() : pos.x,
                y: heightWallBox > maxHeight ? this.y() : pos.y
              };
            },
            scaleY: -defaultScale,
            scaleX: defaultScale
          });
          renderScene(newStage, {
            onDragStart: () => setInfoState(null),
            width: wallWidth,
            height: wallHeight,
            macroblockScaleList,
            onFieldTouchend: handleObjectTouchend(InfoModalType.EMPTY),
            onFieldClick: handleObjectClick(InfoModalType.EMPTY),
            onImageClick: handleObjectClick(InfoModalType.OWNED),
            onWheel: handleWheel,
            getMultipleState: () => clusterModeRef.current,
            onMultipleSelect: setClusterSelected,
            renderClusterBorder: renderClusterBorder
          });
          setStage(newStage);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stage, wallStore]
  );

  const renderMapOnce = (
    areas: AreaTgType[],
    macroblocks: MacroblockScaleType[],
    visiblePosition: boolean
  ) => {
    if (stage) {
      const preArea = areas.find(c => c.id === preAreaId);
      if (stage && preArea && visiblePosition) {
        stage.offsetX(
          -Math.abs(getWidthWallBox() / 2) / stage.scaleX() +
            +preArea.x * SIZE_AREA +
            stage.x() / stage.scaleX()
        );
        stage.offsetY(
          Math.abs(getHeightWallBox() / 2) / -stage.scaleY() +
            +preArea.y * SIZE_AREA +
            stage.y() / stage.scaleY()
        );
      }
      if (stage && preClusterId && visiblePosition) {
        const areasCluster = areas.filter(c => c.cluster?.id === preClusterId);
        if (areasCluster.length > 0) {
          const lowY = orderBy(areasCluster, ['y'], ['desc'])[0].y;
          const lowX = orderBy(areasCluster, ['x'], ['asc'])[0].x;
          stage.offsetX(
            -Math.abs(getWidthWallBox() / 2) / stage.scaleX() +
              +lowX * SIZE_AREA +
              stage.x() / stage.scaleX()
          );
          stage.offsetY(
            Math.abs(getHeightWallBox() / 2) / -stage.scaleY() +
              +lowY * SIZE_AREA +
              stage.y() / stage.scaleY()
          );
        }
      }

      const visibleData = getVisibleAreasData(stage);

      if (macroblocks && macroblocks.length) {
        const width = +oc(tgWallStore).wall.value.wallWidth('0');
        const height = +oc(tgWallStore).wall.value.wallHeight('0');
        renderAllByScale(
          stage,
          macroblocks,
          {
            x: -width / 2,
            y: -height / 2,
            width,
            height
          },
          areas,
          1 // lowest pixel layer
        );
        renderSectors(stage, macroblocks, visibleData, areas);
      }
    }
  };

  const renderMapAfterChangingScope = debounce(renderMapOnce, 1000);
  const renderMap = debounce(renderMapAfterChangingScope, 1000, {
    maxWait: 1500
  });

  const resizeStage = debounce(() => {
    if (stage) {
      const width = window.innerWidth;
      const height =
        window.innerHeight - (DESKTOP ? HEADER_DESCTOP : HEADER_MOBILE);
      stage.width(width);
      stage.height(height);
      renderMapOnce(
        oc(tgWallStore).areas.value.areas([]),
        oc(wallStore).macroblocksData.value([]),
        true
      );
    }
  }, 1000);

  useEffect(
    () => {
      if (stage && firstStage) {
        stage.on('xChange', () => {
          renderMapAfterChangingScope(
            oc(tgWallStore).areas.value()?.areas || [],
            oc(wallStore).macroblocksData.value([]),
            false
          );
          resetClusterBorder(stage);
        });
        window.addEventListener('resize', function() {
          resizeStage();
        });
        setFirstStage(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stage]
  );

  useEffect(
    () =>
      autorun(() => {
        if (wallStore.macroblocksData.state === RemoteDataState.SUCCESS) {
          setFirstRendered(true);
          setLoading(false);
          renderMap(
            oc(tgWallStore).areas.value.areas([]),
            oc(wallStore).macroblocksData.value([]),
            false
          );
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preAreaId, preClusterId, stage, wallStore]
  );

  useEffect(() => {
    if (stage && firstRenderer) {
      renderMap(
        oc(tgWallStore).areas.value.areas([]),
        oc(wallStore).macroblocksData.value([]),
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preAreaId, preClusterId, wallStore, stage, firstRenderer]);

  useEffect(() => {
    if (stage) {
      if (search) {
        const clusters = oc(tgWallStore).clusters.value.clusters([]);
        const areas = oc(tgWallStore).areas.value.areas([]);
        setInfoState(null);
        const layer = stage.findOne('#grid_layer');
        const height = layer.height();
        const width = layer.width();
        let top = height / 2 - INFO_CONTAINER_DESKTOP_HEIGHT / 2;
        let left = width / 2 + SIZE_AREA * 5;
        if (!DESKTOP) {
          top = height / 2 - INFO_CONTAINER_MOBILE_HEIGHT - SIZE_AREA * 7;
          left = (width - INFO_CONTAINER_MOBILE_WIDTH) / 2;
        }
        if (preAreaId) {
          setInfoState({
            top,
            left,
            id: `area_${preAreaId}`,
            type: InfoModalType.OWNED
          });
          history.push(`/?areaId=${preAreaId}`);
          showBorderCluster(stage, [], []);
          renderAreaBorder(
            stage,
            areas.find(i => i.id === preAreaId),
            () => setInfoState(null)
          );
        } else if (preClusterId) {
          history.push(`/?clusterId=${preClusterId}`);
          renderAreaBorder(stage);
          const cluster = clusters.find(i => i.id === preClusterId);
          if (cluster) {
            setInfoState({
              top: top,
              left: left,
              id: `cluster_${preClusterId}`,
              type: InfoModalType.OWNED
            });
            showBorderCluster(stage, [cluster], areas, cluster.id, () =>
              setInfoState(null)
            );
          }
        } else {
          history.push(`/`);
        }
        if (firstRenderer) {
          renderMapOnce(
            oc(tgWallStore).areas.value.areas([]),
            oc(wallStore).macroblocksData.value([]),
            true
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    infoRef.current = infoModalState;
    if (stage && infoModalState === null && !isClusterMode) {
      hideHighlightBox(stage);
    }
  }, [infoModalState, isClusterMode, stage]);

  useEffect(() => {
    if (stage) {
      stage.setDraggable(!isClusterMode);
    }
  }, [isClusterMode, stage]);

  useEffect(() => {
    clusterModeRef.current = isClusterMode;
    if (!isClusterMode) {
      setInfoState(null);
      setClusterSelected(null);
      if (stage) {
        renderInactiveGrayLayer(stage, []);
      }
    } else {
      if (stage) {
        renderInactiveGrayLayer(
          stage,
          oc(tgWallStore)
            .areas.value.areas([])
            .map(c => ({
              x: +c.x * SIZE_AREA,
              y: +c.y * SIZE_AREA,
              width: SIZE_AREA,
              height: SIZE_AREA
            }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClusterMode, stage, wallStore]);

  useReaction(
    () => wallStore.buyAreaMulti.state,
    state => {
      if (state === RemoteDataState.SUCCESS) {
        setClusterMode(false);
      }
    }
  );

  const closeInfoModal = () => {
    setInfoState(null);
    if (stage) {
      showBorderCluster(stage, [], []);
    }
  };

  const handleSearch = (areas: Nullable<AreaCoordinate[]>) => {
    if (stage) {
      renderGrayLayer(
        stage,
        +oc(tgWallStore).wall.value.wallWidth('0'),
        +oc(tgWallStore).wall.value.wallHeight('0'),
        areas,
        () => handleObjectClick(InfoModalType.OWNED),
        () => setInfoState(null)
      );
    }
  };

  const handleClusterSelect = (value: boolean) => () => {
    setClusterMode(value);
    if (infoModalState && value) {
      setClusterSelected({
        ...infoModalState.boxCoords,
        width: SIZE_AREA,
        height: SIZE_AREA
      } as AreaCoordinate);
    }
  };

  const buyCluster = () => {
    if (clusterSelected) {
      const x = clusterSelected.x / SIZE_AREA;
      const y = clusterSelected.y / SIZE_AREA;
      const width = Math.abs(oc(clusterSelected).width(0) / SIZE_AREA);
      const height = Math.abs(oc(clusterSelected).height(0) / SIZE_AREA);
      if (width === 1 && height === 1) {
        wallStore.buyEmptyArea(
          x,
          y,
          Number(oc(tgWallStore).user.value.user.coupons('0')),
          oc(tgWallStore).wall.value.areaCost('0')
        );
      } else {
        wallStore.buyCluster(
          {
            x,
            y,
            width,
            height
          },
          Number(oc(tgWallStore).user.value.user.coupons('0')),
          oc(tgWallStore).wall.value.areaCost('0')
        );
      }
    }
  };

  const activeArea = oc(tgWallStore)
    .areas.value.areas([])
    .find(
      c =>
        c.id ===
          oc(infoModalState)
            .id('')
            .split('_')[1] ||
        c.cluster?.id ===
          oc(infoModalState)
            .id('')
            .split('_')[1]
    );

  const clusterSize = Math.abs(
    ((oc(clusterSelected).width(0) / 10) * oc(clusterSelected).height(0)) / 10
  );

  const handleRent = () => {
    if (activeArea) {
      const clusters = oc(tgWallStore).clusters.value.clusters([]);
      const id = activeArea.cluster?.id || activeArea.id;
      const parentCluster = activeArea.cluster?.id
        ? clusters.find(c => c.id === activeArea.cluster?.id)
        : null;

      if (activeArea.item.status === 'ForSale') {
        wallStore.buyExistingArea(
          id,
          +oc(parentCluster).revision('0'),
          oc(activeArea).item.cost('')
        );
      }
      if (activeArea.item.status === 'ForRent') {
        wallStore.takeRentArea(
          id,
          +oc(parentCluster).revision('0'),
          oc(activeArea).item.cost('')
        );
      }
    }
  };

  return useObserver(() => {
    const coupons = Number(oc(tgWallStore).user.value.user.coupons('0'));
    // const areaCost = parseFloat(
    //   oc(window).web3.utils.fromWei((x: any) => x)(
    //     oc(wallStore).wall.value.areaCost('0')
    //   )
    // );
    const areaCost = parseFloat(
      Web3.utils.fromWei(oc(tgWallStore).wall.value.areaCost('0'))
    );

    let price = `${(areaCost * clusterSize).toFixed(2)} MATIC`;
    if (coupons > 0) {
      price =
        clusterSize > coupons
          ? `${coupons} TWG and ${(areaCost * (clusterSize - coupons)).toFixed(
              2
            )} MATIC`
          : `${clusterSize} TWG`;
    }

    return (
      <div className={s.root}>
        {!loading && (
          <Search
            onSearch={handleSearch}
            closeInfoModal={() => closeInfoModal()}
          />
        )}
        {loading && (
          <div className={s.loader}>
            <CircularProgress />
          </div>
        )}
        {wallStore.renderingAreas && (
          <div className={s.loader}>
            <CircularProgress />
          </div>
        )}
        <div
          className={cx(s.container, loading && s.containerHidden)}
          id="canvasContainer"
        />
        {/* <div className={s.FPSStats}>
          <FPSStats left={`auto`} right={0} />
        </div> */}
        <div className={s.zoomControls}>
          <Button
            onClick={() => controlZoom(stage, false, true)}
            disabled={wallStore.renderingAreas}
          >
            <img src={zoomInIcon} className={s.icon} alt="Zoom In" />
          </Button>
          <Button
            onClick={() => controlZoom(stage, true, true)}
            disabled={wallStore.renderingAreas}
          >
            <img src={zoomOutIcon} className={s.icon} alt="Zoom Out" />
          </Button>
        </div>
        {!isClusterMode &&
          infoModalState &&
          infoModalState.type === InfoModalType.OWNED && (
            <OwnerAreaInfo
              infoModalState={infoModalState}
              handleRent={handleRent}
              closeInfoModal={closeInfoModal}
              activeArea={activeArea}
              allTags={oc(tgWallStore).tags.value.tags([])}
            />
          )}
        {!isClusterMode &&
          infoModalState &&
          infoModalState.type === InfoModalType.EMPTY && (
            <EmptyAreaInfo
              infoModalState={infoModalState}
              closeInfoModal={closeInfoModal}
              handleClusterSelect={handleClusterSelect(true)}
            />
          )}
        {isClusterMode && (
          <div className={s.clusterInfo}>
            <ButtonBase
              disableRipple
              onClick={handleClusterSelect(false)}
              className={s.closeIcon}
            >
              <img src={closeIcon} alt="Close" />
            </ButtonBase>
            <Typography variant="h6" gutterBottom>
              {t('select_area_with_pointer')}
            </Typography>
            <Typography>
              {t('areas_selected_with_price', {
                count: clusterSize,
                price
              })}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={s.clusterBuyButton}
              disableElevation
              disabled={wallStore.isCreateRequesting || !clusterSize}
              onClick={buyCluster}
            >
              {wallStore.isCreateRequesting ? (
                <CircularProgress size={16} color="secondary" />
              ) : (
                <Typography variant="body2">
                  <strong>
                    {isClusterMode ? t('sell_cluster') : t('sell_area')}
                  </strong>{' '}
                  | {price}
                </Typography>
              )}
            </Button>
          </div>
        )}
        <CreateErrorDialog />
        <CreateSuccessDialog />
      </div>
    );
  });
};

export default observer(TheWall);
