import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import { oc } from 'ts-optchain';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import s from './Marketplace.scss';
import FieldItem from '../../Components/FieldItem/FieldItem';
import { RemoteDataState } from '../../Utils/RemoteData';
import AppStateContext from '../../Contexts/AppStateContext';

type ActionType = 'rent' | 'buy';

const MarketplaceClusters = () => {
  const { wallStore, tgWallStore } = useStores();
  const { readOnly } = useContext(AppStateContext);
  const { t } = useTranslation();

  const clustersForSale = oc(tgWallStore)
    .clusters.value.clusters([])
    .filter(
      c =>
        c.item?.status === 'ForSale' &&
        c.item.owner?.id !== tgWallStore.userAddress &&
        oc(c).areas([]).length > 0
    );

  const clustersForRent = oc(tgWallStore)
    .clusters.value.clusters([])
    .filter(
      c =>
        c.item?.status === 'ForRent' &&
        c.item.owner?.id !== tgWallStore.userAddress &&
        oc(c).areas([]).length > 0
    );

  const handleAction = (
    type: ActionType,
    id: string,
    revision: number,
    price: string
  ) => () => {
    if (type === 'buy') {
      wallStore.buyExistingArea(id, revision, price);
    }
    if (type === 'rent') {
      wallStore.takeRentArea(id, revision, price);
    }
  };

  const renderSection = (
    data: ClusterTgType[],
    status: RemoteDataState,
    title: string,
    message: string,
    type: ActionType
  ) => (
    <div className={s.section}>
      <Typography variant="h6" gutterBottom className={s.title}>
        {title}
      </Typography>
      {status === RemoteDataState.SUCCESS && data.length === 0 && (
        <Typography>{message}</Typography>
      )}
      <div className={s.items}>
        {data.length === 0 && status === RemoteDataState.REQUEST && (
          <CircularProgress size={16} color="primary" />
        )}
        {data.map(c => {
          return (
            <div className={s.item}>
              <FieldItem
                toMap
                areasCount={c.areas?.length}
                title={t('cluster_default_name', {
                  areas: c.areas?.length
                })}
                id={c.id}
                item={c.item}
                key={c.id}
                isCluster
              />
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleAction(
                  type,
                  c.id,
                  +c.revision,
                  oc(c).item.cost('0')
                )}
                disabled={readOnly}
              >
                {t(type)}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div>
      {renderSection(
        clustersForRent,
        tgWallStore.areas.state,
        t('for_rent'),
        t('no_clusters_for_rent'),
        'rent'
      )}
      {renderSection(
        clustersForSale,
        tgWallStore.areas.state,
        t('for_sale'),
        t('no_clusters_for_sale'),
        'buy'
      )}
    </div>
  );
};

export default observer(MarketplaceClusters);
