/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { oc } from 'ts-optchain';
import { Typography, CircularProgress, Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../Hooks/useStores';
import s from './BalanceInformer.scss';
import AppStateContext from '../../../Contexts/AppStateContext';
import getAddress from '../../../Utils/getAddress';
import { ethers } from 'ethers';
import { STORAGE_PENDING_TRANSACTIONS } from '../../../Stores/WallStore';

interface BalanceInformer {
  desctop: boolean;
}

const BalanceInformer: React.FC<BalanceInformer> = props => {
  const { t } = useTranslation();
  const { readOnly } = useContext(AppStateContext);
  const { wallStore } = useStores();
  const [maticValue, setMatikValue] = useState<Nullable<number>>(null);
  const [twgValue, setTwgValue] = useState<Nullable<number>>(null);
  const [ctwgValue, setCtwgValue] = useState<Nullable<number>>(null);
  const [provider, setProvider] = useState<
    Nullable<ethers.providers.Web3Provider>
  >(null);
  const [shortAddress, setShortAddress] = useState('');
  const [update, setUpdate] = useState(0);
  const [pendingTransactions, setPendingTransactions] = useState(0);

  const calcPendingTransactions = () => {
    setPendingTransactions(
      wallStore.transactions.reduce((acc, cur) => {
        if (cur.status === '0') {
          return acc + 1;
        }
        return acc;
      }, 0)
    );
  };

  useEffect(() => {
    calcPendingTransactions();
    if (wallStore.appStarted && wallStore.networkVersionTrue) {
      if (!provider) {
        setProvider(new ethers.providers.Web3Provider(window.ethereum));
      }
      wallStore.getCryptaurTWG();
      wallStore.getTWG();
    } else {
      setTwgValue(0);
      setCtwgValue(0);
    }
    const address = getAddress();
    if (address) {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`);
      window.web3.eth.getBalance(address).then((data: number) => {
        const matic = Math.round(data / Math.pow(10, 14)) / Math.pow(10, 4);
        setMatikValue(matic);
      });
    } else {
      setShortAddress('');
      setMatikValue(null);
    }
  }, [
    wallStore.appStarted,
    wallStore.networkVersionTrue,
    wallStore.currentdAddress,
    wallStore.currentNetwork,
    update
  ]);

  useEffect(() => {
    if (wallStore.networkVersionTrue) {
      setTwgValue(oc(wallStore).TWG(0));
    }
  }, [wallStore.TWG, wallStore.networkVersionTrue, wallStore.currentNetwork]);

  useEffect(() => {
    if (wallStore.networkVersionTrue) {
      setCtwgValue(oc(wallStore).cryptaurTWG(0));
    }
  }, [
    wallStore.cryptaurTWG,
    wallStore.networkVersionTrue,
    wallStore.currentNetwork
  ]);

  useEffect(() => {
    if (!provider || !wallStore.currentdAddress) return;
    calcPendingTransactions();
    sessionStorage.setItem(
      `${STORAGE_PENDING_TRANSACTIONS}_${wallStore.currentdAddress}`,
      JSON.stringify(wallStore.transactions.filter(i => i.status === '0'))
    );
    wallStore.transactions.forEach(i => {
      if (i.status === '0') {
        provider.waitForTransaction(i.hash).then(() => {
          setUpdate(prev => prev + 1);
          wallStore.setTransactionStatus(i.hash, '1');
        });
      }
    });
  }, [wallStore.transactions.length, wallStore.currentdAddress, provider]);

  if (readOnly || !shortAddress) return null;

  return (
    <div className={cx(s.main, props.desctop ? s.desctop : s.mobile)}>
      {!!ctwgValue && <Typography variant="body1">{ctwgValue} CTWG</Typography>}
      {!!twgValue && <Typography variant="body1">{twgValue} TWG</Typography>}
      {!!wallStore.networkVersionTrue && (
        <Typography variant="body1">{maticValue || 0} MATIC</Typography>
      )}
      <Typography variant="body1">
        {shortAddress}
        <span
          className={cx(
            s.indicator,
            wallStore.networkVersionTrue ? s.success : s.error
          )}
        />
      </Typography>
      {!!pendingTransactions && (
        <Box
          className={s.pending}
          title={wallStore.transactions.map(i => i.hash).join(', ')}
        >
          <Typography variant="body1">
            {pendingTransactions} {t('pending')}
          </Typography>

          <div className={s.loaderSmall}>
            <CircularProgress color="secondary" size={12} />
          </div>
        </Box>
      )}
    </div>
  );
};

export default observer(BalanceInformer);
