import React, { useState } from 'react';
import { ActionsPopupBaseProps } from './ActionsPopup/ActionsPopup';
import { oc } from 'ts-optchain';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../Hooks/useStores';
import { useReaction } from '../../../Hooks/useReaction';
import { RemoteDataState } from '../../../Utils/RemoteData';
import Web3 from 'web3';
import {
  Button,
  ButtonBase,
  CircularProgress,
  InputAdornment,
  Popover,
  TextField,
  Typography
} from '@material-ui/core';
import { bindPopover } from 'material-ui-popup-state/hooks';
import s from './Popups.scss';
import { observer } from 'mobx-react-lite';

const RentPopup: React.FC<ActionsPopupBaseProps> = ({ popupState, areaId }) => {
  const { t } = useTranslation();
  const { wallStore, tgWallStore } = useStores();
  const [amount, setAmount] = useState(0);
  const [days, setDays] = useState(30);
  const [amountInput, setAmountInput] = useState('');
  const [daysInput, setDaysInput] = useState('30');

  const area = oc(tgWallStore)
    .areas.value.areas([])
    .find(i => i.id === areaId);

  const operationFee =
    area && area.premium
      ? 0
      : +oc(tgWallStore).wall.value.feePercents('0') / 100;

  useReaction(
    () => wallStore.rent.state,
    state => {
      if (state === RemoteDataState.SUCCESS) {
        popupState.close();
      }
    }
  );

  const sell = () => {
    if (areaId) {
      wallStore.rentArea(
        areaId,
        Web3.utils.toWei(amount.toString(), 'ether'),
        (days * 86400).toString()
      );
    }
  };

  const cancel = () => {
    popupState.close();
  };

  const handleAmount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value.replace(/,/g, '.').replace(/[^\d,.]/g, '');
    if ((value.match(/\./g) || []).length > 1) return;
    setAmountInput(value);
    const valueNumber = parseFloat(value);
    setAmount(isNaN(valueNumber) ? 0 : valueNumber);
  };

  const handleDays = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value.replace(/,/g, '.').replace(/[^\d,.]/g, '');
    if ((value.match(/\./g) || []).length > 1) return;
    setDaysInput(value);
    const valueNumber = parseFloat(value);
    setDays(isNaN(valueNumber) ? 0 : valueNumber);
  };

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      PaperProps={{
        square: true
      }}
    >
      <div className={s.container}>
        <Typography variant="h5" className={s.title}>
          {t('rent')}
        </Typography>
        <TextField
          value={amountInput}
          onChange={e => handleAmount(e)}
          label={t('price')}
          placeholder={t('add')}
          type="text"
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            classes: { input: s.input },
            endAdornment: <InputAdornment position="end">MATIC</InputAdornment>
          }}
        />
        <Typography
          variant="caption"
          color="textSecondary"
          className={s.feeLabel}
        >
          {t('sell_fee', {
            value: ((amount || 0) * operationFee).toFixed(2)
          })}
        </Typography>
        <TextField
          value={daysInput}
          onChange={e => handleDays(e)}
          label={t('rental_period')}
          placeholder={t('add')}
          type="text"
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            step: 1
          }}
          InputProps={{
            classes: { input: s.input },
            endAdornment: (
              <InputAdornment position="end">{t('days')}</InputAdornment>
            )
          }}
        />
        <div className={s.buttons}>
          <ButtonBase disableRipple onClick={cancel}>
            <Typography variant="caption">{t('cancel')}</Typography>
          </ButtonBase>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            disabled={
              !amount ||
              amount <= 0 ||
              !days ||
              days <= 0 ||
              wallStore.isRentRequesting
            }
            onClick={sell}
          >
            {wallStore.isRentRequesting ? (
              <CircularProgress size={12} color="secondary" />
            ) : (
              <Typography variant="caption">{t('rent')}</Typography>
            )}
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default observer(RentPopup);
