import React from 'react';
import { observer } from 'mobx-react-lite';
import { oc } from 'ts-optchain';
import { useStores } from '../../../../Hooks/useStores';
import SelectAreaDialog from '../../../../Components/Defi/SelectAreaDialog/SelectAreaDialog';

interface GetLoanDialogProps {}

const AddLiquidityDialog: React.FC<GetLoanDialogProps> = () => {
  const { wallStore, tgDailyStore, tgWallStore } = useStores();
  const userAreasIds = oc(tgWallStore).user.value.user.areas([]);
  const userAreas = oc(tgWallStore)
    .areas.value.areas([])
    .filter(
      c =>
        userAreasIds.includes(c.id) &&
        c.item.status !== 'Rented' &&
        c.cluster?.id === null
    );

  const onSubmit = (areaId: string) => {
    const area = userAreas.find(i => i.id === areaId);
    if (area) {
      console.log(area.x, area.y);
      wallStore.depositDaily(
        +area.x,
        +area.y,
        oc(tgDailyStore).configuration.value.constLiquidity('')
      );
    }
  };

  const approvedAddress = wallStore.defiApprovedAddress.includes(
    `${wallStore.currentdAddress}_${wallStore.dailyContractAddress}`
  );

  return (
    <SelectAreaDialog
      areas={userAreas}
      title="select_an_area"
      btnTitle="add_liquidity"
      btnVariant="contained"
      selectArea={onSubmit}
      cancelBtnText="cancel"
      confirmBtnText="confirm"
      disabled={userAreas.length === 0 || !approvedAddress}
    />
  );
};

export default observer(AddLiquidityDialog);
