import React, { useEffect } from 'react';
import s from './MyPlaces.scss';
import AreasList from '../../Components/AreasList/AreasList';
import { CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Hooks/useStores';
import { useObserver } from 'mobx-react-lite';
import { oc } from 'ts-optchain';
import { orderBy, groupBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { RemoteDataState } from '../../Utils/RemoteData';

const MyAreas = () => {
  const { t } = useTranslation();
  const { wallStore, tgDailyStore, tgLoanStore, tgWallStore } = useStores();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    if (tgWallStore.areas.state === RemoteDataState.SUCCESS) {
      tgLoanStore.getOffers({
        orderBy: 'rate',
        status: 'Available' as StatusTgLoanOffer
      });
      if (wallStore.currentdAddress) {
        tgDailyStore.getAreasByAddress(wallStore.currentdAddress);
        tgDailyStore.getAreasReserve(wallStore.currentdAddress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgWallStore.areas.state, wallStore.currentdAddress]);

  useEffect(() => {
    if (tgWallStore.user.state === RemoteDataState.SUCCESS) {
      const userAreasIds = oc(tgWallStore).user.value.user.areas([]);
      tgWallStore.getAreasByIds(userAreasIds);
    }
  }, [tgWallStore, tgWallStore.user.state]);

  return useObserver(() => {
    const userAddress = tgWallStore.userAddress;
    const isLoading = tgWallStore.areas.state !== RemoteDataState.SUCCESS;
    const isLoadingLoan =
      isLoading || tgLoanStore.offers.state !== RemoteDataState.SUCCESS;

    const isLoadingDaily =
      isLoading || tgDailyStore.areas.state !== RemoteDataState.SUCCESS;

    const userAreasIds = oc(tgWallStore).user.value.user.areas([]);

    const areas: AreaTgType[] = oc(tgWallStore).areas.value.areas([]);

    const userAreas = areas.filter(
      c => userAreasIds.includes(c.id) && c.item.status !== 'Rented'
    );

    const userRentOutAreas = areas.filter(
      c => userAreasIds.includes(c.id) && c.item.status === 'Rented'
    );

    const userRentedAreas = areas.filter(
      c => c.item.status === 'Rented' && c.item.tenant?.id === userAddress
    );

    // const clusters = oc(tgWallStore).clusters.value.clusters([]);

    const renderSection = (
      title: string,
      data: AreaTgType[],
      titleAdd = t('free_areas')
    ) => {
      const groupedAres = groupBy(
        data.filter(c => c.cluster?.id),
        i => i.cluster?.id
      );

      const freeAreas = orderBy(
        data.filter(c => !c.cluster?.id),
        ['areaId'],
        ['desc']
      );

      return (
        <div className={s.section}>
          <Typography variant="h6" className={s.title} gutterBottom>
            {title}
          </Typography>
          <div>
            {Object.keys(groupedAres).map(c => {
              const areas = orderBy(
                data.filter(a => a.cluster?.id === c),
                ['areaId'],
                ['desc']
              );
              return (
                <AreasList
                  title={t('cluster_default_name', {
                    areas: areas.length
                  })}
                  areas={areas}
                  isExpanded={!!areas.find(c => c.id === id)}
                />
              );
            })}
            {freeAreas.length > 0 && (
              <AreasList
                title={titleAdd}
                areas={freeAreas}
                isExpanded={!!freeAreas.find(c => c.id === id)}
              />
            )}
            {!isLoading && data.length === 0 && (
              <Typography>{t('areas_empty')}</Typography>
            )}
            {isLoading && (
              <div className={s.loaderSmall}>
                <CircularProgress size={24} />
              </div>
            )}
          </div>
        </div>
      );
    };

    const offers = oc(tgLoanStore).offers.value([]);
    const dailyAreas = oc(tgDailyStore).areas.value([]);

    const freezed = (
      areas: AreaTgType[],
      title: string,
      isLoading: boolean
    ) => {
      return (
        <div>
          {!isLoading && areas.length !== 0 && (
            <AreasList
              title={t(title, {
                areas: areas.length
              })}
              areas={areas}
              isExpanded={!!areas.find(c => c.id === id)}
            />
          )}
          {/* {!isLoading && areas.length === 0 && (
            <Typography>{t('areas_empty')}</Typography>
          )} */}
          {isLoading && (
            <div className={s.loaderSmall}>
              <CircularProgress size={24} />
            </div>
          )}
        </div>
      );
    };

    const renderSectionFreezedLoan = (
      dataOffers: LoanTgOffersType[],
      data: AreaTgType[]
    ) => {
      const userOffersAreaId = dataOffers
        .filter(
          i =>
            (i.status === 'Accepted' && i.borrower === userAddress) ||
            (i.status === 'Overdue' && i.lender === userAddress)
        )
        .map(i => i.area_id);

      const areas = orderBy(
        data.filter(c => userOffersAreaId.includes(c.id)),
        ['areaId'],
        ['desc']
      );
      return freezed(areas, 'the_wall_loan', isLoadingLoan);
    };

    const renderSectionFreezedDaily = (
      dataDaily: DailyTgAreaType[],
      data: AreaTgType[]
    ) => {
      const dataDailyId = dataDaily.map(i => i.areaId);

      const areas = orderBy(
        data.filter(c => dataDailyId.includes(c.id)),
        ['areaId'],
        ['desc']
      );
      return freezed(areas, 'the_wall_daily', isLoadingDaily);
    };

    // User reserve
    const reserveAreas: AreaTgType[] = [];
    const dailyAreasReserve = oc(tgDailyStore)
      .areasUserReserve.value([])
      .map(i => String(i.areaId));

    areas.forEach(i => {
      if (dailyAreasReserve.includes(i.id)) {
        reserveAreas.push(i);
      }
    });

    return (
      <>
        {renderSection(t('my_areas'), userAreas)}
        {renderSection(
          t('my_reserve'),
          reserveAreas,
          t('the_wall_daily', { areas: reserveAreas.length })
        )}
        {renderSection(t('rented'), userRentedAreas)}
        {renderSection(t('rent_out'), userRentOutAreas)}
        <div className={s.section}>
          <Typography variant="h6" className={s.title} gutterBottom>
            {t('freezed')}
          </Typography>
          {renderSectionFreezedLoan(offers, areas)}
          {renderSectionFreezedDaily(dailyAreas, areas)}
          {!isLoadingLoan &&
            !offers.length &&
            !isLoadingDaily &&
            !dailyAreas.length && <Typography>{t('areas_empty')}</Typography>}
        </div>
      </>
    );
  });
};

export default MyAreas;
