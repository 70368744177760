import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';

import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import twgGuide_1 from '../../../Resources/Instructions/TWG_Guide_1.png';
import twgGuide_2 from '../../../Resources/Instructions/TWG_Guide_2.png';
import twgGuide_3 from '../../../Resources/Instructions/TWG_Guide_3.png';
import twgGuide_4 from '../../../Resources/Instructions/TWG_Guide_4.png';
import s from '../Instructions.scss';

const TwgGuide = () => {
  const { t } = useTranslation();
  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('get_your_piece_of_the_wall_in_3_steps')}
      </Typography>
      <section>
        <Typography variant="h3">{t('step_1_install_metamask')}</Typography>
        <Grid container spacing={2}>
          <Grid item md={4} />
          <Grid item md={4}>
            <Infobox num={1} text={t('install_metamask_extension_for')} />
          </Grid>
          <Grid item md={4} />
          <Grid item md={6}>
            <FancyBoxImage image={twgGuide_1} />
          </Grid>
          <Grid item md={6}>
            <FancyBoxImage image={twgGuide_2} />
          </Grid>
        </Grid>
      </section>
      <section>
        <Typography variant="h3">
          {t('step_2_connect_your_account_to_polygon')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Infobox
              num={2}
              text={t('if_you_dont_yet_have_an_ethereum_account')}
            />
          </Grid>
          <Grid item md={6}>
            <Infobox num={3} text={t('connect_your__ethereum_account')} />
          </Grid>
          <Grid item md={3} />
          <Grid item md={6}>
            <FancyBoxImage image={twgGuide_3} />
          </Grid>
          <Grid item md={3} />
        </Grid>
      </section>
      <section>
        <Typography variant="h3">
          {t('step_3_get_your_piece_of_the_wall')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={3} />
          <Grid item md={6}>
            <Infobox num={4} text={t('visit_the_wall_global_website')} />
          </Grid>
          <Grid item md={3} />
          <Grid item md={3} />
          <Grid item md={6}>
            <FancyBoxImage image={twgGuide_4} />
          </Grid>
          <Grid item md={3} />
        </Grid>
      </section>
    </div>
  );
};

export default TwgGuide;
