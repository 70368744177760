import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../Hooks/useStores';
import SelectAreaDialog from '../../../../Components/Defi/SelectAreaDialog/SelectAreaDialog';

interface GetLoanDialogProps {
  offer: LoanTgOffersType;
  userAreas: AreaTgType[];
  disabled: boolean;
}

const GetLoanDialog: React.FC<GetLoanDialogProps> = ({
  offer,
  userAreas,
  disabled
}) => {
  const { wallStore } = useStores();
  const includedAreas = userAreas.filter(
    area =>
      +area.x >= Number(offer.x1) &&
      +area.x <= Number(offer.x2) &&
      +area.y >= Number(offer.y1) &&
      +area.y <= Number(offer.y2)
  );

  const onSubmit = (areaId: string) => {
    const selectArea = includedAreas.find(i => i.id === areaId);
    if (!selectArea) return;
    wallStore.getLoan(Number(offer.id), +selectArea.x, +selectArea.y);
  };

  return (
    <SelectAreaDialog
      areas={userAreas}
      title="select_an_area"
      btnTitle="get_loan_now"
      selectArea={onSubmit}
      cancelBtnText="cancel"
      confirmBtnText="make_collateral"
      disabled={
        !includedAreas.length ||
        !wallStore.defiApprovedAddress.includes(
          `${wallStore.currentdAddress}_${wallStore.loanContractAddress}`
        ) ||
        disabled
      }
    />
  );
};

export default observer(GetLoanDialog);
