import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import s from './MyPlaces.scss';
import {
  Button,
  ButtonBase,
  CircularProgress,
  InputLabel,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import UploadImage from '../../Components/UploadImage/UploadImage';
import { useTranslation } from 'react-i18next';
import { TitleLink } from './MyPlaces';
import { useFormik } from 'formik';
import schema from './schema';
import { useStores } from '../../Hooks/useStores';
import {
  NavLink as RouterLink,
  NavLink,
  useParams,
  useLocation
} from 'react-router-dom';
import { oc } from 'ts-optchain';
import { useObserver } from 'mobx-react-lite';
import { SIZE_AREA } from '../TheWall/TheWall';
import {
  CancelErrorDialog,
  CancelSuccessDialog,
  SetContentErrorDialog,
  SetContentSuccessDialog
} from '../Dialogs/Dialogs';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindToggle } from 'material-ui-popup-state';
import ActionsPopup from './Popups/ActionsPopup/ActionsPopup';
import ActionsPopupDelete from './Popups/ActionsPopup/ActionsPopupDelete';
import { mergeImagesArr } from '../../Utils/mergeImagesArr';
import { getCorrectTags } from '../../Utils/editUtils';
import { getPreview } from '../../Utils/Preview';
import ExternalNftPopper from '../../Components/ExternalNftPopper/ExternalNftPopper';
import { RemoteDataState } from '../../Utils/RemoteData';
import RenderImage from '../../Components/RenderImage/RenderImage';

const URI_IPFS = window.config.URI_IPFS;

const MyAreaEdit = () => {
  return useObserver(() => {
    const { t } = useTranslation();
    const { wallStore, tgDailyStore, tgLoanStore, tgWallStore } = useStores();
    const { id } = useParams() as { id: string };
    const [resetImage, setResetImage] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    const { pathname } = useLocation();
    const popupState = usePopupState({
      variant: 'popper',
      popupId: 'editmenu'
    });

    const dailyMode =
      pathname.indexOf(`/defi/daily/areas`) !== -1 ||
      pathname.indexOf(`/defi/tgdaily/areas`) !== -1;

    let constLiquidity = '';
    let constLiquidityNum = 0;
    if (dailyMode) {
      constLiquidity = oc(tgDailyStore).configuration.value.constLiquidity('');
      if (!constLiquidity) {
        constLiquidity = oc(tgDailyStore).configuration.value.constLiquidity(
          ''
        );
      }
      constLiquidityNum =
        Math.round(+constLiquidity / Math.pow(10, 14)) / Math.pow(10, 4);
    }

    const freezedLoan = oc(tgLoanStore)
      .offers.value([])
      .filter(i => i.status === 'Accepted' || i.status === 'Overdue')
      .map(i => i.id);

    const freezedDaily = oc(tgDailyStore)
      .areas.value([])
      .map(i => i.areaId);

    const freezed =
      freezedLoan.includes(id) || (!dailyMode && freezedDaily.includes(id));

    useEffect(() => {
      popupState.close();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
      if (tgWallStore.areas.state === RemoteDataState.SUCCESS) {
        if (oc(selectedArea).imageCID([]).length === 0) return;
        getPreview(id)
          .then(path => {
            if (path) {
              setImageUrl(path);
            } else {
              throw new Error();
            }
          })
          .catch(e => {
            setImageUrl(`${URI_IPFS}/${selectedArea?.imageCID[0]}`);
            console.log('Error', e);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tgWallStore.areas.state]);

    const selectedArea = oc(tgWallStore)
      .areas.value.areas([])
      .find(c => c.id === id);

    const selectedCluster = oc(tgWallStore)
      .clusters.value.clusters([])
      .find(c => c.id === oc(selectedArea).cluster?.id(''));

    const tags = oc(tgWallStore)
      .tags.value.tags([])
      .map(c => c.id);

    const initialValues: AreaFormType = {
      title: oc(selectedArea).item.title(''),
      link: oc(selectedArea).item.link(''),
      tags: oc(selectedArea).item.tags([])
    };

    const {
      values,
      setFieldValue,
      submitForm,
      isValid,
      errors,
      resetForm
    } = useFormik({
      initialValues,
      isInitialValid: false,
      validationSchema: schema,
      enableReinitialize: true,
      onSubmit: values => {
        const images = oc(selectedArea).imageCID([]);

        let contentImages = images;
        const resultImage: string[] = [];
        if (values.image !== undefined) {
          values.image.map((i: ImageResult) => {
            if (oc(i.btih)('') !== '') {
              resultImage.push(oc(i.btih)(''));
            }
          });

          contentImages = resultImage.length
            ? mergeImagesArr(images, resultImage)
            : [];
        }

        const pixel = (oc(selectedArea) as any).RGB(null)
          ? (oc(selectedArea) as any).RGB(null).replace('#', '')
          : null;

        const contentData = {
          title: values.title,
          link: values.link,
          tags: getCorrectTags(oc(values.tags)([])).join(','),
          pixel,
          images: contentImages
        };

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        fetch('/api/upload', {
          method: 'POST',
          body: JSON.stringify(contentData),
          headers: myHeaders
        })
          .then(res => res.text())
          .then(response => {
            const content = response.replace(/^f/i, '0x02').replace('\n', '');
            if (dailyMode) {
              wallStore.dailySetContent(id, content, String(constLiquidity));
            } else {
              wallStore.setContent(id, content);
            }
          })
          .catch(error => console.log('error', error));
      }
    });

    const resetFormWithImage = () => {
      resetForm();
      setResetImage(true);
    };

    const handleCancel = () => {
      wallStore.cancelStatus(id || '');
    };

    const handleFinish = () => {
      wallStore.cancelRentArea(id || '');
    };

    const isRentedOut =
      selectedArea?.item.status === 'Rented' &&
      oc(selectedArea).item.tenant.id('') !== tgWallStore.userAddress;

    const isMyRentedOut =
      selectedArea?.item.status === 'Rented' &&
      oc(selectedArea).item.tenant.id('') === tgWallStore.userAddress;

    return selectedArea ? (
      <>
        {oc(selectedArea)?.cluster.id(undefined) && (
          <>
            <div className={s.topCluster}>
              <Typography
                variant="caption"
                color="textSecondary"
                className={s.partClusterTitle}
                gutterBottom
              >
                {t('part_of_cluster')}
                <NavLink to={`/my/clusters/${selectedArea.cluster?.id}`}>
                  {t('cluster_default_name', {
                    areas: oc(selectedCluster).areas.length(0)
                  })}
                </NavLink>
              </Typography>
            </div>
            {selectedArea.externalToken ? (
              <div className={s.content}>
                <ExternalNftPopper externalNFT={selectedArea.externalToken} />
              </div>
            ) : null}
            <div className={cx(s.top, s.smallTop)}>
              <Typography variant="h4" className={s.largeInput}>
                {t('area_title', { x: selectedArea.x, y: selectedArea.y })}
              </Typography>
              {!isRentedOut && !isMyRentedOut && (
                <>
                  <ButtonBase {...bindToggle(popupState)} disableRipple>
                    <div className={s.dots}>
                      <div className={s.dot} />
                      <div className={s.dot} />
                      <div className={s.dot} />
                    </div>
                  </ButtonBase>
                  <ActionsPopupDelete
                    popupState={popupState}
                    areaId={id}
                    clusterId={selectedArea.cluster?.id}
                  />
                </>
              )}
            </div>
            {selectedCluster && (
              <div className={cx(s.form, s.formDisabled)}>
                {selectedCluster.item?.title && (
                  <TextField
                    value={selectedCluster.item?.title}
                    label={t('description')}
                    multiline
                    disabled
                  />
                )}
                {selectedCluster.item?.title && (
                  <TextField
                    value={selectedCluster.item?.link}
                    label={t('link')}
                    disabled
                  />
                )}
                {oc(selectedCluster).item.tags([]).length > 0 && (
                  <Autocomplete
                    value={selectedCluster.item?.tags}
                    multiple
                    options={tags}
                    classes={{
                      input: s.autocompleteInput,
                      endAdornment: s.autocompleteEnd,
                      tag: s.autocompleteTag
                    }}
                    disabled
                    renderInput={params => (
                      <TextField {...params} label={t('tags')} fullWidth />
                    )}
                  />
                )}
                {oc(selectedCluster).areas([]).length && (
                  <div>
                    <InputLabel shrink disabled>
                      {t('banner_image')}
                    </InputLabel>
                    {/* <img
                      src={oc(selectedCluster).previewImagePath('')}
                      className={s.clusterPreviewImage}
                      alt=""
                    /> */}
                    <RenderImage
                      id={selectedCluster.id}
                      classData={s.clusterPreviewImage}
                      alt=""
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {!oc(selectedArea).cluster?.id(undefined) && (
          <>
            <div className={s.top}>
              <TitleLink
                to={dailyMode ? '/defi/daily/areas' : '/my/areas'}
                title={t('settings')}
              />
              <ButtonBase {...bindToggle(popupState)} disableRipple>
                <div className={s.dots}>
                  <div className={s.dot} />
                  <div className={s.dot} />
                  <div className={s.dot} />
                </div>
              </ButtonBase>
              <ActionsPopup
                popupState={popupState}
                areaId={id}
                disabled={isRentedOut || isMyRentedOut || freezed || dailyMode}
              />
            </div>
            <div className={s.form}>
              {selectedArea.externalToken ? (
                <ExternalNftPopper externalNFT={selectedArea.externalToken} />
              ) : null}
              <div className={s.gotToRow}>
                <TextField
                  value={t('area_title', {
                    x: selectedArea.x,
                    y: selectedArea.y
                  })}
                  label={t('name')}
                  multiline
                  InputProps={{
                    classes: { input: s.largeInput }
                  }}
                  disabled
                />
                <Link
                  to={`/?areaId=${selectedArea.id}`}
                  variant="body1"
                  underline="none"
                  component={RouterLink}
                >
                  {t('go_to_area')}
                </Link>
              </div>
              <TextField
                value={values.title}
                onChange={e => setFieldValue('title', e.target.value)}
                error={!!values.title && !!errors.title}
                label={t('description')}
                placeholder={t('add')}
                InputLabelProps={{
                  shrink: true
                }}
                multiline
                disabled={isRentedOut || freezed}
              />
              {selectedArea.item.status !== 'None' && (
                <div className={s.status}>
                  <InputLabel shrink filled>
                    {t('status')}
                  </InputLabel>
                  <div className={s.row}>
                    {selectedArea.item.status === 'ForSale' && (
                      <Typography color="primary" className={s.label}>
                        {t('for_sale')}
                      </Typography>
                    )}
                    {selectedArea.item.status === 'ForRent' && (
                      <Typography color="primary" className={s.label}>
                        {t('for_rent')}
                      </Typography>
                    )}
                    {selectedArea.item.status === 'Rented' && (
                      <Typography color="primary" className={s.label}>
                        {t('rent_out_for', {
                          days:
                            parseInt(oc(selectedArea).item.rentDuration('0')) /
                            86400,
                          // tu do
                          date: ''
                          // date: moment(
                          //   parseInt(
                          //     oc(selectedArea).statusDetails.expirationTime('0')
                          //   ) * 1000
                          // ).format('DD.MM.YYYY')
                        })}
                      </Typography>
                    )}
                    {selectedArea.item.status !== 'Rented' && (
                      <ButtonBase disableRipple onClick={handleCancel}>
                        <Typography color="error">{t('unpublish')}</Typography>
                      </ButtonBase>
                    )}
                    {isMyRentedOut && (
                      <ButtonBase disableRipple onClick={handleFinish}>
                        <Typography color="error">
                          {t('finish_rent')}
                        </Typography>
                      </ButtonBase>
                    )}
                  </div>
                </div>
              )}
              <TextField
                value={values.link}
                onChange={e => setFieldValue('link', e.target.value)}
                error={!!values.link && !!errors.link}
                helperText={errors.link}
                label={t('link')}
                placeholder={t('add')}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={isRentedOut || freezed}
              />
              <Autocomplete
                value={values.tags}
                onChange={(e, value) =>
                  setFieldValue('tags', getCorrectTags(value))
                }
                multiple
                freeSolo
                filterSelectedOptions
                id="tags-standard"
                options={tags}
                classes={{
                  input: s.autocompleteInput,
                  endAdornment: s.autocompleteEnd,
                  tag: s.autocompleteTag
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('tags')}
                    placeholder={t('add_tags')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                )}
                disabled={isRentedOut || freezed}
              />
              <UploadImage
                areas={[
                  {
                    x: +oc(selectedArea).x('0') * SIZE_AREA,
                    y: +oc(selectedArea).y('0') * SIZE_AREA,
                    width: 10,
                    height: 10,
                    id: oc(selectedArea).id('0')
                  }
                ]}
                macroblocks={oc(wallStore).macroblocksData.value([])}
                resetImage={resetImage}
                setResetImage={setResetImage}
                onChange={value => setFieldValue('image', value)}
                imageUrl={imageUrl}
                disabled={isRentedOut || freezed}
              />
            </div>
            <div className={s.bottom}>
              <ButtonBase disableRipple onClick={resetFormWithImage}>
                <Typography variant="body1" className={s.cancel}>
                  {t('cancel')}
                </Typography>
              </ButtonBase>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                disabled={!isValid || wallStore.isSetContentRequesting}
                onClick={submitForm}
                size="large"
              >
                {wallStore.isSetContentRequesting ? (
                  <CircularProgress size={16} color="secondary" />
                ) : (
                  <Typography variant="body1">
                    <strong>
                      {t('save')}
                      {!!constLiquidityNum && ` | ${constLiquidityNum} MATIC`}
                    </strong>
                  </Typography>
                )}
              </Button>
            </div>
          </>
        )}
        <SetContentErrorDialog />
        <SetContentSuccessDialog />
        <CancelErrorDialog />
        <CancelSuccessDialog />
      </>
    ) : (
      <CircularProgress className={s.loader} />
    );
  });
};

export default MyAreaEdit;
