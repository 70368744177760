import React from 'react';
import WallStore from './WallStore';
import TgDailyStore from './TgDailyStore';
import TgLoanStore from './TgLoanStore';
import TgWallStore from './TgWallStore';

export const stores = {
  wallStore: new WallStore(),
  tgDailyStore: new TgDailyStore(),
  tgLoanStore: new TgLoanStore(),
  tgWallStore: new TgWallStore()
};

export const storesContext = React.createContext(stores);
