import React, { useState } from 'react';
import {
  Button,
  ButtonBase,
  CircularProgress,
  Popover,
  TextField,
  Typography,
  InputAdornment
} from '@material-ui/core';
import { bindPopover } from 'material-ui-popup-state/hooks';
import { oc } from 'ts-optchain';
import s from './Popups.scss';
import { ActionsPopupBaseProps } from './ActionsPopup/ActionsPopup';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../Hooks/useStores';
import { useReaction } from '../../../Hooks/useReaction';
import { RemoteDataState } from '../../../Utils/RemoteData';
import Web3 from 'web3';
import { observer } from 'mobx-react-lite';

const SellPopup: React.FC<ActionsPopupBaseProps> = ({ popupState, areaId }) => {
  const { t } = useTranslation();
  const { wallStore, tgWallStore } = useStores();
  const [amount, setAmount] = useState(0);
  const [amountInput, setAmountInput] = useState('');

  const area = oc(tgWallStore)
    .areas.value.areas([])
    .find(i => i.id === areaId);

  const operationFee =
    area && area.premium
      ? 0
      : +oc(tgWallStore).wall.value.feePercents('0') / 100;

  useReaction(
    () => wallStore.sell.state,
    state => {
      if (state === RemoteDataState.SUCCESS) {
        popupState.close();
      }
    }
  );

  const sell = () => {
    if (areaId) {
      wallStore.sellArea(areaId, Web3.utils.toWei(amount.toString(), 'ether'));
    }
  };

  const cancel = () => {
    popupState.close();
  };

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value.replace(/,/g, '.').replace(/[^\d,.]/g, '');
    if ((value.match(/\./g) || []).length > 1) return;
    setAmountInput(value);
    const valueNumber = parseFloat(value);
    setAmount(isNaN(valueNumber) ? 0 : valueNumber);
  };

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      PaperProps={{
        square: true
      }}
    >
      <div className={s.container}>
        <Typography variant="h5" className={s.title}>
          {t('sell')}
        </Typography>
        <TextField
          value={amountInput}
          onChange={e => handleInput(e)}
          label={t('price')}
          placeholder={t('add')}
          type="text"
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            classes: { input: s.input },
            endAdornment: <InputAdornment position="end">MATIC</InputAdornment>
          }}
        />
        <Typography variant="caption" color="textSecondary">
          {t('sell_fee', {
            value: ((amount || 0) * operationFee).toFixed(2)
          })}
        </Typography>
        <div className={s.buttons}>
          <ButtonBase disableRipple onClick={cancel}>
            <Typography variant="caption">{t('cancel')}</Typography>
          </ButtonBase>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            disabled={!amount || amount <= 0 || wallStore.isSellRequesting}
            onClick={sell}
          >
            {wallStore.isSellRequesting ? (
              <CircularProgress size={12} color="secondary" />
            ) : (
              <Typography variant="caption">{t('sell')}</Typography>
            )}
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default observer(SellPopup);
