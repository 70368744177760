import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { getActionString } from '../../Helpers/labels';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';

interface RentButtonProps {
  onClick: () => void;
  statusCode: StatusTgType;
  duration?: string;
  price: string;
  className?: string;
  disabled?: boolean;
  type: 'cluster' | 'area';
}

const RentButton: React.FC<RentButtonProps> = ({
  onClick,
  statusCode,
  duration,
  price,
  className,
  disabled,
  type
}) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="outlined"
      color="primary"
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography variant="body2">
        <strong>{getActionString(statusCode, type)}</strong>{' '}
        {duration && duration !== '0'
          ? `(${t('days_value', {
              value: parseInt(duration) / 86400
            })}) `
          : ''}
        |{' '}
        {t('eth_value', {
          value: Web3.utils.fromWei(price)
        })}
      </Typography>
    </Button>
  );
};

export default RentButton;
