import React, { useState, useRef, useEffect } from 'react';
import s from './AvatarDialog.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Cropper from 'react-cropper';
import { useTranslation } from 'react-i18next';
import 'cropperjs/dist/cropper.css';
import { useStores } from '../../../Hooks/useStores';
import {
  SetAvatarErrorDialog,
  SetAvatarSuccessDialog
} from '../../Dialogs/Dialogs';

const MAX_SIZE_IMAGE = 64000;

interface NicknameDialogProps {
  isDialogOpen: boolean;
  closeDialog: () => void;
  imageUrl: string;
}

const AvatarDialog: React.FC<NicknameDialogProps> = ({
  isDialogOpen,
  closeDialog,
  imageUrl
}) => {
  const { t } = useTranslation();
  const [disabledBtnSave, setDisabledBtnSave] = useState(false);
  const { wallStore } = useStores();
  const cropperRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (isDialogOpen) {
      setDisabledBtnSave(false);
    }
  }, [isDialogOpen]);

  const save = (quality = 1) => {
    setDisabledBtnSave(true);
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;

    if (quality < 1) {
      cropper.canvasData.naturalWidth =
        cropper.canvasData.naturalWidth * quality;
      cropper.canvasData.naturalHeight =
        cropper.canvasData.naturalHeight * quality;

      cropper.imageData.naturalWidth = cropper.imageData.naturalWidth * quality;
      cropper.imageData.naturalHeight =
        cropper.imageData.naturalHeight * quality;
    }

    cropper.getCroppedCanvas().toBlob(
      async (blob: Blob) => {
        if (blob.size > MAX_SIZE_IMAGE) {
          return save(quality - 0.01);
        }
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'image/jpeg');
        fetch('/api/upload', {
          method: 'POST',
          body: blob,
          headers: myHeaders
        })
          .then(res => {
            if (res.status !== 200) {
              throw res.statusText;
            }
            return res.text();
          })
          .then(response =>
            wallStore.setAvatar(
              response.replace(/^f/i, '0x06').replace('\n', '')
            )
          )
          .then(() => {
            setDisabledBtnSave(false);
            closeDialog();
          })
          .catch(error => {
            alert(`Error: ${error}`);
            setDisabledBtnSave(false);
          });
      },
      'image/jpeg',
      0.8
    );
  };

  return (
    <>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>{t('upload_avatar')}</DialogTitle>
        <DialogContent className={s.content}>
          <Cropper
            src={imageUrl}
            style={{ height: 400, width: '100%' }}
            aspectRatio={1}
            viewMode={1}
            guides={false}
            ref={cropperRef}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => save()}
            color="primary"
            variant="contained"
            disableElevation
            autoFocus
            disabled={disabledBtnSave}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
      <SetAvatarErrorDialog />
      <SetAvatarSuccessDialog />
    </>
  );
};

export default AvatarDialog;
