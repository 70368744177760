import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import s from './RatingItem.scss';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NicknameDialog from '../NicknameDialog/NicknameDialog';
import AvatarDialog from '../AvatarDialog/AvatarDialog';
import defaultUser from '../../../Resources/default_user.png';
import { fetchImgUrl } from '../../../Utils/Storage';

interface RatingItemProps {
  value: number;
  unit: string;
  address: string;
  index: number;
  nickname?: string;
  primary?: boolean;
  my?: boolean;
  avatarCID?: string;
  currentUser?: UserTgType;
}

const RatingItem: React.FC<RatingItemProps> = ({
  value,
  unit,
  address,
  index,
  primary,
  nickname,
  my,
  avatarCID,
  currentUser
}) => {
  const { t } = useTranslation();
  const [isSetNicknameOpen, setNicknameOpen] = useState(false);
  const [isAvatarUploadOpen, setAvatarUploadOpen] = useState(false);
  const [imageObject, setImageObject] = useState('');
  const [currentUserAvatar, setCurrentUserAvatar] = useState('');
  const [userAvatar, setUserAvatar] = useState('');
  const avatarForm = useRef(null);
  const currentUserCensored = currentUser?.censored;

  useEffect(() => {
    if (currentUser?.avatarCID && my) {
      fetchImgUrl(currentUser?.avatarCID, setCurrentUserAvatar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.avatarCID, my]);

  useEffect(() => {
    if (avatarCID && !my) {
      fetchImgUrl(avatarCID, setUserAvatar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarCID, my]);

  const handleNicknameDialog = (value: boolean) => () => {
    setNicknameOpen(value);
  };

  const handleAvatarDialog = (value: boolean) => () => {
    setAvatarUploadOpen(value);
    if (!value && avatarForm.current) {
      (avatarForm.current as any).reset();
    }
  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageObject(URL.createObjectURL(e.target.files[0]));
      setAvatarUploadOpen(true);
    }
  };

  const avatarSection = my ? (
    currentUser?.avatarCID ? (
      <div
        className={cx(s.avatar, s.cover, my && s.myAvatar)}
        style={{ backgroundImage: `url(${currentUserAvatar})` }}
      />
    ) : (
      <div className={cx(s.avatar, s.upload)}>
        <Typography variant="caption">{t('add_userpic')}</Typography>
      </div>
    )
  ) : (
    <div
      className={cx(s.avatar, s.cover, my && s.myAvatar)}
      style={{ backgroundImage: `url(${userAvatar || defaultUser})` }}
    />
  );

  const nicknameSection = my ? (
    currentUser?.nickname ? (
      <Typography
        style={{ cursor: 'pointer' }}
        className={s.address}
        onClick={handleNicknameDialog(true)}
        title={t('change_nickname')}
      >
        {currentUser.nickname}
      </Typography>
    ) : (
      <Button
        color="primary"
        variant="contained"
        onClick={handleNicknameDialog(true)}
      >
        {t('add_nickname')}
      </Button>
    )
  ) : (
    nickname && <Typography className={s.address}>{nickname}</Typography>
  );

  return (
    <>
      <div
        className={cx(
          s.container,
          primary && s.primary,
          my && s.my,
          my && currentUserCensored && s.userCensored
        )}
      >
        <Typography className={s.index}>{index ? `${index}.` : ``}</Typography>
        {my ? (
          <form ref={avatarForm}>
            <input
              accept="image/*"
              className={s.uploadInput}
              id="uploadarea"
              multiple
              type="file"
              onChange={handleFile}
            />
            <label htmlFor="uploadarea">{avatarSection}</label>
          </form>
        ) : (
          avatarSection
        )}
        <div className={s.right}>
          <div className={s.top}>
            {nicknameSection}
            <Typography className={s.address} variant={'caption'}>
              {address}
            </Typography>
          </div>
          {my && currentUserCensored && (
            <div>
              <Typography className={s.alert} variant="body2">
                {t('alert_text_rating')}
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                href={`mailto:info@thewall.global?subject=User ban`}
                className={s.report}
              >
                {t('support')}
              </Button>
            </div>
          )}
          {my && currentUserCensored ? null : (
            <div className={s.value}>
              <Typography
                className={s.pixels}
                variant={primary ? 'h4' : my ? 'h5' : 'body1'}
              >
                {value}
              </Typography>
              <Typography
                className={s.unit}
                variant={primary ? 'h4' : my ? 'h5' : 'body1'}
              >
                &nbsp;<span>{unit}</span>
              </Typography>
            </div>
          )}
        </div>
      </div>
      {my && (
        <NicknameDialog
          isDialogOpen={isSetNicknameOpen}
          closeDialog={handleNicknameDialog(false)}
        />
      )}
      {my && (
        <AvatarDialog
          isDialogOpen={isAvatarUploadOpen}
          closeDialog={handleAvatarDialog(false)}
          imageUrl={imageObject}
        />
      )}
    </>
  );
};

export default RatingItem;
