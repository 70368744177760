import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import { RemoteDataState } from '../../Utils/RemoteData';
import { getPreview } from '../../Utils/Preview';
import { oc } from 'ts-optchain';

const URI_IPFS = window.config.URI_IPFS;

interface RenderImageProps {
  id: string;
  classData: string;
  imageCID?: string[];
  alt?: string;
}

const RenderImage: React.FC<RenderImageProps> = ({
  id,
  classData,
  imageCID,
  alt = ''
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const { tgWallStore } = useStores();

  useEffect(() => {
    if (tgWallStore.areas.state === RemoteDataState.SUCCESS) {
      getPreview(id)
        .then(path => {
          if (path) {
            setImageUrl(path);
          } else {
            throw new Error();
          }
        })
        .catch(e => {
          if (imageCID && oc(imageCID)([]).length > 0) {
            setImageUrl(`${URI_IPFS}/${imageCID[0]}`);
          }
          console.log('Error', e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgWallStore.areas.state]);

  return <img src={imageUrl} className={classData} alt={alt} />;
};

export default observer(RenderImage);
