import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Web3 from 'web3';
import { oc } from 'ts-optchain';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../Hooks/useStores';
import ItemBar from '../../../../../Components/Defi/ItemBar/ItemBar';
import {
  getDeadlineTimeT,
  getRefundAmountStringTg
} from '../../../../../Utils/defiUtils';
import RowComponent from '../../../../../Components/Defi/RowComponent/RowComponent';
import AreaCollateralLink from '../../../AreaCollateralLink/AreaCollateralLink';

import s from './OffersItem.scss';

interface OffersItemProps {
  offer: LoanTgOffersType;
  disabled: boolean;
}

const OffersItem: React.FC<OffersItemProps> = ({ offer, disabled }) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();

  const handleClickRefund = () => {
    wallStore.repay(Number(offer.id), Number(offer.refund_wei));
  };

  const offerDeadline = offer.deadline ? Number(offer.deadline) : null;

  const dedlineTimeString = getDeadlineTimeT(offerDeadline);

  const expiredOffer = offer.status === 'Overdue';
  const summary = [
    <div key="1">
      <ItemBar
        title={t('loan_amount')}
        value={`<span class="wordBreakAll">${Web3.utils.fromWei(
          offer.loan_wei || '0'
        )}</span>`}
        note={t('matic')}
      />
      {expiredOffer && (
        <Typography variant="caption" className={s.alert}>
          {t('collateral')}{' '}
          <AreaCollateralLink areaId={Number(offer.area_id)} />
        </Typography>
      )}
    </div>,
    <div key="2">
      <ItemBar
        title={t('refund_amount')}
        value={getRefundAmountStringTg(offer, 1)}
      />
      <Typography variant="caption" className={cx(expiredOffer && s.alert)}>
        {t('till')} {dedlineTimeString}
      </Typography>
    </div>
  ];

  const details = [
    <div key="1">
      <div>
        <Typography variant="caption">{t('collateral')}</Typography>
        <Typography variant="caption">
          <AreaCollateralLink areaId={Number(offer.area_id)} />
        </Typography>
      </div>
    </div>,
    <div key="2">
      {!expiredOffer && (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClickRefund}
          disabled={disabled}
        >
          {t('refund')}
        </Button>
      )}
    </div>
  ];

  return (
    <>
      <div className={s.mainDesktop}>
        <div>
          <ItemBar
            title={t('loan_amount')}
            value={`<span class="wordBreakAll">${Web3.utils.fromWei(
              offer.loan_wei || '0'
            )}</span>`}
            note={t('matic')}
          />
          <ItemBar
            title={t('expiration_date')}
            value={dedlineTimeString}
            note=""
            color={expiredOffer ? 'red' : ''}
          />
          <ItemBar
            title={t('refund_amount')}
            value={getRefundAmountStringTg(offer)}
          />
        </div>
        <div>{details}</div>
      </div>
      <div className={s.mainMobile}>
        <RowComponent summary={summary} details={expiredOffer ? [] : details} />
      </div>
    </>
  );
};

export default observer(OffersItem);
