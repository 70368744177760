import React, { useRef } from 'react';
import { ButtonBase, Typography, Popper } from '@material-ui/core';
import { bindPopper } from 'material-ui-popup-state';
import useClipboard from 'react-use-clipboard';
import s from '../../MyPlaces.scss';
import { PopupState } from 'material-ui-popup-state/core';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import { useStores } from '../../../../Hooks/useStores';
import { observer } from 'mobx-react-lite';

export interface ActionsPopupLinkProps {
  popupState: PopupState;
  areaId?: string;
  clusterId?: string;
}

const ActionsPopupLink: React.FC<ActionsPopupLinkProps> = ({
  popupState,
  areaId,
  clusterId
}) => {
  const { t } = useTranslation();
  const { tgWallStore } = useStores();
  const containerRef = useRef(null);

  const [, setCopied] = useClipboard(
    `${window.location.origin}/?${areaId ? 'areaId' : 'clusterId'}=${areaId ||
      clusterId}&invite=${oc(tgWallStore).user.value.user.id('')}`
  );

  const copyRefLink = async () => {
    setCopied();
    popupState.close();
  };

  return (
    <div ref={containerRef} className={s.popperContainer}>
      <Popper
        {...bindPopper(popupState)}
        container={containerRef.current}
        placement="bottom-end"
      >
        <div className={s.popupItemLink}>
          <ButtonBase className={s.black} onClick={copyRefLink}>
            <Typography color="secondary">{t('copy_link')}</Typography>
          </ButtonBase>
        </div>
      </Popper>
    </div>
  );
};

export default observer(ActionsPopupLink);
