import { action, observable } from 'mobx';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import {
  createRemoteData,
  handleTgRemoteData,
  handleTgRemoteDataPagination,
  handleTgRemoteDataIdGt
} from '../Utils/RemoteData';
import {
  CONFIGURATIONS_QUERY,
  TAGS_QUERY,
  TAGS_UPDATE,
  USERS_QUERY,
  USERS_UPDATE,
  USER_QUERY,
  AREAS_FIELDS,
  AREAS_UPDATE,
  CLUSTERS_QUERY
} from '../Query/wall';
import { oc } from 'ts-optchain';
import { mergeArrWithReplacement } from '../Helpers/helpers';

// const URI = ''; // prod
const URI =
  'https://api.thegraph.com/subgraphs/name/statzky/the-wall-global-mumbai'; // test
const FIRST = 1000;

class TgWallStore {
  client = new ApolloClient({
    uri: URI,
    cache: new InMemoryCache()
  });

  @observable wall = createRemoteData<WallTgType>();
  @observable areas = createRemoteData<AreasDataTgType>();
  @observable clusters = createRemoteData<ClustersDataTgType>();
  @observable tags = createRemoteData<TagsDataTypeTg>();
  @observable users = createRemoteData<UsersDataTypeTg>();
  @observable user = createRemoteData<UserDataTypeTg>();
  @observable searchItems = createRemoteData<ItemTgType[]>();
  @observable userAddress = '';

  constructor() {
    this.getConfiguration();
    this.getTags();
    this.getAreas();
    this.getClusters();

    // update interval
    setInterval(() => {
      this.getUpdateTags();
      this.getUpdateUsers();
      this.getAreas();
      this.getUser();
      this.getClusters();
    }, 60000);
  }

  @action
  async getConfiguration() {
    handleTgRemoteData(
      this.wall,
      async () =>
        this.client.query({
          query: gql`
            ${CONFIGURATIONS_QUERY}
          `
        }),
      data => (data as { configuration: WallTgType }).configuration
    );
  }

  @action
  getTags = async (
    skip = 0,
    receivedTags: TagTypeTg[] = [],
    revImpl: Nullable<RevImplType> = null
  ) => {
    const first = FIRST;
    this.client.cache.reset();
    handleTgRemoteData(
      this.tags,
      async () =>
        this.client.query({
          query: gql`
            ${TAGS_QUERY}
          `,
          variables: {
            skip,
            first,
            rev: '0'
          }
        }),
      data => {
        const { tags, configuration } = data as {
          tags: TagTypeTg[];
          configuration: { revImpl: RevImplType };
        };

        if (
          revImpl &&
          revImpl.hash !== configuration.revImpl.hash &&
          revImpl.id !== configuration.revImpl.id
        ) {
          this.getTags();
        }

        const result = tags.filter(i => !i.censored);
        if (tags.length < first) {
          return { tags: [...receivedTags, ...result], ...configuration };
        } else {
          this.getTags(
            skip + first,
            [...receivedTags, ...result],
            configuration.revImpl
          );
        }
        return this.tags.value;
      }
    );
  };

  @action
  getUpdateTags = async (
    skip = 0,
    receivedTags: TagTypeTg[] = [],
    revImpl: Nullable<RevImplType> = null
  ) => {
    const first = FIRST;
    this.client.cache.reset();
    handleTgRemoteData(
      this.tags,
      async () =>
        this.client.query({
          query: gql`
            ${TAGS_UPDATE}
          `,
          variables: {
            skip,
            first,
            rev: oc(this).tags.value.revImpl.id('0')
          }
        }),
      data => {
        const { tags, configuration, revision } = data as {
          tags: TagTypeTg[];
          configuration: { revImpl: RevImplType };
          revision: RevImplType;
        };

        if (
          revImpl &&
          revImpl.hash !== configuration.revImpl.hash &&
          revImpl.id !== configuration.revImpl.id
        ) {
          this.getUpdateTags();
        }

        if (
          revision.id !== this.tags.value?.revImpl.id ||
          revision.hash !== this.tags.value?.revImpl.hash
        ) {
          this.getTags();
        }

        const result = tags.filter(i => !i.censored);
        if (tags.length < first) {
          const dataResult: TagTypeTg[] = [];
          const updateDataResult: TagTypeTg[] = [];
          const currentTags: TagTypeTg[] = oc(this).tags.value.tags([]);

          // add new tags
          [...receivedTags, ...result].forEach(i => {
            if (currentTags.find(j => j.id === i.id)) {
              updateDataResult.push(i);
            } else {
              dataResult.push(i);
            }
          });

          // update existing tags
          currentTags.forEach(i => {
            const updateTag = updateDataResult.find(j => j.id === i.id);
            if (updateTag) {
              dataResult.push(updateTag);
            } else {
              dataResult.push(i);
            }
          });

          return { tags: dataResult, ...configuration };
        } else {
          this.getUpdateTags(
            skip + first,
            [...receivedTags, ...result],
            configuration.revImpl
          );
        }
        return this.tags.value;
      }
    );
  };

  processingUsers = (users: UserTgType[]) => {
    let rank = 1;
    return users.map((i, index, arr) => {
      const user: {
        nickname?: string;
        avatarCID?: string;
      } = { nickname: i.nickname, avatarCID: i.avatarCID || '' };
      if (i.censored) {
        user.nickname = undefined;
        user.avatarCID = undefined;
      }
      if (Number(i.scores)) {
        rank =
          index !== 0 && Number(arr[index - 1].scores) > Number(i.scores)
            ? rank + 1
            : rank;
      } else {
        rank = 0;
      }

      return { ...i, ...user, rank };
    });
  };

  @action
  getUsers = async () => {
    const first = FIRST;
    this.client.cache.reset();
    handleTgRemoteDataPagination(
      this.users,
      async skip =>
        this.client.query({
          query: gql`
            ${USERS_QUERY}
          `,
          variables: {
            skip,
            first,
            rev: '0'
          }
        }),
      null,
      data => {
        const { users } = data as {
          users: UserTgType[];
        };
        if (!Array.isArray(users)) {
          throw new Error(`Incorrect format of the received data`);
        }
        return users.length < first;
      },
      data => {
        if (!data.length) {
          return this.users.value;
        }
        const users: UserTgType[] = [];
        (data as UsersDataTypeTg[]).forEach(i => users.push(...i.users));
        return {
          users: this.processingUsers(users),
          revImpl: (data as { configuration: { revImpl: RevImplType } }[])[0]
            .configuration.revImpl
        };
      },
      { name: 'users', first: first, skip: 0 },
      []
    );
  };

  @action
  getUpdateUsers = async () => {
    if (!this.users.value?.revImpl?.id) {
      this.getUsers();
      return;
    }

    const first = FIRST;
    this.client.cache.reset();
    handleTgRemoteDataPagination(
      this.users,
      async skip =>
        this.client.query({
          query: gql`
            ${USERS_UPDATE}
          `,
          variables: {
            skip,
            first,
            rev: oc(this).users.value.revImpl.id('0')
          }
        }),
      null,
      data => {
        const { users, revision } = data as {
          users: UserTgType[];
          revision: RevImplType;
        };
        if (
          revision.id !== this.users.value?.revImpl.id ||
          revision.hash !== this.users.value?.revImpl.hash
        ) {
          this.getUpdateUsers();
          throw new Error(
            'There has been a restructuring of the blockchain head'
          );
        }

        if (!Array.isArray(users)) {
          throw new Error('Incorrect format of the received data');
        }
        return users.length < first;
      },
      data => {
        if (!data.length) {
          return this.users.value;
        }

        const users: UserTgType[] = [];
        (data as UsersDataTypeTg[]).forEach(i => users.push(...i.users));

        const dataResult: UserTgType[] = [];
        const updateDataResult: UserTgType[] = [];
        const currentItems: UserTgType[] = oc(this).users.value.users([]);

        // add new items
        users.forEach(i => {
          if (currentItems.find(j => j.id === i.id)) {
            updateDataResult.push(i);
          } else {
            dataResult.push(i);
          }
        });

        // update existing items
        currentItems.forEach(i => {
          const updateItem = updateDataResult.find(j => j.id === i.id);
          if (updateItem) {
            dataResult.push(updateItem);
          } else {
            dataResult.push(i);
          }
        });

        return {
          users: this.processingUsers(dataResult),
          revImpl: (data as { configuration: { revImpl: RevImplType } }[])[0]
            .configuration.revImpl
        };
      },
      { name: 'users', first: first, skip: 0 },
      []
    );
  };

  processingUser = (user: UserTgType) => {
    const areas: string[] = [];
    const clusters: string[] = [];

    user.items.forEach(i => {
      if (i.area) {
        areas.push(i.area.id);
      } else if (i.cluster) {
        clusters.push(i.cluster.id);
      }
    });

    return {
      ...user,
      avatarCID: user.avatarCID || '',
      nickname: user.nickname,
      areas,
      clusters
    };
  };

  @action
  setUserAddress = async (address: string) => {
    this.userAddress = address;
    if (address) {
      this.getUser();
    }
  };

  @action
  getUser = async () => {
    const id = this.userAddress;
    if (!id) return;

    this.client.cache.reset();
    handleTgRemoteData(
      this.user,
      async () =>
        this.client.query({
          query: gql`
            ${USER_QUERY}
          `,
          variables: {
            id
          }
        }),
      data => {
        const { user, configuration } = data as {
          user: UserTgType;
          configuration: { revImpl: RevImplType };
        };
        return { user: this.processingUser(user), ...configuration };
      }
    );
  };

  processingAreas = (areas: AreaTgType[]) => {
    return areas.map(i => {
      if (i.item?.tags.length === 1 && i.item?.tags[0] === '') {
        return {
          ...i,
          item: { ...i.item, tags: [] }
        };
      } else {
        return i;
      }
    });
  };

  // @action
  // getAreas = async () => {
  //   const first = FIRST;
  //   this.client.cache.reset();
  //   handleTgRemoteDataIdGt(
  //     this.areas,
  //     async idGt =>
  //       this.client.query({
  //         query: gql`
  //           ${AREAS_QUERY}
  //         `,
  //         variables: {
  //           idGt,
  //           first,
  //           rev: '0'
  //         }
  //       }),
  //     null,
  //     data => {
  //       const { areas } = data as {
  //         areas: AreaTgType[];
  //       };
  //       if (!Array.isArray(areas)) {
  //         throw new Error(`Incorrect format of the received data`);
  //       }
  //       // return areas.length < first;
  //       return true;
  //     },
  //     data => {
  //       if (!data.length) {
  //         return this.areas.value;
  //       }
  //       const areas: AreaTgType[] = [];
  //       (data as AreasDataTgType[]).forEach(i => areas.push(...i.areas));
  //       return {
  //         areas: this.processingAreas(areas),
  //         revImpl: (data as { configuration: { revImpl: RevImplType } }[])[0]
  //           .configuration.revImpl
  //       };
  //     },
  //     { name: 'areas', first: first, idGt: '0' },
  //     []
  //   );
  // };

  @action
  getAreas = async () => {
    const first = FIRST;
    this.client.cache.reset();
    handleTgRemoteDataIdGt(
      this.areas,
      async idGt =>
        this.client.query({
          query: gql`
            ${AREAS_UPDATE}
          `,
          variables: {
            idGt,
            first,
            rev: oc(this).areas.value.revImpl.id('0')
          }
        }),
      null,
      data => {
        const { items } = data as {
          items: { area: AreaTgType }[];
        };

        if (!Array.isArray(items)) {
          throw new Error(`Incorrect format of the received data`);
        }
        return items.length < first;
      },
      data => {
        if (!data.length) {
          return this.areas.value;
        }
        const areas: AreaTgType[] = [];
        (data as { items: ItemTgType[] }[]).forEach(i =>
          i.items.forEach(j => {
            if (j.area) {
              if (
                j.area.item?.tags.length === 1 &&
                j.area.item?.tags[0] === ''
              ) {
                areas.push({
                  ...j.area,
                  item: { ...j.area.item, tags: [] }
                });
              } else {
                areas.push(j.area);
              }
            }
          })
        );
        console.table({
          areas_length: areas.length,
          areas_rev: oc(this).areas.value.revImpl.id('0')
        });
        return {
          areas: mergeArrWithReplacement(oc(this).areas.value.areas([]), areas),
          revImpl: (data as { configuration: { revImpl: RevImplType } }[])[0]
            .configuration.revImpl
        };
      },
      { name: 'items', first: first, idGt: '0' },
      []
    );
  };

  @action
  getClusters = async () => {
    const first = FIRST;
    this.client.cache.reset();
    handleTgRemoteDataIdGt(
      this.clusters,
      async idGt =>
        this.client.query({
          query: gql`
            ${CLUSTERS_QUERY}
          `,
          variables: {
            idGt,
            first,
            rev: oc(this).clusters.value.revImpl.id('0')
          }
        }),
      null,
      data => {
        const { items } = data as {
          items: { cluster: ClusterTgType }[];
        };

        if (!Array.isArray(items)) {
          throw new Error(`Incorrect format of the received data`);
        }
        return items.length < first;
      },
      data => {
        if (!data.length) {
          return this.clusters.value;
        }
        const clusters: ClusterTgType[] = [];
        (data as { items: ItemTgType[] }[]).forEach(i =>
          i.items.forEach(j => {
            if (j.cluster) {
              if (!j.cluster.removed) {
                if (
                  j.cluster.item?.tags.length === 1 &&
                  j.cluster.item?.tags[0] === ''
                ) {
                  clusters.push({
                    ...j.cluster,
                    item: { ...j.cluster.item, tags: [] }
                  });
                } else {
                  clusters.push(j.cluster);
                }
              }
            }
          })
        );
        console.table({
          clusters_length: clusters.length,
          clusters_rev: oc(this).clusters.value.revImpl.id('0')
        });
        return {
          clusters: mergeArrWithReplacement(
            oc(this).clusters.value.clusters([]),
            clusters
          ),
          revImpl: (data as { configuration: { revImpl: RevImplType } }[])[0]
            .configuration.revImpl
        };
      },
      { name: 'items', first: first, idGt: '0' },
      []
    );
  };

  // This request will be used before getting the all areas data.
  @action
  getAreasByIds = async (ids: string[]) => {
    if (this.areas.value?.revImpl) return;
    this.client.cache.reset();
    return handleTgRemoteData(
      this.areas,
      async () =>
        this.client.query({
          query: gql`
            query getAreasByIds {
              areas(where: {id_in: ["${ids.join(`","`)}"]}) {
                ${AREAS_FIELDS}
              }
            }
          `
        }),
      data => {
        const { areas } = data as {
          areas: AreaTgType[];
          configuration: { revImpl: RevImplType };
        };
        return {
          areas: mergeArrWithReplacement(
            oc(this).areas.value.areas([]),
            this.processingAreas(areas)
          ),
          revImpl: this.areas.value?.revImpl
        };
      }
    );
  };

  // This request will be used before getting the all areas data.
  @action
  getAreasByXY = async (x: string, y: string) => {
    if (
      this.areas.value?.revImpl ||
      oc(this)
        .areas.value.areas([])
        .find((i: AreaTgType) => i.x === x && i.y === y)
    )
      return;
    this.client.cache.reset();
    return handleTgRemoteData(
      this.areas,
      async () =>
        this.client.query({
          query: gql`
            query getAreasByIds {
              areas(where: {x: ${x}, y: ${y}}) {
                ${AREAS_FIELDS}
              }
            }
          `
        }),
      data => {
        const { areas } = data as {
          areas: AreaTgType[];
          configuration: { revImpl: RevImplType };
        };
        return {
          areas: mergeArrWithReplacement(
            oc(this).areas.value.areas([]),
            this.processingAreas(areas)
          ),
          revImpl: this.areas.value?.revImpl
        };
      }
    );
  };

  @action
  async getItemsBy(request: SearchRequestType) {
    this.client.cache.reset();
    const { tags } = request;
    if (!Array.isArray(tags) || !tags.length) return;
    return handleTgRemoteData(
      this.searchItems,
      async () =>
        this.client.query({
          query: gql`
            query searchAreas {
              items(first: 1000, where: { tags_contains: ["${tags.join(
                `","`
              )}"] }) {
                tags
                area {
                  id
                }
                cluster {
                  id
                }
              }
            }
          `
        }),
      data => {
        const { items } = data as {
          items: ItemTgType[];
        };
        return items;
      }
    );
  }

  @action
  clearSearch() {
    this.searchItems = createRemoteData();
  }
}

export default TgWallStore;
