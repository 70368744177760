import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';

import Infobox from '../Infobox';
import polygonIcon from '../../../Resources/Icons/Instructions/polygon_round.svg';
import maticIcon from '../../../Resources/Icons/Instructions/matic_round.svg';
import wallIcon from '../../../Resources/Icons/Instructions/wall_round.svg';

import s from '../Instructions.scss';

const WhereToBuyMaticCoins = () => {
  const { t } = useTranslation();
  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('where_to_buy_matic_coins')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_is_this_guide_about')}
            text={t('this_guide_is_about_matic_coins')}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Infobox
            image={polygonIcon}
            title={t('what_polygon_is')}
            text={t('polygon_is_a_second_level_network')}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Infobox
            image={maticIcon}
            title={t('what_is_matic_coins')}
            text={t('what_is_matic_coins_text')}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Infobox
            image={wallIcon}
            title={t('why_matic_is_needed_for')}
            text={t('why_matic_is_needed_for_text')}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Infobox
            icon="faQuestionCircle"
            title={t('where_to_buy_matic')}
            text={t('where_to_buy_matic_text')}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Infobox
            icon="faExclamationTriangle"
            title={t('what_to_pay_attention_for')}
            text={t('what_to_pay_attention_for_text')}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default WhereToBuyMaticCoins;
