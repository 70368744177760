/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import s from './Rating.scss';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RatingItem from './RatingItem/RatingItem';
import InfiniteScroll from 'react-infinite-scroller';
import AppStateContext, { MetamaskState } from '../../Contexts/AppStateContext';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import { oc } from 'ts-optchain';
import Search from './Search/Search';
import { RemoteDataState } from '../../Utils/RemoteData';

const Rating = () => {
  const { t } = useTranslation();
  const [filterStr, setFilterStr] = useState('');
  const [users, setUsers] = useState<UserTgType[]>([]);
  const { tgWallStore } = useStores();
  const { pluginState } = useContext(AppStateContext);

  useEffect(() => {
    tgWallStore.getUpdateUsers();
  }, []);

  useEffect(() => {
    if (tgWallStore.users.state === RemoteDataState.SUCCESS) {
      setUsers(oc(tgWallStore).users.value.users([]));
    }
  }, [tgWallStore.users.state, filterStr]);

  const loadMore = async () => {
    await tgWallStore.getUpdateUsers();
  };

  const handleSearch = (query: string) => {
    // setUsers([]);
    setFilterStr(query);
  };

  const filterUser = (c: UserTgType) => {
    if (c.rank < 1) return false;
    if (filterStr === '') return true;
    const filterStrLower = filterStr.toLowerCase();
    return (
      (c.id && c.id.toLowerCase().indexOf(filterStrLower) >= 0) ||
      (c.nickname && c.nickname.toLowerCase().indexOf(filterStrLower) >= 0)
    );
  };

  return (
    <>
      <Search onSearch={handleSearch} />
      <div className={s.container}>
        <Typography variant="h4" classes={{ h4: s.header }}>
          {t('top_50')}
        </Typography>
        <div className={s.list}>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={false}
            useWindow={false}
            threshold={1000}
            loader={
              <Typography className={s.loader} key={0}>
                Loading ...
              </Typography>
            }
          >
            {users
              .filter(c => filterUser(c))
              .sort((a, b) => {
                if (b.rank < 1) return -1;
                if (a.rank > b.rank) return 1;
                return -1;
              })
              .map(c => (
                <RatingItem
                  value={Number(c.scores)}
                  unit="px"
                  address={c.id}
                  index={c.rank}
                  primary={c.rank === 1}
                  key={c.id}
                  nickname={c.nickname}
                  avatarCID={c.avatarCID}
                  my={
                    pluginState === MetamaskState.Connected &&
                    c.id === tgWallStore.userAddress
                  }
                  currentUser={tgWallStore.user.value?.user}
                />
              ))}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default observer(Rating);
