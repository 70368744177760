/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, ButtonGroup } from '@material-ui/core';
import { oc } from 'ts-optchain';
import Web3 from 'web3';
import { observer } from 'mobx-react-lite';
import { RemoteDataState } from '../../../../Utils/RemoteData';
import { useStores } from '../../../../Hooks/useStores';
import ItemBar from '../../../../Components/Defi/ItemBar/ItemBar';
import DescriptionPanel from '../../../../Components/Defi/DescriptionPanel/DescriptionPanel';
import mapImg from '../../../../Resources/Defi/pngwing.com.png';
import s from './Statistics.scss';
import sDefi from '../../Defi.scss';

interface BottomItemProps {
  title: string;
  value: string;
  setValue(title: string): void;
}

const ButtonItem: React.FC<BottomItemProps> = ({ title, value, setValue }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={() => setValue(title)}
      variant={value === title ? 'contained' : 'outlined'}
      color={value === title ? 'primary' : 'default'}
    >
      {t(title)}
    </Button>
  );
};

const Statistics = () => {
  const { t } = useTranslation();
  const { tgLoanStore } = useStores();
  const [currentValue, setCurrentValue] = useState('gross_liquidity');

  useEffect(() => {
    tgLoanStore.getStatistics();
  }, []);

  useEffect(() => {
    if (
      tgLoanStore.statistics.state === RemoteDataState.SUCCESS ||
      tgLoanStore.statistics.state === RemoteDataState.FAILURE
    ) {
      setTimeout(() => tgLoanStore.getStatistics(), 60000);
    }
  }, [tgLoanStore.statistics.state]);

  const statistics = oc(tgLoanStore).statistics.value();

  const items = ['gross_liquidity', 'available_liquidity', 'pending_refund'];

  const getValue = (v: string) => {
    if (statistics) {
      switch (v) {
        case items[0]:
          return Web3.utils.fromWei(statistics.total_loan_amount);
        case items[1]:
          return Web3.utils.fromWei(statistics.currently_available_liquidity);
        case items[2]:
          return Web3.utils.fromWei(statistics.currently_to_refund);
        default:
          return '-';
      }
    }
    return '-';
  };

  return (
    <div className={s.main}>
      <div className={s.left}>
        <div className={s.statisticsBar}>
          {items.map(i => (
            <ItemBar
              title={t(i)}
              value={getValue(i)}
              note={t('matic')}
              key={i}
            />
          ))}
        </div>
        <div className={s.statisticsBarMobile}>
          <div className={s.bastBtnGroup}>
            <ButtonGroup>
              {items.map(i => (
                <ButtonItem
                  title={i}
                  value={currentValue}
                  setValue={setCurrentValue}
                  key={i}
                />
              ))}
            </ButtonGroup>
          </div>
          <Typography variant="h6">
            {getValue(currentValue)} <small>MATIC</small>
          </Typography>
        </div>
        <div className={s.LiquidityMap}>
          <img src={mapImg} alt="" className={s.mapImg} />
          <div>Liquidity map will appear here soon</div>
        </div>
      </div>
      <div className={sDefi.rightPageContent}>
        <DescriptionPanel content={t('thewall_loan_statistics_description')} />
      </div>
    </div>
  );
};

export default observer(Statistics);
