import React, { useState } from 'react';
import cx from 'classnames';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails
} from '@material-ui/core';
import s from './AreasList.scss';
import { useTranslation } from 'react-i18next';
import arrowIcon from '../../Resources/Icons/arrow_down.svg';
import { useParams, useHistory } from 'react-router-dom';
import RenderImage from '../RenderImage/RenderImage';

interface ExpansionBlockProps {
  title: string;
  areas: AreaTgType[];
  isExpanded?: boolean;
}

interface AreaItemProps extends AreaTgType {
  active?: boolean;
}

const AreaItem: React.FC<AreaItemProps> = ({
  id,
  item,
  x,
  y,
  active,
  imageCID
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    setTimeout(() => {
      history.push('/my/areas/' + id);
    }, 1);
    history.push('/my/areas/');
  };

  return (
    <div className={cx(s.item, active && s.active)} onClick={handleClick}>
      {imageCID.length > 0 ? (
        <RenderImage
          id={id}
          imageCID={imageCID}
          classData={s.image}
          alt={item.title}
        />
      ) : (
        <div className={s.empty} />
      )}
      <Typography>{t('area_title', { x, y })}</Typography>
      <Typography>
        <span className={s.color}>X</span> {x}
      </Typography>
      <Typography>
        <span className={s.color}>Y</span> {y}
      </Typography>
    </div>
  );
};

const AreasList: React.FC<ExpansionBlockProps> = ({
  title,
  areas,
  isExpanded
}) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={(e, expanded) => setExpanded(expanded)}
      elevation={0}
      square
    >
      <ExpansionPanelSummary classes={{ content: s.summary }}>
        <Typography variant="h5" color="textPrimary" className={s.title}>
          {title}
        </Typography>
        <div className={s.expand}>
          <Typography color="textSecondary" className={s.hide}>
            {expanded ? t('hide_areas') : t('show_areas')}
          </Typography>
          <img
            src={arrowIcon}
            alt="Expand"
            className={cx(s.arrow, expanded && s.down)}
          />
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={s.items}>
          {areas.map(area => (
            <AreaItem {...area} key={area.id} active={area.id === id} />
          ))}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default AreasList;
