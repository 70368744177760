export const CONFIGURATIONS_QUERY = `
query getWallConfiguration {
  configuration(id: 0) {
    id
    rev
    revImpl {
      id
      hash
      event
    }
    contractTheWall
    contractTheWallCore
    contractTheWallCoupons
    contractTheWallCryptaur
    wallWidth
    wallHeight
    fundsReceiver
    feePercents
    areaCost
    couponsCreated
    couponsUsed
    totalPayments
    lastCreatedArea {
      id
    }
    lastPending {
      id
    }
  }
}
`;

const CONFIGURATION = `
configuration(id: "0") {
  revImpl {
    id
    hash
  }
}
`;

const REVISION = `
revision(id: $rev) {
  id
  hash
}
`;

const TAGS = `
tags(skip: $skip, first: $first, where: {rev_gt: $rev}, orderBy: rank, orderDirection: desc) {
  id
  value
  rank
  rev
  censored
}
`;

export const TAGS_QUERY = `
query getTags($first: Int, $skip: Int, $rev: String) {
  ${TAGS}
  ${CONFIGURATION}
}
`;

export const TAGS_UPDATE = `
query getUpdateTags($first: Int, $skip: Int, $rev: String) {
  ${REVISION}
  ${TAGS}
  ${CONFIGURATION}
}
`;

const USER_FIELDS = `
id
rev
censored
nickname
avatarCID
referrer
totalPayments
referralPayments
coupons
ccoupons
scores
`;

const ITEMS = `
id
rev
owner {
  id
}
status
link
tags
title
cost
rentDuration
tenant {
  id
}
area {
  id
}
cluster {
  id
}
`;

const USER_FIELDS_FOR_USER = `
id
rev
censored
nickname
avatarCID
referrer
totalPayments
referralPayments
coupons
items {
  ${ITEMS}
}
ccoupons
scores
`;

const USERS = `
users(skip: $skip, first: $first, where: {rev_gt: $rev}, orderBy: scores, orderDirection: desc) {
  ${USER_FIELDS}
}
`;

export const USERS_QUERY = `
query getUsers($first: Int, $skip: Int, $rev: String) {
  ${USERS}
  ${CONFIGURATION}
}
`;

export const USERS_UPDATE = `
query getUpdateUsers($first: Int, $skip: Int, $rev: String) {
  ${REVISION}
  ${USERS}
  ${CONFIGURATION}
}
`;

export const USER_QUERY = `
query getUser($id: ID) {
  user(id: $id) {
    ${USER_FIELDS_FOR_USER}
  }
  ${CONFIGURATION}
}
`;

export const AREAS_FIELDS = `
id
item {
  ${ITEMS}
}
x
y
imageCID
pixel
hashOfSecret
premium
nonce
cluster {
  id
  item {
    ${ITEMS}
  }
}
externalToken {
  id
}
prevArea {
  id
}
`;

export const AREAS = `
areas(first: $first, where: {id_gt: $idGt}) {
  ${AREAS_FIELDS}
}
`;

export const AREAS_QUERY = `
query getAreas($first: Int, $idGt: ID, $rev: String) {
  ${AREAS}
  ${CONFIGURATION}
}
`;

export const AREAS_UPDATE = `
query getUpdateAreas($first: Int, $idGt: ID, $rev: String) {
  items (first: $first, where: {id_gt: $idGt, rev_gt: $rev, area_not: null}) {
    id
    rev
    area {
      ${AREAS_FIELDS}
    }
  }
  ${CONFIGURATION}
}
`;

const CLUSTER_FIELDS = `
id
revision
scores
removed
item {
  ${ITEMS}
}
areas {
  ${AREAS_FIELDS}
}
`;

export const CLUSTERS_QUERY = `
query getClusters($first: Int, $idGt: ID, $rev: String) {
  items (first: $first, where: {id_gt: $idGt, rev_gt: $rev, cluster_not: null}) {
    id
    rev
    cluster {
      ${CLUSTER_FIELDS}
    }
  }
  ${CONFIGURATION}
}
`;
