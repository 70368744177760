export const mergeImagesArr = (
  images: string[],
  resultImage: string[]
): string[] => {
  let contentImages: string[];

  contentImages = images.map((i, index) => {
    if (resultImage[index]) {
      const imgBtih = resultImage[index];
      delete resultImage[index];
      return imgBtih;
    } else {
      return i;
    }
  });
  return [...contentImages, ...resultImage].filter(i => i);
};
