import Konva from 'konva';
// import { SIZE_AREA } from '../TheWall';
// const GRAY_CONTAINER_ID = 'gray';
const GRAY_INACTIVE_CONTAINER_ID = 'gray_inac';

export const renderGrayLayer = (
  stage: Konva.Stage,
  width: number,
  height: number,
  areas?: Nullable<AreaCoordinate[]>,
  onActiveAreaClick?: (e?: Konva.KonvaEventObject<MouseEvent>) => void,
  onInactiveAreaClick?: () => void
) => {
  /*
stage.getLayers().each(c => {
if (c.id() === GRAY_CONTAINER_ID) {
  c.destroy();
}
});

if (areas) {
const offsetX = (-width * SIZE_AREA) / 2;
const offsetY = (-width * SIZE_AREA) / 2;

const grayLayer = new Konva.Layer({
  id: GRAY_CONTAINER_ID
});

const grayBox = new Konva.Rect({
  fill: 'rgba(0, 0, 0, 0.8)',
  y: offsetY,
  x: offsetX,
  width: width * SIZE_AREA,
  height: height * SIZE_AREA
});

grayLayer.add(grayBox);

if (onInactiveAreaClick) {
  grayBox.on('click', onInactiveAreaClick);
}

areas.forEach(c => {
  const lightBox = new Konva.Rect({
    ...c,
    fill: '#fff',
    opacity: 1,
    globalCompositeOperation: 'destination-out'
  });
  if (onActiveAreaClick) {
    lightBox.on('click', onActiveAreaClick);
  }
  grayLayer.add(lightBox);
});

grayLayer.cache();
stage.add(grayLayer);
 */
};

export const renderInactiveGrayLayer = (
  stage: Konva.Stage,
  areas?: AreaCoordinate[]
) => {
  stage.getLayers().forEach(c => {
    if (c.id() === GRAY_INACTIVE_CONTAINER_ID) {
      c.destroy();
    }
  });

  if (areas && areas.length) {
    const grayLayer = new Konva.Layer({
      id: GRAY_INACTIVE_CONTAINER_ID
    });

    areas.forEach(c => {
      const lightBox = new Konva.Rect({
        ...c,
        fill: '#000',
        opacity: 0.8
      });
      grayLayer.add(lightBox);
    });

    grayLayer.cache();
    stage.add(grayLayer);
  }
};
