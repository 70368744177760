import React, { useState } from 'react';
import cx from 'classnames';
import s from './FilterPrice.scss';
import { Popover, Typography, Button } from '@material-ui/core';
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-material-ui';

export interface FilterPriceValues {
  from?: number;
  to?: number;
}

interface FilterPriceProps {
  onChange: (values: FilterPriceValues) => void;
}

const FilterPrice: React.FC<FilterPriceProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState<FilterPriceValues>({});
  const popupState = usePopupState({ variant: 'popover', popupId: 'filter' });

  const getInitialValue = () => {
    const initialValues: FilterPriceValues = {};
    return initialValues;
  };

  const getTitle = () => {
    if (formState.from || formState.to) {
      return (
        t('price') +
        ': ' +
        (formState.from || 0) +
        ' — ' +
        (formState.to || '∞')
      );
    }

    return t('price');
  };

  const onSubmit = (values: FilterPriceValues) => {
    setFormState(values);
    onChange(values);
    popupState.close();
  };

  const PriceInput = ({ name }: { name: string }) => (
    <TextField
      name={name}
      color="secondary"
      disabled={false}
      type="number"
      InputProps={{
        startAdornment: (
          <Typography variant="body2" className={s.startAdornment}>
            {t(name)}
          </Typography>
        ),
        endAdornment: (
          <Typography variant="body2" className={s.endAdornment}>
            MATIC
          </Typography>
        )
      }}
    />
  );

  return (
    <div className={s.container}>
      <Button
        variant="outlined"
        className={cx(
          s.button,
          popupState.isOpen && s.active,
          (formState.to || formState.from) && s.selected
        )}
        {...bindTrigger(popupState)}
      >
        <Typography variant="body2">{getTitle()}</Typography>
      </Button>
      <Formik onSubmit={onSubmit} initialValues={getInitialValue()}>
        {({ submitForm }) => (
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            PaperProps={{
              square: true,
              elevation: 0
            }}
          >
            <div className={s.innerContainer}>
              <div className={s.inputs}>
                <PriceInput name="from" />
                <PriceInput name="to" />
              </div>
              <div className={s.buttons}>
                <Button onClick={popupState.close}>
                  <Typography variant="button">{t('cancel')}</Typography>
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  onClick={submitForm}
                >
                  {t('save')}
                </Button>
              </div>
            </div>
          </Popover>
        )}
      </Formik>
    </div>
  );
};

export default FilterPrice;
