import Konva from 'konva';
import {
  SIZE_AREA,
  HandleObjectMouseType,
  HandleObjectTouchendType
} from '../TheWall';
import dotsMap from '../../../Resources/field.png';
import dotsMapTop from '../../../Resources/fieldTop.png';
import dotsMapRight from '../../../Resources/fieldRight.png';
import dotsMapTopRight from '../../../Resources/fieldTopRight.png';
import { SELECT_LAYER_ID } from './renderScene';
import { stores } from '../../../Stores/RootStore';

export const renderGrid = (
  stage: Konva.Stage,
  width: number,
  height: number,
  onFieldTouchend?: HandleObjectTouchendType,
  onFieldClick?: HandleObjectMouseType,
  getMultipleState?: () => boolean,
  onMultipleSelect?: (value: AreaCoordinate) => void,
  renderClusterBorder?: (x: number, y: number, stage: Konva.Stage) => boolean
): void => {
  const { tgWallStore } = stores;
  var gridLayer = new Konva.Layer({
    listening: !!onFieldClick,
    id: 'grid_layer'
  });

  let multipleBoxes: Konva.Rect[] = [];
  let boxIndex = 0;

  let multipleMouseStartPoint = { x: 0, y: 0 };
  let multipleCorrection = { x: 0, y: 0 };
  let multipleMouseStartX: number = 0;
  let multipleMouseStartY: number = 0;
  let multipleMouseWidth: number = 0;
  let multipleMouseHeight: number = 0;
  let isSelecting = false;
  function getDistance(p1: any, p2: any) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  function getCenter(p1: any, p2: any) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2
    };
  }
  var lastCenter: any = null;
  var lastDist = 0;

  stage.on('touchmove', e => {
    e.evt.preventDefault();
    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];

    if (touch1 && touch2) {
      stage.stopDrag();
      var p1 = {
        x: touch1.clientX,
        y: touch1.clientY
      };
      var p2 = {
        x: touch2.clientX,
        y: touch2.clientY
      };

      if (!lastCenter) {
        lastCenter = getCenter(p1, p2);
        return;
      }
      var newCenter = getCenter(p1, p2);

      var dist = getDistance(p1, p2);

      if (!lastDist) {
        lastDist = dist;
      }

      // local coordinates of center point
      var pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX()
      };

      var scale = stage.scaleX() * (dist / lastDist);

      stage.scaleX(scale);
      stage.scaleY(-scale);

      // calculate new position of the stage
      var dx = newCenter.x - lastCenter.x;
      var dy = newCenter.y - lastCenter.y;

      var newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy
      };

      stage.position(newPos);

      lastDist = dist;
      lastCenter = newCenter;
    }
  });

  stage.on('touchend', () => {
    lastDist = 0;
    lastCenter = null;
  });

  gridLayer.on('mousedown touchstart', e => {
    if (getMultipleState && getMultipleState()) {
      isSelecting = true;
      multipleBoxes.forEach(c => c.destroy());
      const oldBox = gridLayer.findOne('#hightlightboxes_area');
      if (oldBox) {
        oldBox.destroy();
      }

      const transform = gridLayer.getAbsoluteTransform().copy();
      transform.invert();
      const pos = stage.getPointerPosition();
      if (pos == null) return;

      multipleMouseStartPoint = transform.point(pos);
      multipleMouseStartX = Math.floor(multipleMouseStartPoint.x / 10) * 10;
      multipleMouseStartY = Math.ceil(multipleMouseStartPoint.y / 10) * 10;
      multipleMouseHeight = 0;
      multipleMouseWidth = 0;
      multipleCorrection = { x: 0, y: 0 };

      const hoverBox = new Konva.Rect();
      hoverBox.strokeWidth(1);
      hoverBox.stroke('#001AFF');
      hoverBox.fill('rgba(0, 26, 255, 0.1)');
      hoverBox.id('hightlightboxes_area');
      hoverBox.on('click', () => {
        hoverBox.remove();
        gridLayer.draw();
      });
      hoverBox.y(multipleMouseStartY);
      hoverBox.x(multipleMouseStartX);
      gridLayer.add(hoverBox);
    }
  });

  const selectingEnd = () => {
    if (getMultipleState && getMultipleState()) {
      isSelecting = false;
      if (onMultipleSelect) {
        const height = multipleMouseHeight + multipleCorrection.y;
        const width = multipleMouseWidth - multipleCorrection.x;
        onMultipleSelect({
          x:
            width < 0
              ? multipleMouseStartX + multipleCorrection.x - Math.abs(width)
              : multipleMouseStartX,
          y:
            height < 0
              ? multipleMouseStartY - Math.abs(height)
              : multipleMouseStartY - multipleCorrection.y,
          height,
          width
        });
      }
    }
  };

  gridLayer.on('mousemove touchmove', e => {
    const hoverBox = gridLayer.findOne('#hightlightboxes_area');
    if (hoverBox && isSelecting && getMultipleState && getMultipleState()) {
      const transform = gridLayer.getAbsoluteTransform().copy();
      transform.invert();
      const pos = stage.getPointerPosition();
      if (pos == null) return;

      const result = transform.point(pos);
      if (
        Math.abs(result.y) > (height * SIZE_AREA) / 2 ||
        Math.abs(result.x) > (width * SIZE_AREA) / 2
      ) {
        selectingEnd();
        return;
      }
      const targetX = Math.round(result.x / 10) * 10;
      const targetY = Math.round(result.y / 10) * 10;
      multipleMouseWidth = targetX - multipleMouseStartX;
      multipleMouseHeight = targetY - multipleMouseStartY;
      if (result.x < multipleMouseStartPoint.x) {
        multipleCorrection.x = 10;
        hoverBox.x(multipleMouseStartX + 10);
        hoverBox.width(multipleMouseWidth - 10);
      } else {
        multipleCorrection.x = 0;
        hoverBox.x(multipleMouseStartX);
        hoverBox.width(multipleMouseWidth);
      }

      if (result.y > multipleMouseStartPoint.y) {
        multipleCorrection.y = 10;
        hoverBox.y(multipleMouseStartY - 10);
        hoverBox.height(multipleMouseHeight + 10);
      } else {
        multipleCorrection.y = 0;
        hoverBox.y(multipleMouseStartY);
        hoverBox.height(multipleMouseHeight);
      }

      gridLayer.batchDraw();
    }
  });

  gridLayer.on('mouseup touchend', () => {
    selectingEnd();
  });

  let touchX: number, touchY: number;

  gridLayer.on('touchstart', e => {
    if (getMultipleState && getMultipleState() && !isSelecting) {
      return;
    }
    touchX = e.evt.changedTouches[0].pageX;
    touchY = e.evt.changedTouches[0].pageY;
  });

  gridLayer.on('touchend', e => {
    if (
      Math.abs(e.evt.changedTouches[0].pageX - touchX) > 10 ||
      Math.abs(e.evt.changedTouches[0].pageY - touchY) > 10
    ) {
      return;
    }
    if (getMultipleState && getMultipleState() && !isSelecting) {
      return;
    }
    stage.getLayers().forEach(c => {
      if (c.id() === SELECT_LAYER_ID) {
        c.destroy();
      }
    });
    const transform = gridLayer.getAbsoluteTransform().copy();
    transform.invert();
    const pos = stage.getPointerPosition();
    if (pos == null) return;

    const result = transform.point(pos);
    if (
      Math.abs(result.y) > (height * SIZE_AREA) / 2 ||
      Math.abs(result.x) > (width * SIZE_AREA) / 2
    ) {
      return;
    }
    const targetX = Math.floor(result.x / SIZE_AREA) * SIZE_AREA;
    const targetY = Math.floor(result.y / SIZE_AREA) * SIZE_AREA;

    if (onFieldTouchend) {
      onFieldTouchend(e, { x: targetX, y: targetY });
    }

    multipleBoxes.forEach(c => c.destroy());

    if (renderClusterBorder && renderClusterBorder(targetX, targetY, stage)) {
      gridLayer.batchDraw();
      return;
    }

    const box = new Konva.Rect();

    box.visible(true);
    box.x(targetX);
    box.y(targetY);
    box.height(SIZE_AREA);
    box.width(SIZE_AREA);
    box.strokeWidth(1);
    box.stroke('#001AFF');
    box.id('hightlightboxes_' + boxIndex);
    box.on('touchend', () => {
      box.remove();
      gridLayer.draw();
      if (onFieldTouchend) {
        onFieldTouchend(e, { x: targetX, y: targetY }, true);
      }
    });
    boxIndex++;

    multipleBoxes.push(box);
    gridLayer.add(box);

    gridLayer.batchDraw();
  });

  gridLayer.on('click', e => {
    if (getMultipleState && getMultipleState() && !isSelecting) {
      return;
    }
    stage.getLayers().forEach(c => {
      if (c.id() === SELECT_LAYER_ID) {
        c.destroy();
      }
    });
    const transform = gridLayer.getAbsoluteTransform().copy();
    transform.invert();
    const pos = stage.getPointerPosition();
    if (pos == null) return;
    const result = transform.point(pos);
    if (
      Math.abs(result.y) >
        (height * SIZE_AREA) / 2 - (result.y < 0 ? 1 * SIZE_AREA : 0) ||
      Math.abs(result.x) >
        (width * SIZE_AREA) / 2 - (result.x < 0 ? 1 * SIZE_AREA : 0)
    ) {
      return;
    }
    const targetX = Math.floor(result.x / SIZE_AREA) * SIZE_AREA;
    const targetY = Math.floor(result.y / SIZE_AREA) * SIZE_AREA;

    if (onFieldClick) {
      tgWallStore
        .getAreasByXY(String(targetX / SIZE_AREA), String(targetY / SIZE_AREA))
        .then(() => onFieldClick(e, { x: targetX, y: targetY }));
    }

    multipleBoxes.forEach(c => c.destroy());

    if (renderClusterBorder && renderClusterBorder(targetX, targetY, stage)) {
      gridLayer.batchDraw();
      return;
    }

    const box = new Konva.Rect();

    box.visible(true);
    box.x(targetX);
    box.y(targetY);
    box.height(SIZE_AREA);
    box.width(SIZE_AREA);
    box.strokeWidth(1);
    box.stroke('#001AFF');
    box.id('hightlightboxes_' + boxIndex);
    box.on('click', () => {
      box.remove();
      gridLayer.draw();
      if (onFieldClick) {
        onFieldClick(e, { x: targetX, y: targetY }, true);
      }
    });
    boxIndex++;

    multipleBoxes.push(box);
    gridLayer.add(box);

    gridLayer.batchDraw();
  });

  stage.add(gridLayer);

  const offsetX = (-width * SIZE_AREA) / 2 + SIZE_AREA;
  const offsetY = (-height * SIZE_AREA) / 2 + SIZE_AREA;

  Konva.Image.fromURL(dotsMap, (node: Konva.Image) => {
    // one baked tile is 100x100 areas map
    for (let w = 0; w < width / 100 - 1; w++) {
      for (let h = 0; h < height / 100 - 1; h++) {
        const clone = node.clone({
          x: offsetX + 100 * SIZE_AREA * w - 1,
          y: offsetY + 100 * SIZE_AREA * h - 1,
          width: 1002,
          height: 1002
        });
        gridLayer.add(clone);
        gridLayer.batchDraw();
      }
    }
  });

  Konva.Image.fromURL(dotsMapTop, (node: Konva.Image) => {
    // one baked tile is 100x99 areas map
    for (let w = 0; w < width / 100 - 1; w++) {
      const clone = node.clone({
        x: offsetX + 100 * SIZE_AREA * w - 1,
        y: offsetY + 100 * SIZE_AREA * (height / 100 - 1) - 1,
        width: 1002,
        height: 992
      });
      gridLayer.add(clone);
      gridLayer.batchDraw();
    }
  });

  Konva.Image.fromURL(dotsMapRight, (node: Konva.Image) => {
    // one baked tile is 99x100 areas map
    for (let h = 0; h < width / 100 - 1; h++) {
      const clone = node.clone({
        x: offsetX + 100 * SIZE_AREA * (width / 100 - 1) - 1,
        y: offsetY + 100 * SIZE_AREA * h - 1,
        width: 992,
        height: 1002
      });
      gridLayer.add(clone);
      gridLayer.batchDraw();
    }
  });

  Konva.Image.fromURL(dotsMapTopRight, (node: Konva.Image) => {
    // one baked tile is 99x99 areas map
    const clone = node.clone({
      x: offsetX + 100 * SIZE_AREA * (width / 100 - 1) - 1,
      y: offsetY + 100 * SIZE_AREA * (height / 100 - 1) - 1,
      width: 992,
      height: 992
    });
    gridLayer.add(clone);
    gridLayer.batchDraw();
  });

  gridLayer.moveToBottom();
  gridLayer.draw();
};
