import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import TheWall from '../TheWall/TheWall';
import { MuiThemeProvider } from '@material-ui/core';
import theme from '../../Resources/Themes/theme';
import MetamaskInfo from '../../Components/MetamaskInfo/MetamaskInfo';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MyPlaces from '../MyPlaces/MyPlaces';
import AppStateContext, { MetamaskState } from '../../Contexts/AppStateContext';
import { FeeErrorDialog } from '../Dialogs/Dialogs';
import NetworkVersionErrorDialog from '../Dialogs/NetworkVersionErrorDialog';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Defi from '../../Components/Defi/Defi';
import TheWallTgLoan from '../Defi/TheWallTgLoan/TheWallTgLoan';
import TheWallTgDaily from '../Defi/TheWallTgDaily/TheWallTgDaily';
import Rating from '../Rating/Rating';
import Marketplace from '../Marketplace/Marketplace';
import Instructions from '../../Components/Instructions/Instructions';
import { useStores } from '../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import { RemoteDataState } from '../../Utils/RemoteData';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';

const App = () => {
  const [pluginState, setPluginState] = useState<MetamaskState>(
    MetamaskState.Initial
  );
  const [currentAccount, setCurrentAccount] = useState('');
  const [provider, setProvider] = useState<
    Nullable<ethers.providers.Web3Provider>
  >(null);
  const { wallStore, tgWallStore } = useStores();
  const [readOnly, setReadOnly] = useState(true);

  const setCurProvider = (notNull = true) => {
    if (!currentAccount) return;
    if (provider) {
      window.location.reload();
    }
    if (notNull) {
      setProvider(new ethers.providers.Web3Provider(window.ethereum));

      const filterToAccount = {
        topics: [null, null, null, ethers.utils.hexZeroPad(currentAccount, 32)]
      };

      const filterFromAccount = {
        topics: [null, null, ethers.utils.hexZeroPad(currentAccount, 32), null]
      };

      const toAccount = () => {
        // setBalance();
      };

      const fromAccount = () => {
        // setBalance();
      };
      if (provider) {
        provider.on(filterToAccount, toAccount);
        provider.on(filterFromAccount, fromAccount);
      }
    } else {
      setProvider(null);
    }
    // setBalance();
  };

  const handleAccountsChanged = (accounts: any) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      setPluginState(MetamaskState.Installed);
      setCurrentAccount('');
    } else if (accounts[0] !== currentAccount) {
      setCurrentAccount(accounts[0]);
      setPluginState(MetamaskState.Connected);
      setReadOnly(false);
    }
  };

  const handleChainChanged = () => {
    // We recommend reloading the page, unless you must do otherwise
    window.location.reload();
  };

  const startApp = async () => {
    const provider = await detectEthereumProvider();
    if (!provider) {
      setPluginState(MetamaskState.NotInstalled);
      return;
    }
    // If the provider returned by detectEthereumProvider is not the same as
    // window.ethereum, something is overwriting it, perhaps another wallet.
    if (provider !== window.ethereum) {
      console.error('Do you have multiple wallets installed?');
      setPluginState(MetamaskState.Installed);
      return;
    }
    await window.ethereum.request({ method: 'eth_chainId' });

    window.ethereum.on('chainChanged', handleChainChanged);

    window.ethereum
      .request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err: any) => {
        setPluginState(MetamaskState.Installed);
        // Some unexpected error.
        // For backwards compatibility reasons, if no accounts are available,
        // eth_accounts will return an empty array.
        console.error(err);
      });
    window.ethereum.on('accountsChanged', handleAccountsChanged);
  };

  useEffect(() => {
    if (
      tgWallStore.wall.state === RemoteDataState.SUCCESS &&
      tgWallStore.wall.value
    ) {
      wallStore.startApp(tgWallStore.wall.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgWallStore.wall.state]);

  useEffect(() => {
    if (wallStore.appStarted) {
      wallStore.getBorder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallStore.appStarted]);

  useEffect(() => {
    if (pluginState !== MetamaskState.Initial) {
      if (
        pluginState === MetamaskState.Connected ||
        pluginState === MetamaskState.Installed
      ) {
        setCurProvider();
      } else {
        setCurProvider(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pluginState, currentAccount]);

  useEffect(() => {
    startApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    wallStore.setProvider(provider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  useEffect(() => {
    wallStore.setCurrentAddress(currentAccount);
    tgWallStore.setUserAddress(currentAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  const disableApp = () => {
    setReadOnly(true);
  };

  const enableApp = () => {
    if (pluginState === MetamaskState.Connected) {
      setReadOnly(false);
    }
  };

  return (
    <ErrorBoundary>
      <AppStateContext.Provider
        value={{
          pluginState,
          readOnly,
          currentAccount,
          provider,
          disableApp,
          enableApp
        }}
      >
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <Header />
            <Switch>
              {/* <Route path="/defi/loan">
                <TheWallLoan />
              </Route> */}
              <Route path="/defi/loan">
                <TheWallTgLoan />
              </Route>
              {/* <Route path="/defi/daily">
                <TheWallDaily />
              </Route> */}
              <Route path="/defi/daily">
                <TheWallTgDaily />
              </Route>
              <Route path="/defi">
                <Defi />
              </Route>
              <Route path="/rating">
                <Rating />
              </Route>
              <Route path="/instructions">
                <Instructions />
              </Route>
              <Route path="/my">
                <MyPlaces />
              </Route>
              <Route path="/marketplace">
                <Marketplace />
              </Route>
              <Route path="/:id?">
                <TheWall />
              </Route>
            </Switch>
          </BrowserRouter>
          <MetamaskInfo handleAccountsChanged={handleAccountsChanged} />
          <FeeErrorDialog />
          <NetworkVersionErrorDialog />
        </MuiThemeProvider>
      </AppStateContext.Provider>
    </ErrorBoundary>
  );
};

export default observer(App);
