import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import s from './../Defi.scss';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  NavLink as RouterLink,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AppStateContext from '../../../Contexts/AppStateContext';
import Statistics from './Statistics/Statistics';
import TopOffers from './TopOffers/TopOffers';
import MyLiquidity from './MyLiquidity/MyLiquidity';
import MyLoans from './MyLoans/MyLoans';
import Instructions from '../../../Components/Instructions/Pages/DefiLoan';
import { useStores } from '../../../Hooks/useStores';
import Rating from './Rating/Rating';
import { RemoteDataState } from '../../../Utils/RemoteData';

interface TitleLinkProps {
  to: string;
  title: string;
}

export const TitleLink: React.FC<TitleLinkProps> = ({ to, title }) => {
  return (
    <Link
      to={to}
      activeClassName={s.active}
      underline="none"
      variant="h5"
      color="textPrimary"
      component={RouterLink}
      TypographyClasses={{ h5: s.nav }}
    >
      {title}
    </Link>
  );
};

export const TitleLinkDot: React.FC<TitleLinkProps> = ({ to, title }) => {
  return (
    <Link
      to={to}
      activeClassName={s.activeMobile}
      underline="none"
      variant="h5"
      color="textPrimary"
      component={RouterLink}
      TypographyClasses={{ h5: s.nav }}
    >
      <div className={s.dotMenu} title={title} />
    </Link>
  );
};

const STATISTICS = 'statistics';
const TOPOFFERS = 'topOffers';
const MYLIQUIDITY = 'myLiquidity';
const MYLOANS = 'myLoans';
const INSTRUCTIONS = 'manual';
const RATING = 'rating';

const CENTER = 'center';
const START = 'start';
const END = 'end';

const TheWallLoan = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { wallStore, tgLoanStore } = useStores();
  const readOnly =
    useContext(AppStateContext).readOnly || !wallStore.networkVersionTrue;
  const pathArr = readOnly
    ? [STATISTICS, TOPOFFERS, RATING, INSTRUCTIONS]
    : [STATISTICS, TOPOFFERS, MYLIQUIDITY, MYLOANS, RATING, INSTRUCTIONS];

  const path = pathArr.find(i => location.pathname.indexOf(`/${i}`) !== -1);

  useEffect(() => {
    if (tgLoanStore.configuration.state === RemoteDataState.SUCCESS) {
      const contractAddress = tgLoanStore.configuration.value?.contract;
      if (contractAddress) {
        wallStore.startAppLoan(contractAddress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgLoanStore.configuration.state]);

  let menuPosition = CENTER;
  if (pathArr.indexOf(path || '') === 0) {
    menuPosition = START;
  } else if (pathArr.indexOf(path || '') === pathArr.length - 1) {
    menuPosition = END;
  }

  return (
    <div className={s.container}>
      <div className={s.main}>
        <div className={s.top}>
          {pathArr.map(i => (
            <TitleLink to={`/defi/loan/${i}`} title={t(i)} key={i} />
          ))}
        </div>
        <div className={cx(s.mobileMenu, s[menuPosition])}>
          {pathArr.map(i => (
            <TitleLink to={`/defi/loan/${i}`} title={t(i)} key={i} />
          ))}
        </div>
        <Switch>
          <Route path={`/defi/loan/${STATISTICS}`}>
            <Statistics />
          </Route>
          <Route path={`/defi/loan/${TOPOFFERS}`}>
            <TopOffers />
          </Route>
          <Route path={`/defi/loan/${MYLIQUIDITY}`}>
            {readOnly ? null : <MyLiquidity />}
          </Route>
          <Route path={`/defi/loan/${MYLOANS}`}>
            {readOnly ? null : <MyLoans />}
          </Route>
          <Route path={`/defi/loan/${RATING}`}>
            <Rating />
          </Route>
          <Route path={`/defi/loan/${INSTRUCTIONS}`}>
            <Instructions />
          </Route>
          <Route path="/defi/loan">
            <Redirect to={`/defi/loan/${STATISTICS}`} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default observer(TheWallLoan);
