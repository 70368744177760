import React, { useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  PropTypes
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import AppStateContext, { MetamaskState } from '../../Contexts/AppStateContext';
import { NETWORK_VERSION } from '../../Stores/WallStore';
import { switchChain } from '../../Utils/switchChain';
import iconMetamask from '../../Resources/Icons/icon_metamask.svg';
import { decimalToHexadecimal } from '../../Helpers/helpers';
import s from './Dialogs.scss';

const AddChainBtn = ({
  id,
  icon,
  color,
  text
}: {
  id: string;
  icon: string;
  color: PropTypes.Color;
  text: string;
}) => {
  const { pluginState } = useContext(AppStateContext);
  const handleClick = () => {
    if (pluginState !== MetamaskState.NotInstalled) {
      switchChain(id);
    }
  };
  return (
    <>
      <Box>
        <Button
          className={s.addNetworkBtn}
          variant="outlined"
          startIcon={<img src={icon} alt="" className={s.iconBtn} />}
          onClick={handleClick}
          color={color}
        >
          <span className={s.btnText}>{text}</span>
        </Button>
      </Box>
    </>
  );
};

const NetworkVersionErrorDialog = () => {
  const { t } = useTranslation();
  const { wallStore } = useStores();

  const handleErrorDialog = (value: boolean) => () => {
    wallStore.setNetworkVersionError(value);
  };

  return (
    <Dialog
      open={wallStore.isNetworkVersionError}
      onClose={handleErrorDialog(false)}
    >
      <DialogTitle>{t('metamask_network_error')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('network_version_error_message')}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={s.buttons}>
        <AddChainBtn
          id={decimalToHexadecimal(NETWORK_VERSION)}
          icon={iconMetamask}
          color="primary"
          text={t('add_polygon_network')}
        />
        <Button
          onClick={handleErrorDialog(false)}
          color="primary"
          variant="contained"
          disableElevation
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(NetworkVersionErrorDialog);
