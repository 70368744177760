import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';

import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import gettingStartedImg from '../../../Resources/Instructions/getting_started_daily.png';
import viewStatisticsImg from '../../../Resources/Instructions/view_statistics.png';
import viewingAvailable_1 from '../../../Resources/Instructions/viewing_available_1.png';
import viewingAvailable_1_L from '../../../Resources/Instructions/viewing_available_1_L.png';
import viewingAvailable_2 from '../../../Resources/Instructions/viewing_available_2.png';
import viewingAvailable_2_L from '../../../Resources/Instructions/viewing_available_2_L.png';
import viewingAvailable_3 from '../../../Resources/Instructions/viewing_available_3.png';
import viewingAvailable_3_L from '../../../Resources/Instructions/viewing_available_3_L.png';
import viewingAvailable_4 from '../../../Resources/Instructions/viewing_available_4.png';
import viewingAvailable_4_L from '../../../Resources/Instructions/viewing_available_4_L.png';
import viewingManaging_1 from '../../../Resources/Instructions/viewing_managing_1.png';
import viewingManaging_1_L from '../../../Resources/Instructions/viewing_managing_1_L.png';
import viewingManaging_2 from '../../../Resources/Instructions/viewing_managing_2.png';
import viewingManaging_2_L from '../../../Resources/Instructions/viewing_managing_2_L.png';
import viewingManaging_3 from '../../../Resources/Instructions/viewing_managing_3.png';
import viewingManaging_3_L from '../../../Resources/Instructions/viewing_managing_3_L.png';
import viewingManaging_4 from '../../../Resources/Instructions/viewing_managing_4.png';
import viewingManaging_4_L from '../../../Resources/Instructions/viewing_managing_4_L.png';
import s from '../Instructions.scss';

const DefiDaily = () => {
  const { t } = useTranslation();
  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('about_the_wall_daily_defi_protocol')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_this_guide_is_about')}
            text={t('what_is_this_guide_daily')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_defi')}
            text={t('what_is_defi_content')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_the_wall_daily')}
            text={t('what_is_the_wall_daily_text')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={s.elementorSection}>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('what_is_needed')}</Typography>
          <ol>
            <li>
              <Typography variant="body1">{t('what_is_needed_1')}</Typography>
            </li>
            <li>
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{ __html: t('what_is_needed_2') }}
                />
              </Typography>
            </li>
            <li>
              <Typography variant="body1">{t('what_is_needed_3')}</Typography>
            </li>
            <li>
              <Typography variant="body1">{t('areas_of_the_wall')}</Typography>
            </li>
          </ol>
        </Grid>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('how_to_start')}</Typography>
          <Typography variant="body1" className={s.infobox2}>
            <span
              dangerouslySetInnerHTML={{ __html: t('how_to_start_daily_1') }}
            />
          </Typography>
          <Typography variant="body1">{t('how_to_start_daily_2')}</Typography>
        </Grid>
        <Grid item md={4} xs={12} className={s.backgroundColor2}>
          <Infobox
            icon="faLightBulb"
            title={t('good_to_know')}
            text={t('good_to_know_daily')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">
          {t('who_needs_the_wall_daily_protocol')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography variant="h4">{t('owners_of_wall_areas')}</Typography>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t('those_who_are_ready_to_contribute_their')
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="h4">{t('content_providers')}</Typography>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t('those_who_do_not_want_to_purchase_areas')
              }}
            />
          </Grid>
        </Grid>
      </section>
      <section>
        <Typography variant="h3">
          {t('getting_started_with_the_wall_daily')}
        </Typography>
        <Typography variant="body1">
          {t('to_start_working_with_the_wall_daily_protocol')}
        </Typography>
        <div className={s.image}>
          <FancyBoxImage
            image={gettingStartedImg}
            thumbnail={gettingStartedImg}
          />
        </div>
        <Typography variant="body1">
          {t('the_permit_needs_to_be_issued_only_once')}
        </Typography>
      </section>
      <section>
        <Typography variant="h3">
          {t('view_statistics_of_the_wall_daily_protocol')}
        </Typography>
        <Typography variant="body1">
          {t('to_view_the_statistics_of_the_wall_daily_protocol')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Typography variant="h4">
              {t('daily_total_areas_in_pull')}
            </Typography>
            <Typography variant="body1">
              {t('the_total_number_of_area')}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h4">{t('total_withdrawn_income')}</Typography>
            <Typography variant="body1">
              {t('the_total_volume_of_earnings_withdrawn')}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h4">{t('total_funds_in_the_pool')}</Typography>
            <Typography variant="body1">
              {t('the_amount_of_unwithdrawn')}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h4">{t('my_areas_in_the_pool')}</Typography>
            <Typography variant="body1">
              {t('the_number_of_your_areas')}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h4">{t('my_withdrawn_income')}</Typography>
            <Typography variant="body1">
              {t('the_volume_of_earnings_that_you_personally')}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h4">{t('my_funds_in_the_pool')}</Typography>
            <Typography variant="body1">
              {t('the_amount_of_your_unwithdrawn')}
            </Typography>
          </Grid>
        </Grid>
        <div className={s.image}>
          <img src={viewStatisticsImg} alt="" />
        </div>
      </section>
      <section>
        <Typography variant="h3">
          {t('viewing_available_areas_and_renting_them')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Infobox
              num={1}
              text={t('to_view_the_available_areas_and_rent_them')}
            />
            <FancyBoxImage
              image={viewingAvailable_1_L}
              thumbnail={viewingAvailable_1}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              num={2}
              text={t('you_can_sort_and_look_for_areas_on_the_basis')}
            />
            <FancyBoxImage
              image={viewingAvailable_2_L}
              thumbnail={viewingAvailable_2}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              num={3}
              text={t('choose_an_area_you_like_and_click_on_it')}
            />
            <FancyBoxImage
              image={viewingAvailable_3_L}
              thumbnail={viewingAvailable_3}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              num={4}
              text={t('after_you_confirm_the_transaction_in_metamask')}
            />
            <FancyBoxImage
              image={viewingAvailable_4_L}
              thumbnail={viewingAvailable_4}
            />
          </Grid>
        </Grid>
      </section>
      <section>
        <Typography variant="h3">
          {t('viewing_and_managing_your_liquidity')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Infobox
              num={1}
              text={t('to_view_the_liquidity_offers_you_have_posted')}
            />
            <FancyBoxImage
              image={viewingManaging_1_L}
              thumbnail={viewingManaging_1}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              num={2}
              text={t('to_place_liquidity_to_make_a_rent_offer')}
            />
            <FancyBoxImage
              image={viewingManaging_2_L}
              thumbnail={viewingManaging_2}
            />
            <Infobox text={t('it_will_cost_you')} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              num={3}
              text={t('to_cancel_an_offer_in_the_available_status')}
            />
            <FancyBoxImage
              image={viewingManaging_3_L}
              thumbnail={viewingManaging_3}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox num={4} text={t('to_withdraw_income_earned_from')} />
            <FancyBoxImage
              image={viewingManaging_4_L}
              thumbnail={viewingManaging_4}
            />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default DefiDaily;
