/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  withStyles,
  Checkbox,
  ListItemText,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import TopOffersItem from './TopOrrersItem/TopOffersItem';
import { RemoteDataState } from '../../../../Utils/RemoteData';
import { oc } from 'ts-optchain';
import { useStores } from '../../../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import ApprovedInfoPanel from '../../ApprovedInfoPanel';
import theWallLoanImg from '../../../../Resources/Defi/TheWallLoan.svg';
import DescriptionPanel from '../../../../Components/Defi/DescriptionPanel/DescriptionPanel';
import s from './TopOffers.scss';
import sDefi from '../../Defi.scss';

const styles = () => ({
  label: {
    transform: 'translate(14px, 13px) scale(1)'
  }
});

interface TopOffersProps {
  classes: { label: string };
}
const TopOffers: React.FC<TopOffersProps> = ({ classes }) => {
  const { t } = useTranslation();
  const [liquidityZone, setLiquidityZone] = useState(['all']);
  const { wallStore, tgLoanStore, tgWallStore } = useStores();
  const [timer, setTimer] = useState<NodeJS.Timeout>(setTimeout(() => null, 0));
  const [requestParams, setRequestParams] = useState({
    orderBy: 'rate',
    status: 'Available' as StatusTgLoanOffer
  });

  const offers = oc(tgLoanStore).offers.value([]);

  const userAreasIds = oc(tgWallStore).user.value.user.areas([]);
  const userAreas = oc(tgWallStore)
    .areas.value.areas([])
    .filter(
      c =>
        userAreasIds.includes(c.id) &&
        c.item.status !== 'Rented' &&
        c.cluster?.id === null
    );

  const disableTransactions =
    !wallStore.currentdAddress || !wallStore.networkVersionTrue;

  useEffect(() => {
    clearTimeout(timer);
    tgLoanStore.getOffers(requestParams);
  }, [requestParams]);

  useEffect(() => {
    if (
      tgLoanStore.offers.state === RemoteDataState.SUCCESS ||
      tgLoanStore.offers.state === RemoteDataState.FAILURE
    ) {
      setTimer(setTimeout(() => tgLoanStore.getOffers(requestParams), 60000));
    }
  }, [tgLoanStore.offers.state]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string[];
    if (value.includes('selectAll')) {
      setLiquidityZone(['all']);
    } else {
      setLiquidityZone(value);
    }
  };

  const getRenderData = (): LoanTgOffersType[] => {
    let result = offers.filter(
      i =>
        i.lender !== wallStore.currentdAddress &&
        Number(i.duration) / 86400 >= 1
    );
    if (liquidityZone.length > 1) {
      const selectAreas = userAreas.filter(c => liquidityZone.includes(c.id));
      const selectOffers = offers.filter(i =>
        selectAreas.find(
          area =>
            +area.x >= Number(i.x1) &&
            +area.x <= Number(i.x2) &&
            +area.y >= Number(i.y1) &&
            +area.y <= Number(i.y2)
        )
      );
      result = selectOffers;
    }

    return result.slice(0, 9);
  };

  const renderData = getRenderData();

  return (
    <>
      <div className={s.main}>
        <div className={s.left}>
          <div className={s.topBar}>
            <div className={s.bastBtnGroup}>
              <ButtonGroup>
                <Button
                  onClick={() =>
                    setRequestParams({ ...requestParams, orderBy: 'loan' })
                  }
                  variant={
                    requestParams.orderBy === 'loan' ? 'contained' : 'outlined'
                  }
                  color={
                    requestParams.orderBy === 'loan' ? 'primary' : 'default'
                  }
                >
                  {isMobile ? t('top_amount') : t('best_amount')}
                </Button>
                <Button
                  onClick={() =>
                    setRequestParams({ ...requestParams, orderBy: 'rate' })
                  }
                  variant={
                    requestParams.orderBy === 'rate' ? 'contained' : 'outlined'
                  }
                  color={
                    requestParams.orderBy === 'rate' ? 'primary' : 'default'
                  }
                >
                  {isMobile ? t('top_rate') : t('best_interest_rate')}
                </Button>
              </ButtonGroup>
            </div>
            <div className={s.liquidityZoneSelect}>
              <FormControl variant="outlined" className={s.formControl}>
                <InputLabel
                  id="select-liquidity-zone-label"
                  classes={{ outlined: classes.label }}
                >
                  {t('liquidity_zone')}
                </InputLabel>
                <Select
                  labelId="select-liquidity-zone-label"
                  id="select-liquidity-zone"
                  multiple
                  className={s.selectLiquidity}
                  label={t('liquidity_zone')}
                  value={liquidityZone}
                  renderValue={s => {
                    const selected = s as string[];
                    if (selected.includes('all') && selected.length === 1)
                      return t('all');
                    const result = userAreas.filter(c =>
                      selected.includes(c.id)
                    );
                    return result.map(i => `${t('area_at')} (${i.x},${i.y})`);
                  }}
                  onChange={handleChange}
                >
                  <MenuItem value="selectAll">
                    <Checkbox
                      color="primary"
                      checked={
                        liquidityZone.includes('all') &&
                        liquidityZone.length === 1
                      }
                    />
                    <ListItemText primary={t('all')} />
                  </MenuItem>
                  {userAreas.map(i => (
                    <MenuItem key={i.id} value={i.id}>
                      <Checkbox
                        color="primary"
                        checked={!!liquidityZone.find(j => j === i.id)}
                      />
                      <ListItemText
                        primary={`${t('area_at')} (${i.x},${i.y})`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={s.tableContent}>
            {renderData.length ? (
              renderData.map(i => (
                <TopOffersItem
                  offer={i}
                  userAreas={userAreas}
                  disabled={disableTransactions}
                  key={i.id}
                />
              ))
            ) : (
              <div className={sDefi.empty}>
                <Typography variant="h6">
                  {t('no_offers_for_you_found')}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className={sDefi.rightPageContent}>
          <DescriptionPanel content={t('thewall_loan_TopOffers_description')} />
        </div>
      </div>
      <ApprovedInfoPanel
        contractAddress={wallStore.loanContractAddress}
        logoImg={theWallLoanImg}
        text="thewall_loan_protocol_requires_permission"
      />
    </>
  );
};

export default withStyles(styles)(observer(TopOffers));
