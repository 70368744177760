import Konva from 'konva';
import { oc } from 'ts-optchain';
import { renderImage } from './renderImage';
import { SIZE_AREA } from '../TheWall';
import { getScaleByZoom } from '../../../Utils/getScaleByZoom';

import { MACROBLOCK_GROUP_ID } from './renderScene';
import backgroundBlock from '../../../Resources/backgroundBlock.png';
import { debounce } from 'lodash';

// checks if there are areas within the accepted coordinates
const getImageByCoordinates = (
  visibleAreas: AreaTgType[],
  left: number,
  top: number,
  size: number
) => {
  return visibleAreas.find(
    i => +i.x >= left && +i.x < left + size && +i.y <= top && +i.y > top - size
  );
};

const callRenderImg = (
  block: MacroblockType,
  group: Konva.Group,
  sizeArea: number,
  areas: AreaTgType[]
) => {
  const idBlock = `block_${block.scale}_${block.left}_${block.top}`;
  let image: Konva.Image = group.findOne(`#${idBlock}`);
  if (image && image.attrs.path === block.imagePath) {
    return;
  }
  renderImage(
    group,
    { path: block.imagePath },
    0,
    block.left * sizeArea,
    (block.top + 1) * sizeArea,
    block.size * sizeArea,
    block.size * sizeArea,
    {
      id: idBlock,
      path: block.imagePath
    },
    false
  ).then(() => {
    // added background to overlap lower layers
    if (areas && areas.length) {
      const areasLeft = getImageByCoordinates(
        areas,
        block.left - block.size,
        block.top,
        block.size
      );
      const areasRight = getImageByCoordinates(
        areas,
        block.left + block.size,
        block.top,
        block.size
      );
      const areasTop = getImageByCoordinates(
        areas,
        block.left,
        block.top + block.size,
        block.size
      );
      const areasBottom = getImageByCoordinates(
        areas,
        block.left,
        block.top - block.size,
        block.size
      );

      // adjustments to hide blurry layers underneath
      const shiftX = areasLeft ? 0 : block.size * sizeArea;
      const addWidth =
        block.size * sizeArea * ((areasLeft ? 0 : 1) + (areasRight ? 0 : 1));
      const shiftY = areasTop ? 0 : block.size * sizeArea;
      const addHeihgt =
        block.size * sizeArea * ((areasTop ? 0 : 1) + (areasBottom ? 0 : 1));

      const rectPath = backgroundBlock;
      Konva.Image.fromURL(rectPath, (node: Konva.Image) => {
        node.setAttrs({
          x: block.left * sizeArea - shiftX,
          y: (block.top + 1) * sizeArea + shiftY,
          width: block.size * sizeArea + addWidth,
          height: block.size * sizeArea + addHeihgt,
          id: rectPath,
          scaleY: -1
        });

        group.add(node);
        node.moveToBottom();
      });
    }
  });
};

const showOnlyCurrentLayer = (layer: Konva.Layer, scale: number) => {
  const groups = layer.getChildren();
  if (!groups.length) return;
  const maxIndex = groups.reduce(
    (acc, cur) => (cur.index > acc ? cur.index : acc),
    0
  );
  const groupToTop = groups.find(c => c.id() === `macroblock_group_${scale}`);

  if (groupToTop) groupToTop.zIndex(maxIndex);
};

const _renderMacroblocks = (
  visibleData: VisibleAreas,
  macroblocks: MacroblockType[],
  group: Konva.Group,
  areas: AreaTgType[]
) => {
  const visibleMackroblocks = macroblocks.filter(
    i =>
      i.right >= visibleData.x &&
      i.left <= visibleData.x + visibleData.width &&
      i.top >= visibleData.y &&
      i.bottom <= visibleData.y + visibleData.height
  );
  visibleMackroblocks.forEach(block => {
    callRenderImg(block, group, SIZE_AREA, areas);
  });
};

const renderMacroblocks = debounce(_renderMacroblocks, 1300);

export const renderSectors = (
  stage: Konva.Stage,
  macroblocks: MacroblockScaleType[],
  visibleData: VisibleAreas,
  areas: AreaTgType[]
) => {
  const zoomLevel = stage.scaleX();
  const scaleList = macroblocks.map(i => i.scale);
  const scale = getScaleByZoom(zoomLevel, scaleList);
  const layer: Konva.Layer = stage.findOne(`#macroblocksLayer`);
  const groupId = `${MACROBLOCK_GROUP_ID}_${scale}`;

  let group: Konva.Group = stage.findOne(`#${groupId}`);

  if (!layer || !scale) return;
  layer.moveToBottom();
  showOnlyCurrentLayer(layer, scale);
  layer.draw();

  if (macroblocks && macroblocks.length) {
    const dataMacroblocks = macroblocks.find(
      (i: MacroblockScaleType) => i.scale === scale
    );
    const renderData = oc(dataMacroblocks).data([]);

    renderMacroblocks(visibleData, renderData, group, areas);
  }
};

export const renderAllByScale = (
  stage: Konva.Stage,
  macroblocks: MacroblockScaleType[],
  visibleData: VisibleAreas,
  areas: AreaTgType[],
  scale: number
) => {
  const layer: Konva.Layer = stage.findOne(`#macroblocksLayer`);
  const groupId = `${MACROBLOCK_GROUP_ID}_${scale}`;

  let group: Konva.Group = stage.findOne(`#${groupId}`);
  if (oc(group.children)([]).length) return;

  if (!layer) return;
  layer.moveToBottom();
  layer.draw();

  if (macroblocks && macroblocks.length) {
    const dataMacroblocks = macroblocks.find(
      (i: MacroblockScaleType) => i.scale === scale
    );
    const renderData = oc(dataMacroblocks).data([]);

    _renderMacroblocks(visibleData, renderData, group, areas);
  }
};
