import { isMobile } from 'react-device-detect';
import { networkConfig } from '../networkConfig';

export const switchChain = async (id: string) => {
  const dataChain = Object.values(networkConfig)
    .reduce((p: ChainDataType[], c: ChainDataType[]) => {
      return [...p, ...c];
    })
    .find(i => i.id === id);

  if (!dataChain || !window.ethereum) {
    return;
  }

  const addChain = async () => {
    return await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: dataChain.id,
          chainName: dataChain.name,
          nativeCurrency: {
            symbol: dataChain.currency,
            decimals: dataChain.decimals
          },
          rpcUrls: dataChain.rpcUrls,
          blockExplorerUrls: dataChain.blockExplorerUrls
        }
      ]
    });
  };

  if (isMobile) {
    try {
      return addChain();
    } catch (addError) {
      console.log('Error', addError);
    }
    return;
  }

  try {
    return await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: dataChain.id }]
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if ((switchError as { code: number })?.code === 4902) {
      try {
        return addChain();
      } catch (addError) {
        console.log('Error', addError);
      }
    }
    console.log('Error', switchError);
  }
};
