export const getPreview = (id: string) => {
  const URI = '/api/renderer';
  const myHeaders = new Headers();
  return fetch(`${URI}/preview/${id}`, {
    method: 'GET',
    headers: myHeaders
  })
    .then(res => res.json())
    .then(response => {
      const { result } = response;
      if (Array.isArray(result)) {
        const data = result.find((i: PreviewType) => i.preset === 'api');
        return data.path;
      }
    })
    .catch(e => {
      console.log('Error ', e);
    });
};
