import React, { useState } from 'react';
import cx from 'classnames';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Link
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import arrowIcon from '../../Resources/Icons/arrow_down.svg';
import { useParams, useHistory } from 'react-router-dom';
import { getDeadlineTimeT } from '../../Utils/defiUtils';
import NearestPopup from '../../Containers/Defi/TheWallTgDaily/Areas/NearestPopup/NearestPopup';
import s from './AreasList.scss';

const URI_IPFS = window.config.URI_IPFS;

type sectionType = 'reserve' | 'nearest';

interface ExpansionBlockProps {
  title: string;
  areas: AreaTgType[];
  isExpanded?: boolean;
  section: sectionType;
}

interface AreaItemProps extends AreaTgType {
  section: sectionType;
  active?: boolean;
  dailyData?: DailyAreaType;
}

const AreaItem: React.FC<AreaItemProps> = ({
  id,
  item,
  x,
  y,
  active,
  imageCID,
  dailyData,
  section
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    setTimeout(() => {
      history.push('/defi/daily/areas/' + id);
    }, 1);
    history.push('/defi/daily/areas/');
  };

  if (!dailyData) return null;

  const dedlineTimeString = getDeadlineTimeT(dailyData.deadline);

  return (
    <div
      className={cx(s.item, s.itemDaily, active && s.active)}
      onClick={handleClick}
    >
      {imageCID.length > 0 ? (
        <img
          src={`${URI_IPFS}/${imageCID[0]}`}
          className={s.image}
          alt={item.title}
        />
      ) : (
        <div className={s.empty} />
      )}
      <Typography>{t('area_title', { x, y })}</Typography>
      <Link
        href={`https://polygonscan.com/address/${dailyData.provider}`}
        variant="body1"
        target="_blank"
      >
        {!!dailyData.provider &&
          `${dailyData.provider.slice(0, 6)}...${dailyData.provider.slice(-4)}`}
      </Link>
      <Typography variant="body1">
        {section === 'reserve' && dedlineTimeString}
      </Typography>
    </div>
  );
};

const AreasListDaily: React.FC<ExpansionBlockProps> = ({
  title,
  areas,
  isExpanded,
  section
}) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={(e, expanded) => setExpanded(expanded)}
      elevation={0}
      square
    >
      <ExpansionPanelSummary classes={{ content: s.summaryDaily }}>
        <Typography variant="h6" className={s.titleUp}>
          {title}
        </Typography>
        <div className={s.expand}>
          <Typography color="textSecondary" className={s.hide}>
            {expanded ? t('hide_areas') : t('show_areas')}
          </Typography>
          <img
            src={arrowIcon}
            alt="Expand"
            className={cx(s.arrow, expanded && s.down)}
          />
        </div>
      </ExpansionPanelSummary>
      {section === 'reserve' ? null : <NearestPopup />}
      <ExpansionPanelDetails>
        <div className={cx(s.items, s.itemsDaily)}>
          <div className={cx(s.item, s.itemDaily, s.boldType)}>
            <Typography variant="body2">{t('area')}</Typography>
            <div />
            <Typography variant="body2">{t('owner')}</Typography>
            {section === 'reserve' ? (
              <Typography variant="body2">{t('reserved_till')}</Typography>
            ) : null}
          </div>
          {areas.map(area => (
            <AreaItem
              {...area}
              key={area.id}
              active={area.id === id}
              section={section}
            />
          ))}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default AreasListDaily;
