import React, { useRef, useState } from 'react';
import Popper from '@material-ui/core/Popper';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useStores } from '../../../../../Hooks/useStores';
import s from './NearestPopup.scss';

const STORAGE_AREA_FOR_DISTANCE_REQUEST = 'areaForDistanceRequest';
export const getAreaForDistance = () => {
  const values = JSON.parse(
    sessionStorage.getItem(STORAGE_AREA_FOR_DISTANCE_REQUEST) as string
  );
  if (!values) return { x: 0, y: 0 };
  const x = Number(values.x) || 0;
  const y = Number(values.y) || 0;
  return { x, y };
};

interface NearestPopupProps {}

const NearestPopup: React.FC<NearestPopupProps> = ({}) => {
  const { t } = useTranslation();
  const { tgDailyStore } = useStores();
  const [area, setArea] = useState(getAreaForDistance());
  const getInitialValue = () => {
    const initialValues = {
      x: area.x,
      y: area.y
    };
    return initialValues;
  };

  const getDailyAreasDistance = (x: number, y: number) => {
    tgDailyStore.getAreasDistance(Math.round(Date.now() / 1000), x, y);
  };

  const containerRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'distance-popper' : undefined;

  const XYInput = ({
    name,
    placeholder
  }: {
    name: string;
    placeholder: string;
  }) => (
    <TextField
      name={name}
      color="secondary"
      disabled={false}
      type="number"
      className={s.xyInputField}
      placeholder={placeholder}
    />
  );

  const onSubmit = (values: any) => {
    const x = Number(values.x) || 0;
    const y = Number(values.y) || 0;
    getDailyAreasDistance(x, y);
    setArea({ x, y });
    sessionStorage.setItem(
      STORAGE_AREA_FOR_DISTANCE_REQUEST,
      JSON.stringify({ x, y })
    );
    setAnchorEl(null);
  };

  return (
    <div ref={containerRef} className={s.popperContainer}>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        aria-describedby={id}
      >
        {t('nearest_to', { x: area.x, y: area.y })}
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className=""
        placement="bottom-start"
        container={containerRef.current}
      >
        <div className={s.main}>
          <Formik onSubmit={onSubmit} initialValues={getInitialValue()}>
            {({ submitForm }) => (
              <div className={s.innerContainer}>
                <div className={s.inputs}>
                  <XYInput name="x" placeholder="" />
                  <XYInput name="y" placeholder="" />
                </div>
                <div className={s.buttons}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClick}
                  >
                    {t('close')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    {t('save')}
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </Popper>
    </div>
  );
};

export default NearestPopup;
