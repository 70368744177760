declare interface ImageData {
  x?: number | undefined;
  y?: number | undefined;
  id: string;
  width?: number | undefined;
  height?: number | undefined;
  RGB?: any;
  images?: any[] | undefined;
  data: Blob;
  btih?: string | undefined;
}

declare interface ContentData {
  title?: string;
  link?: string;
  tags?: string;
}

export const multiRequests = async (
  images: ImageData[],
  contentData: ContentData,
  idClusters: any,
  setContentMulti: any
) => {
  const contents: string[] = [];
  const tokens: string[] = [];

  const checkResultUpload = new Promise(resolve => {
    const dataLength = images ? images.length : 0;
    const checkResult = () => {
      setTimeout(() => {
        if (dataLength === contents.length) {
          resolve(true);
        } else {
          checkResult();
        }
      }, 200);
    };
    checkResult();
  });

  const getClusterContents = () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    fetch('/api/upload', {
      method: 'POST',
      body: JSON.stringify(contentData),
      headers: myHeaders
    })
      .then(res => res.text())
      .then(response => {
        contents.push(response.replace(/^f/i, '0x02').replace('\n', ''));
        tokens.push(idClusters);
        setContentMulti(tokens, contents);
      })
      .catch(error => console.log('error', error));
  };

  const getAreasContents = (images: ImageData[]) => {
    images.forEach(i => {
      if (i.images && i.btih) {
        i.images[0] = i.btih;
      }

      if (i.images && i.btih === '') {
        i.images = [];
      }

      const pixel = i.RGB ? i.RGB.replace('#', '') : null;

      const contentData = {
        images: i.images,
        pixel
      };

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      fetch('/api/upload', {
        method: 'POST',
        body: JSON.stringify(contentData),
        headers: myHeaders
      })
        .then(res => res.text())
        .then(response => {
          contents.push(response.replace(/^f/i, '0x02').replace('\n', ''));
          tokens.push(i.id);
        })
        .catch(error => console.log('error', error));
    });
  };

  getAreasContents(images);
  await checkResultUpload;
  getClusterContents();
};
