import React, { useRef } from 'react';
import cx from 'classnames';
import { ButtonBase, Typography, Popper, Link } from '@material-ui/core';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindPopper } from 'material-ui-popup-state';
import useClipboard from 'react-use-clipboard';
import s from '../../MyPlaces.scss';
import { PopupState } from 'material-ui-popup-state/core';
import { NavLink as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChangeOwnerPopup from '../ChangeOwnerPopup';
import {
  RemoveClusterErrorDialog,
  RentErrorDialog,
  RentSuccessDialog,
  SellErrorDialog,
  SellSuccessDialog,
  TransferErrorDialog,
  TransferSuccessDialog,
  RemoveClusterSuccessDialog,
  AddToClusterSuccessDialog,
  AddToClusterErrorDialog
} from '../../../Dialogs/Dialogs';
import { oc } from 'ts-optchain';
import { useStores } from '../../../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import SellPopup from '../SellPopup';
import RentPopup from '../RentPopup';
import SelectClusterDialog from '../SelectClusterDialog';

export interface ActionsPopupBaseProps {
  popupState: PopupState;
  areaId?: string;
}

interface ActionsPopupProps extends ActionsPopupBaseProps {
  clusterId?: string;
  disabled?: boolean;
  option?: string;
  toSetting?: string;
}

const ActionsPopup: React.FC<ActionsPopupProps> = ({
  popupState,
  areaId,
  clusterId,
  disabled,
  option,
  toSetting
}) => {
  const { t } = useTranslation();
  const { wallStore, tgWallStore } = useStores();
  const containerRef = useRef(null);
  const changeOwnerState = usePopupState({
    variant: 'popover',
    popupId: 'changeOwner'
  });
  const sellState = usePopupState({
    variant: 'popover',
    popupId: 'sell'
  });
  const rentState = usePopupState({
    variant: 'popover',
    popupId: 'rent'
  });

  const handleChangeOwner = () => {
    changeOwnerState.open(popupState.anchorEl);
    popupState.close();
  };

  const handleSell = () => {
    sellState.open(popupState.anchorEl);
    popupState.close();
  };

  const handleRent = () => {
    rentState.open(popupState.anchorEl);
    popupState.close();
  };

  const [, setCopied] = useClipboard(
    `${window.location.origin}/?${areaId ? 'areaId' : 'clusterId'}=${areaId ||
      clusterId}&invite=${oc(tgWallStore).user.value.user.id('')}`
  );

  const copyRefLink = async () => {
    setCopied();
    popupState.close();
  };

  const handleRemoveCluster = () => {
    wallStore.removeClusterById(clusterId || '');
  };

  return (
    <div
      ref={containerRef}
      className={cx(s.popperContainer, option && s[option])}
    >
      <Popper
        {...bindPopper(popupState)}
        container={containerRef.current}
        placement={toSetting ? 'bottom-end' : 'bottom'}
      >
        <div className={s.popupItems}>
          {!disabled && (
            <ButtonBase className={s.black} onClick={handleRent}>
              <Typography color="secondary">{t('rent')}</Typography>
            </ButtonBase>
          )}
          {!disabled && (
            <ButtonBase
              className={cx(s.blue, toSetting || s.extended)}
              onClick={handleSell}
            >
              <Typography color="secondary">{t('sell')}</Typography>
            </ButtonBase>
          )}
          {toSetting && (
            <Link
              to={toSetting}
              underline="none"
              variant="body1"
              color="secondary"
              className={cx(s.vanilla)}
              component={RouterLink}
            >
              {t('settings')}
            </Link>
          )}
          {!disabled && (
            <ButtonBase className={s.blue} onClick={handleChangeOwner}>
              <Typography color="secondary">{t('change_owner')}</Typography>
            </ButtonBase>
          )}
          {!disabled && clusterId && (
            <ButtonBase className={s.red} onClick={handleRemoveCluster}>
              <Typography color="secondary">{t('remove')}</Typography>
            </ButtonBase>
          )}
          {!disabled && areaId && <SelectClusterDialog areaId={areaId} />}
          <ButtonBase className={s.black} onClick={copyRefLink}>
            <Typography color="secondary">{t('copy_link')}</Typography>
          </ButtonBase>
        </div>
      </Popper>
      <ChangeOwnerPopup
        popupState={changeOwnerState}
        areaId={areaId || clusterId || ''}
      />
      <SellPopup popupState={sellState} areaId={areaId || clusterId || ''} />
      <RentPopup popupState={rentState} areaId={areaId || clusterId || ''} />
      <TransferSuccessDialog />
      <TransferErrorDialog />
      <SellErrorDialog />
      <SellSuccessDialog />
      <RentErrorDialog />
      <RentSuccessDialog />
      <RemoveClusterErrorDialog />
      <RemoveClusterSuccessDialog />
      <AddToClusterSuccessDialog />
      <AddToClusterErrorDialog />
    </div>
  );
};

export default observer(ActionsPopup);
