import React, { useContext } from 'react';
import s from './Header.scss';
import logo from '../../Resources/logo.svg';
import theWallLoanImg from '../../Resources/Defi/TheWallLoanLogoBlack.svg';
import theWallDailyImg from '../../Resources/Defi/TheWallDailyLogoBlack.svg';
import logoText from '../../Resources/logo_text.svg';
import logoTextLoan from '../../Resources/logo_text_loan.svg';
import logoTextDaily from '../../Resources/logo_text_daily.svg';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppStateContext from '../../Contexts/AppStateContext';
// import Diagnostics from '../../Components/Diagnostics/Diagnostics';
import MobileMenu from './MobileMenu';
import HeaderLink from './HeaderLink';
import BalanceInformer from './BalanceInformer/BalanceInformer';
import { Switch, Route } from 'react-router-dom';

const Header = () => {
  const { t } = useTranslation();
  const { readOnly } = useContext(AppStateContext);

  return (
    <>
      <header className={s.container}>
        <div className={s.headerMenu}>
          <div className={s.logoContainer}>
            <Switch>
              <Route path="/defi/loan">
                <img src={theWallLoanImg} alt="The Wall Loan" />
              </Route>
              <Route path="/defi/daily">
                <img src={theWallDailyImg} alt="The Wall Daily" />
              </Route>
              <Route path="/">
                <a href="https://about.thewall.global">
                  <img src={logo} alt="The Wall Global" />
                </a>
              </Route>
            </Switch>
          </div>
          <div className={s.inner}>
            <Switch>
              <Route path="/defi/loan">
                <img src={logoTextLoan} alt="The Wall Loan" />
              </Route>
              <Route path="/defi/daily">
                <img src={logoTextDaily} alt="The Wall Daily" />
              </Route>
              <Route path="/">
                <img src={logoText} alt="The Wall Global" />
              </Route>
            </Switch>
            <Box className={s.nav}>
              <HeaderLink title={t('wall')} to="/" exact />
              {!readOnly && (
                <>
                  <HeaderLink title={t('my_places')} to="/my" />
                </>
              )}
              <HeaderLink title={t('buy_places')} to="/marketplace" />
              <HeaderLink title={t('defi')} to="/defi" />
              <HeaderLink title={t('rating')} to="/rating" />
              <HeaderLink title={t('instructions')} to="/instructions" />
            </Box>
            <MobileMenu />
          </div>
        </div>
        <BalanceInformer desctop={true} />
      </header>
      {/* <Diagnostics /> */}
    </>
  );
};

export default Header;
