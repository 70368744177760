import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReaction } from '../../Hooks/useReaction';
import { RemoteDataState } from '../../Utils/RemoteData';
import { useObserver } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import WallStore from '../../Stores/WallStore';
import { stores } from '../../Stores/RootStore';

export const FeeErrorDialog = () => {
  return useObserver(() => {
    const { t } = useTranslation();
    const { wallStore } = useStores();
    const [isErrorDialog, setErrorDialog] = useState(false);

    const handleErrorDialog = (value: boolean) => () => {
      setErrorDialog(value);
      if (!value) {
        wallStore.clearCreate();
        wallStore.clearEdit();
      }
    };

    useReaction(
      () => wallStore.isCalculationError,
      state => {
        if (state) {
          setErrorDialog(true);
        }
      }
    );

    return (
      <Dialog open={isErrorDialog} onClose={handleErrorDialog(false)}>
        <DialogTitle>{t('transaction_error')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('calculate_fee_error')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleErrorDialog(false)}
            color="primary"
            variant="contained"
            disableElevation
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
};

export interface DialogSettings {
  onClean: (wallStore: WallStore) => void;
  reactionExpression: () => any;
  messageKey: string;
  titleKey: string;
}

const createDialog = ({
  onClean,
  reactionExpression,
  messageKey,
  titleKey
}: DialogSettings) => {
  return () =>
    useObserver(() => {
      const { t } = useTranslation();
      const { wallStore } = useStores();
      const [isDialogOpen, setDialogOpen] = useState(false);

      const handleSuccessDialog = (value: boolean) => () =>
        setDialogOpen(value);

      useEffect(() => {
        return () => {
          onClean(wallStore);
        };
      }, [wallStore]);

      useReaction(reactionExpression, state => {
        if (state) {
          setDialogOpen(true);
        }
      });

      return (
        <Dialog open={isDialogOpen} onClose={handleSuccessDialog(false)}>
          <DialogTitle>{t(titleKey)}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t(messageKey)}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSuccessDialog(false)}
              color="primary"
              variant="contained"
              disableElevation
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      );
    });
};

export const TransferSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearTransfer();
  },
  reactionExpression: () =>
    stores.wallStore.transfer.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_transfer',
  titleKey: 'transaction_success'
});
export const TransferErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearTransfer();
  },
  reactionExpression: () =>
    stores.wallStore.transfer.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_transfer',
  titleKey: 'transaction_error'
});

export const SellSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearSell();
  },
  reactionExpression: () =>
    stores.wallStore.sell.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const SellErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearSell();
  },
  reactionExpression: () =>
    stores.wallStore.sell.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const RentSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearRent();
  },
  reactionExpression: () =>
    stores.wallStore.rent.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const RentErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearRent();
  },
  reactionExpression: () =>
    stores.wallStore.rent.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const RemoveClusterSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearRemoveCluster();
  },
  reactionExpression: () =>
    stores.wallStore.removeCluster.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const RemoveClusterErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearRemoveCluster();
  },
  reactionExpression: () =>
    stores.wallStore.removeCluster.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const RemoveFromClusterSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearRemoveFromCluster();
  },
  reactionExpression: () =>
    stores.wallStore.removeFromCluster.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const RemoveFromClusterErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearRemoveFromCluster();
  },
  reactionExpression: () =>
    stores.wallStore.removeFromCluster.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const CancelSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearCancel();
  },
  reactionExpression: () =>
    stores.wallStore.cancel.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_cancel',
  titleKey: 'transaction_success'
});
export const CancelErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearCancel();
  },
  reactionExpression: () =>
    stores.wallStore.cancel.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const RentOutSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearTakeRent();
  },
  reactionExpression: () =>
    stores.wallStore.takeRent.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const RentOutErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearTakeRent();
  },
  reactionExpression: () =>
    stores.wallStore.takeRent.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const BuySuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearBuy();
  },
  reactionExpression: () =>
    stores.wallStore.buy.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const BuyErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearBuy();
  },
  reactionExpression: () =>
    stores.wallStore.buy.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const EditSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearEdit();
  },
  reactionExpression: () =>
    stores.wallStore.editAreaTitle.state === RemoteDataState.SUCCESS ||
    stores.wallStore.editAreaLinks.state === RemoteDataState.SUCCESS ||
    stores.wallStore.editAreaTags.state === RemoteDataState.SUCCESS ||
    stores.wallStore.editAreaImage.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_edit',
  titleKey: 'transaction_success'
});
export const EditErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearEdit();
  },
  reactionExpression: () =>
    (stores.wallStore.editAreaTitle.state === RemoteDataState.FAILURE ||
      stores.wallStore.editAreaLinks.state === RemoteDataState.FAILURE ||
      stores.wallStore.editAreaTags.state === RemoteDataState.FAILURE ||
      stores.wallStore.editAreaImage.state === RemoteDataState.FAILURE) &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_edit',
  titleKey: 'transaction_error'
});

export const CreateSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearCreate();
  },
  reactionExpression: () =>
    stores.wallStore.buyArea.state === RemoteDataState.SUCCESS ||
    stores.wallStore.buyAreaMulti.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const CreateErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearCreate();
  },
  reactionExpression: () =>
    (stores.wallStore.buyArea.state === RemoteDataState.FAILURE ||
      stores.wallStore.buyAreaMulti.state === RemoteDataState.FAILURE) &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_create',
  titleKey: 'transaction_error'
});

export const SetNicknameSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearSetNickname();
  },
  reactionExpression: () =>
    stores.wallStore.updateNickname.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const SetNicknameErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearSetNickname();
  },
  reactionExpression: () =>
    stores.wallStore.updateNickname.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const SetAvatarSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearSetAvatar();
  },
  reactionExpression: () =>
    stores.wallStore.updateAvatar.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const SetAvatarErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearSetAvatar();
  },
  reactionExpression: () =>
    stores.wallStore.updateAvatar.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});
export const SetContentSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearSetContent();
  },
  reactionExpression: () =>
    stores.wallStore.updateContent.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const SetContentErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearSetContent();
  },
  reactionExpression: () =>
    stores.wallStore.updateContent.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});
export const SetContentMultiSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearSetContentMulti();
  },
  reactionExpression: () =>
    stores.wallStore.updateContentMulti.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const SetContentMultiErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearSetContentMulti();
  },
  reactionExpression: () =>
    stores.wallStore.updateContentMulti.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});
export const AddToClusterSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearAddToCluster();
  },
  reactionExpression: () =>
    stores.wallStore.addToCluster.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const AddToClusterErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearAddToCluster();
  },
  reactionExpression: () =>
    stores.wallStore.addToCluster.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});
export const CreateClusterSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearCreateCluster();
  },
  reactionExpression: () =>
    stores.wallStore.createCluster.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const CreateClusterErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearCreateCluster();
  },
  reactionExpression: () =>
    stores.wallStore.createCluster.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});
export const CreateLoanOfferSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearCreateLoanOffer();
  },
  reactionExpression: () =>
    stores.wallStore.createLoanOffer.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const CreateLoanOfferErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearCreateLoanOffer();
  },
  reactionExpression: () =>
    stores.wallStore.createLoanOffer.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const DailyWithdrawProfitSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearDailyWithdrawProfit();
  },
  reactionExpression: () =>
    stores.wallStore.dailyWithdrawProfitRd.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const DailyWithdrawProfitErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearDailyWithdrawProfit();
  },
  reactionExpression: () =>
    stores.wallStore.dailyWithdrawProfitRd.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const DailyWithdrawSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearDailyWithdraw();
  },
  reactionExpression: () =>
    stores.wallStore.dailyWithdrawRd.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const DailyWithdrawErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearDailyWithdraw();
  },
  reactionExpression: () =>
    stores.wallStore.dailyWithdrawRd.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const DailyDepositSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearDailyDeposit();
  },
  reactionExpression: () =>
    stores.wallStore.dailyDepositRd.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const DailyDepositErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearDailyDeposit();
  },
  reactionExpression: () =>
    stores.wallStore.dailyDepositRd.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const DailySetContentSuccessDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearDailySetContent();
  },
  reactionExpression: () =>
    stores.wallStore.dailySetContentRd.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const DailySetContentErrorDialog = createDialog({
  onClean: wallStore => {
    wallStore.clearDailySetContent();
  },
  reactionExpression: () =>
    stores.wallStore.dailySetContentRd.state === RemoteDataState.FAILURE &&
    !stores.wallStore.isCalculationError,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});
