import React from 'react';

export default class ErrorBoundary extends React.Component {
  // @ts-ignore
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error) {
    console.log(error);
    this.setState({ hasError: true });
  }

  render() {
    return this.props.children;
  }
}
