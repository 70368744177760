/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../Hooks/useStores';
import getAddress from '../../../../Utils/getAddress';
import { RemoteDataState } from '../../../../Utils/RemoteData';
import { observer } from 'mobx-react-lite';
import { oc } from 'ts-optchain';
import MyLiquidityItem from './MyLiquidityItem/MyLiquidityItem';
import AddLiquidityDialog from './AddLiquidityDialog';
import DescriptionPanel from '../../../../Components/Defi/DescriptionPanel/DescriptionPanel';
import ApprovedInfoPanel from '../../ApprovedInfoPanel';
import theWallDailyImg from '../../../../Resources/Defi/TheWallDaily.svg';
import { UPDATE_INTERVAL } from '../TheWallTgDaily';
import s from './MyLiquidity.scss';
import sDefi from '../../Defi.scss';

const MyLiquidity = () => {
  const { t } = useTranslation();
  const { wallStore, tgDailyStore } = useStores();
  const disableTransactions =
    !wallStore.currentdAddress || !wallStore.networkVersionTrue;

  const dailyContractAddress = wallStore.dailyContractAddress;

  useEffect(() => {
    if (wallStore.theWallDailyContact) {
      wallStore.getDailyBalance();
    }
  }, [wallStore.theWallDailyContact]);

  useEffect(() => {
    if (wallStore.theWallDailyContact) {
      tgDailyStore.getAreasByAddress(getAddress());
    }
  }, [wallStore.theWallDailyContact, wallStore.currentdAddress]);

  useEffect(() => {
    if (
      tgDailyStore.areas.state === RemoteDataState.SUCCESS ||
      tgDailyStore.areas.state === RemoteDataState.FAILURE
    ) {
      setTimeout(
        () => tgDailyStore.getAreasByAddress(getAddress()),
        UPDATE_INTERVAL
      );
    }
  }, [tgDailyStore.areas.state]);

  useEffect(() => {
    if (
      dailyContractAddress &&
      !wallStore.defiApprovedAddress.includes(
        `${wallStore.currentdAddress}_${dailyContractAddress}`
      ) &&
      wallStore.appStarted
    ) {
      wallStore.getApprovedForAll(dailyContractAddress);
    }
  }, [wallStore.appStarted, wallStore.currentdAddress, dailyContractAddress]);

  const currentAddress = getAddress();
  const liquidityData = oc(tgDailyStore)
    .areas.value([])
    .filter(i => i.provider === currentAddress);

  if (wallStore.dailyTotalLPT === null || wallStore.dailyBalance === null)
    return <CircularProgress className={s.loader} size={24} />;

  return (
    <>
      <div className={s.main}>
        <div className={s.left}>
          <div className={s.topBar}>
            <AddLiquidityDialog />
          </div>
          <div className={s.tableContent}>
            {liquidityData.length ? (
              liquidityData.map(i => (
                <MyLiquidityItem
                  key={i.areaId}
                  area={i}
                  disabled={disableTransactions}
                />
              ))
            ) : (
              <div className={sDefi.empty}>
                <Typography variant="h6">
                  {t('your_liquidity_positions_will_appear_here')}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className={sDefi.rightPageContent}>
          <DescriptionPanel content={t('daily_my_liquidity_description')} />
        </div>
      </div>
      {tgDailyStore.configuration.state === RemoteDataState.SUCCESS && (
        <ApprovedInfoPanel
          contractAddress={dailyContractAddress}
          logoImg={theWallDailyImg}
          text="thewall_daily_protocol_requires_permission"
        />
      )}
    </>
  );
};

export default observer(MyLiquidity);
