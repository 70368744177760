/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import s from './Search.scss';
import {
  ButtonBase,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import searchIcon from '../../Resources/Icons/search.svg';
import tuneIcon from '../../Resources/Icons/tune.svg';
import SearchResult from './SearchResult/SearchResult';
import FilterSelect from '../../Components/FilterSelect/FilterSelect';
import FilterPrice, {
  FilterPriceValues
} from '../../Components/FilterPrice/FilterPrice';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import { RemoteDataState } from '../../Utils/RemoteData';
import { oc } from 'ts-optchain';
import { SIZE_AREA, DESKTOP } from '../TheWall/TheWall';
import closeIcon from '../../Resources/Icons/close.svg';
import { Autocomplete } from '@material-ui/lab';
import Web3 from 'web3';

interface SearchProps {
  onSearch: (areas: Nullable<AreaCoordinate[]>) => void;
  closeInfoModal: () => void;
}

const Search: React.FC<SearchProps> = ({ onSearch, closeInfoModal }) => {
  return useObserver(() => {
    const { t } = useTranslation();
    const { tgWallStore } = useStores();
    const [query, setQuery] = useState([]);
    const [queryAdd, setQueryAdd] = useState('');
    const [searchMode, setSearchMode] = useState(false);
    const [reset, setReset] = useState(false);
    const [type, setType] = useState<string[]>(['clusters', 'areas']);
    const [prices, setPrices] = useState<Nullable<FilterPriceValues>>(null);
    const [status, setStatus] = useState<string[]>([
      'selling',
      'rent',
      'published'
    ]);
    const [enterIsPressed, setEnterIsPressed] = useState(false);

    const tags = oc(tgWallStore)
      .tags.value.tags([])
      .map(c => c.id);

    const areaResult = oc(tgWallStore)
      .areas.value.areas([])
      .filter(c =>
        oc(tgWallStore)
          .searchItems.value([])
          .find(j => {
            return j.area?.id === c.id;
          })
      );

    const clusterResult = oc(tgWallStore)
      .clusters.value.clusters([])
      .filter(c =>
        oc(tgWallStore)
          .searchItems.value([])
          .find(j => j.cluster?.id === c.id)
      );

    const handleSearch = () => {
      closeInfoModal();
      setSearchMode(true);
      const tags: string[] = [...query];
      if (queryAdd) {
        tags.push(...queryAdd.trim().split(' '));
      }
      if (tags.length) {
        tgWallStore.getItemsBy({
          tags
        });
      }
    };

    const filterClusterByStatusAndPrice = (data: ClusterTgType[]) => {
      const statusCodes: StatusTgType[] = ['None', 'Rented'];

      if (status.includes('rent')) {
        statusCodes.push('ForRent');
      }
      if (status.includes('selling')) {
        statusCodes.push('ForSale');
      }

      return data
        .filter(c => {
          if (!c.item?.status) return false;
          return statusCodes.includes(c.item.status);
        })
        .filter(c =>
          prices && prices.to
            ? parseFloat(Web3.utils.fromWei(c.item?.cost || '')) <= prices.to
            : true
        )
        .filter(c =>
          prices && prices.from
            ? parseFloat(Web3.utils.fromWei(c.item?.cost || '')) >= prices.from
            : true
        );
    };

    const filterByStatusAndPrice = (data: AreaTgType[]) => {
      const statusCodes = [0, 3];

      if (status.includes('rent')) {
        statusCodes.push(2);
      }
      if (status.includes('selling')) {
        statusCodes.push(1);
      }

      return data
        .filter(c =>
          prices && prices.to
            ? parseFloat(Web3.utils.fromWei(c.item.cost || '')) <= prices.to
            : true
        )
        .filter(c =>
          prices && prices.from
            ? parseFloat(Web3.utils.fromWei(c.item.cost || '')) >= prices.from
            : true
        );
    };

    useEffect(() => {
      if (tgWallStore.searchItems.state === RemoteDataState.SUCCESS) {
        const result = [];

        if (type.includes('areas')) {
          result.push(
            ...filterByStatusAndPrice(areaResult).map(c => ({
              x: +c.x * SIZE_AREA,
              y: +c.y * SIZE_AREA,
              width: SIZE_AREA,
              height: SIZE_AREA,
              id: c.id
            }))
          );
        }
        if (type.includes('clusters')) {
          result.push(
            ...filterByStatusAndPrice(
              oc(tgWallStore).areas.value.areas([])
            ).map(c => ({
              x: +c.x * SIZE_AREA,
              y: +c.y * SIZE_AREA,
              width: SIZE_AREA,
              height: SIZE_AREA,
              id: c.id
            }))
          );
        }

        onSearch(result);
      }
    }, [
      areaResult,
      clusterResult,
      type,
      status,
      prices,
      filterByStatusAndPrice
    ]);

    useEffect(() => {
      if (enterIsPressed) {
        handleSearch();
        setEnterIsPressed(false);
      }
    }, [enterIsPressed]);

    const closeSearch = () => {
      setType(['clusters', 'areas']);
      setQuery([]);
      setQueryAdd('');
      setSearchMode(false);
      tgWallStore.clearSearch();
      onSearch(null);
      setReset(true);
    };

    const checkKeyInput = (e: any) => {
      if (e.keyCode === 13) {
        setEnterIsPressed(true);
      }
    };

    // Until the data on the areas is loaded, we hide the search
    if (!oc(tgWallStore).areas.value.revImpl.id('')) {
      return null;
    }

    const isLoading = tgWallStore.searchItems.state === RemoteDataState.REQUEST;

    const handleClickMobile = () => {
      if (!DESKTOP) {
        closeSearch();
      }
    };

    if (reset) {
      setTimeout(() => {
        setReset(false);
      }, 0);
      return <div className={cx(s.container, searchMode && s.active)}></div>;
    }
    return (
      <>
        <div className={cx(s.container, searchMode && s.active)}>
          <div className={cx(s.searchContainer, searchMode && s.active)}>
            <Autocomplete
              debug={reset}
              multiple
              getOptionLabel={tags => tags}
              onChange={(e: any, value: any) => setQuery(value)}
              onInputChange={(e: any, value: any) => setQueryAdd(value)}
              onKeyDown={checkKeyInput}
              options={tags}
              classes={{
                root: s.input,
                input: s.inputUnderline
              }}
              popupIcon={false}
              closeIcon={false}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={t('search_placeholder')}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true
                  }}
                />
              )}
            />
            <div className={s.buttons}>
              <ButtonBase centerRipple onClick={handleSearch}>
                <img src={searchIcon} alt={t('search')} />
              </ButtonBase>
              {false && (
                <ButtonBase centerRipple>
                  <img src={tuneIcon} alt="Filters" />
                </ButtonBase>
              )}
            </div>
          </div>
          {searchMode && (
            <div className={s.filters}>
              <FilterSelect
                title={t('type')}
                filters={[
                  { title: t('clusters'), name: 'clusters' },
                  { title: t('areas'), name: 'areas' }
                ]}
                onChange={setType}
                defaultValues={['clusters', 'areas']}
              />
              <FilterSelect
                title={t('status')}
                filters={[
                  { title: t('selling'), name: 'selling' },
                  { title: t('on_rent'), name: 'rent' },
                  { title: t('published'), name: 'published' }
                ]}
                onChange={setStatus}
                defaultValues={['selling', 'rent', 'published']}
              />
              <FilterPrice onChange={setPrices} />
              {false && (
                <FilterSelect
                  title={t('owner')}
                  filters={[
                    { title: t('user'), name: 'user' },
                    { title: t('platform'), name: 'platform' }
                  ]}
                />
              )}
            </div>
          )}
        </div>
        {searchMode && (
          <div className={s.resultContainer}>
            <ButtonBase
              disableRipple
              onClick={closeSearch}
              className={s.closeIcon}
            >
              <img src={closeIcon} alt="Close" />
            </ButtonBase>
            {isLoading ? (
              <CircularProgress className={s.loader} />
            ) : (
              <>
                <Typography variant="h3">
                  {t('places_found', {
                    count:
                      (type.includes('areas')
                        ? filterByStatusAndPrice(areaResult).length
                        : 0) +
                      (type.includes('clusters')
                        ? filterClusterByStatusAndPrice(clusterResult).length
                        : 0)
                  })}
                </Typography>
                <div className={s.items}>
                  {type.includes('clusters') &&
                    filterClusterByStatusAndPrice(clusterResult).map(c => {
                      return (
                        <SearchResult
                          title={t('cluster_default_name', {
                            areas: c.areas?.length
                          })}
                          type="cluster"
                          status={oc(c).item.status('None')}
                          tags={oc(c).item.tags([])}
                          areasCount={c.areas?.length}
                          key={c.id}
                          id={c.id}
                          price={c.item?.cost}
                          duration={c.item?.rentDuration}
                          revision={+c.revision}
                          owner={c.item?.owner.id}
                          handleClickMobile={handleClickMobile}
                        />
                      );
                    })}
                  {type.includes('areas') &&
                    filterByStatusAndPrice(areaResult).map(c => (
                      <SearchResult
                        title={t('area_title', { x: c.x, y: c.y })}
                        type="area"
                        status={c.item.status}
                        tags={c.item.tags}
                        key={c.id}
                        id={c.id}
                        duration={c.item.rentDuration}
                        price={c.item.cost}
                        owner={c.item.owner.id}
                        handleClickMobile={handleClickMobile}
                      />
                    ))}
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  });
};

export default Search;
