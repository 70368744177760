/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../Hooks/useStores';
import { RemoteDataState } from '../../../../Utils/RemoteData';
import { observer } from 'mobx-react-lite';
import { oc } from 'ts-optchain';
import MyLiquidityItem from './MyLiquidityItem/MyLiquidityItem';
import AddLiquidityDialog from './AddLiquidityDialog/AddLiquidityDialog';
import DescriptionPanel from '../../../../Components/Defi/DescriptionPanel/DescriptionPanel';
import s from './MyLiquidity.scss';
import sDefi from '../../Defi.scss';

const MyLiquidity = () => {
  const { t } = useTranslation();
  const { wallStore, tgLoanStore } = useStores();
  const disableTransactions =
    !wallStore.currentdAddress || !wallStore.networkVersionTrue;

  useEffect(() => {
    if (wallStore.currentdAddress) {
      tgLoanStore.getLenderOffers(wallStore.currentdAddress);
    }
  }, [wallStore.currentdAddress]);

  useEffect(() => {
    if (
      tgLoanStore.lenderOffers.state === RemoteDataState.SUCCESS ||
      tgLoanStore.lenderOffers.state === RemoteDataState.FAILURE
    ) {
      setTimeout(
        () => tgLoanStore.getLenderOffers(wallStore.currentdAddress as string),
        60000
      );
    }
  }, [tgLoanStore.lenderOffers.state]);

  const liquidityData = oc(tgLoanStore).lenderOffers.value([]);

  return (
    <div className={s.main}>
      <div className={s.left}>
        <div className={s.topBar}>
          <AddLiquidityDialog disabled={disableTransactions} />
        </div>
        <div className={s.tableContent}>
          {liquidityData.length ? (
            liquidityData.map(i => (
              <MyLiquidityItem
                key={i.id}
                offer={i}
                disabled={disableTransactions}
              />
            ))
          ) : (
            <div className={sDefi.empty}>
              <Typography variant="h6">
                {t('your_liquidity_positions_will_appear_here')}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div className={sDefi.rightPageContent}>
        <DescriptionPanel content={t('my_liquidity_description')} />
      </div>
    </div>
  );
};

export default observer(MyLiquidity);
