import React from 'react';
import s from './MyPlaces.scss';
import { CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Hooks/useStores';
import { useParams } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { oc } from 'ts-optchain';
import FieldItem from '../../Components/FieldItem/FieldItem';
import { RemoteDataState } from '../../Utils/RemoteData';
import NewClusterDialog from './Popups/NewClusterDialog';
import {
  CreateClusterSuccessDialog,
  CreateClusterErrorDialog
} from '../../Containers/Dialogs/Dialogs';

const MyClusters = () => {
  const { t } = useTranslation();
  const { tgWallStore } = useStores();
  const { id } = useParams() as { id: string };

  return useObserver(() => {
    const isLoading = tgWallStore.clusters.state !== RemoteDataState.SUCCESS;
    const userClustersIds = oc(tgWallStore).user.value.user.clusters([]);

    const userClusters = oc(tgWallStore)
      .clusters.value.clusters([])
      .filter(
        c => userClustersIds.includes(c.id) && c.item?.status !== 'Rented'
      );

    const userRentOutClusters = oc(tgWallStore)
      .clusters.value.clusters([])
      .filter(
        c => userClustersIds.includes(c.id) && c.item?.status === 'Rented'
      );

    const userRentedClusters = oc(tgWallStore)
      .clusters.value.clusters([])
      .filter(
        c =>
          c.item?.status === 'Rented' &&
          c.item.tenant?.id === tgWallStore.userAddress
      );

    const renderSection = (title: string, data: ClusterTgType[]) => {
      return (
        <div className={s.section}>
          <Typography variant="h6" className={s.title} gutterBottom>
            {title} {title === t('my_clusters') ? <NewClusterDialog /> : null}
          </Typography>
          {data.length > 0 && (
            <div className={s.clusters}>
              {data.map(c => {
                return (
                  <FieldItem
                    areasCount={oc(c).areas([]).length}
                    title={t('cluster_default_name', {
                      areas: oc(c).areas([]).length
                    })}
                    id={c.id}
                    isActive={c.id === id}
                    isCluster
                    item={c.item}
                  />
                );
              })}
            </div>
          )}
          {!isLoading && data.length === 0 && (
            <Typography>{t('cluster_empty')}</Typography>
          )}
          {isLoading && (
            <div className={s.loaderSmall}>
              <CircularProgress size={24} />
            </div>
          )}
        </div>
      );
    };

    return (
      <>
        {renderSection(t('my_clusters'), userClusters)}
        {renderSection(t('rented'), userRentedClusters)}
        {renderSection(t('rent_out'), userRentOutClusters)}
        <CreateClusterSuccessDialog />
        <CreateClusterErrorDialog />
      </>
    );
  });
};

export default MyClusters;
