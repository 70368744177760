import React, { useContext } from 'react';
import s from './Marketplace.scss';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import FieldItem from '../../Components/FieldItem/FieldItem';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import { useStores } from '../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import { RemoteDataState } from '../../Utils/RemoteData';
import getAddress from '../../Utils/getAddress';
import AppStateContext from '../../Contexts/AppStateContext';

type ActionType = 'rent' | 'buy';

const MarketplaceAreas = () => {
  const { wallStore, tgWallStore } = useStores();
  const { readOnly } = useContext(AppStateContext);
  const { t } = useTranslation();

  const areasForSale = oc(tgWallStore)
    .areas.value.areas([])
    .filter(
      c =>
        c.item.status === 'ForSale' &&
        !c.cluster?.id &&
        c.item.owner !== getAddress()
    );
  const areasForRent = oc(tgWallStore)
    .areas.value.areas([])
    .filter(
      c =>
        c.item.status === 'ForRent' &&
        !c.cluster?.id &&
        c.item.owner !== getAddress()
    );

  const handleAction = (type: ActionType, id: string, price: string) => () => {
    if (type === 'buy') {
      wallStore.buyExistingArea(id, 0, price);
    }
    if (type === 'rent') {
      wallStore.takeRentArea(id, 0, price);
    }
  };

  const renderSection = (
    data: AreaTgType[],
    status: RemoteDataState,
    title: string,
    message: string,
    type: ActionType
  ) => (
    <div className={s.section}>
      <Typography variant="h6" gutterBottom className={s.title}>
        {title}
      </Typography>
      {status === RemoteDataState.SUCCESS && data.length === 0 && (
        <Typography>{message}</Typography>
      )}
      <div className={s.items}>
        {status === RemoteDataState.REQUEST && (
          <CircularProgress size={16} color="primary" />
        )}
        {data.map(c => {
          return (
            <div className={s.item}>
              <FieldItem
                toMap
                title={t('area_title', {
                  x: c.x,
                  y: c.y
                })}
                id={c.id}
                item={c.item}
                key={c.cluster?.id}
              />
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleAction(type, c.id, oc(c).item.cost('0'))}
                disabled={readOnly}
              >
                {t(type)}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <>
      {renderSection(
        areasForRent,
        tgWallStore.areas.state,
        t('for_rent'),
        t('no_areas_for_rent'),
        'rent'
      )}
      {renderSection(
        areasForSale,
        tgWallStore.areas.state,
        t('for_sale'),
        t('no_areas_for_sale'),
        'buy'
      )}
    </>
  );
};

export default observer(MarketplaceAreas);
