import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import s from './MyPlaces.scss';
import { TitleLink } from './MyPlaces';
import {
  TextField,
  Button,
  Typography,
  ButtonBase,
  CircularProgress,
  Link,
  InputLabel
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import UploadImage from '../../Components/UploadImage/UploadImage';
import { useFormik } from 'formik';
import schema from './schema';
import { oc } from 'ts-optchain';
import { useObserver } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import { SIZE_AREA } from '../TheWall/TheWall';
import {
  SetContentMultiSuccessDialog,
  SetContentMultiErrorDialog
} from '../Dialogs/Dialogs';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindToggle } from 'material-ui-popup-state';
import ActionsPopup from './Popups/ActionsPopup/ActionsPopup';
// import moment from 'moment';
import closeIcon from '../../Resources/Icons/close.svg';
import { multiRequests } from '../../Utils/multiRequests';
import { getCorrectTags } from '../../Utils/editUtils';
import { RemoteDataState } from '../../Utils/RemoteData';
import { getPreview } from '../../Utils/Preview';

const MyClusterEdit = () => {
  return useObserver(() => {
    const { t } = useTranslation();
    const { wallStore, tgWallStore } = useStores();
    const { id } = useParams() as { id: string };
    const [resetImage, setResetImage] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    const popupState = usePopupState({
      variant: 'popper',
      popupId: 'editmenu'
    });
    const history = useHistory();

    useEffect(() => {
      popupState.close();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
      if (tgWallStore.clusters.state === RemoteDataState.SUCCESS) {
        getPreview(id)
          .then(path => {
            if (path) {
              setImageUrl(path);
            } else {
              throw new Error();
            }
          })
          .catch(e => {
            console.log('Error', e);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tgWallStore.clusters.state]);

    const tags = oc(tgWallStore)
      .tags.value.tags([])
      .map(c => c.id);

    const selectedCluster = oc(tgWallStore)
      .clusters.value.clusters([])
      .find(c => c.id === id);

    // const selectedClusterAreas = oc(tgWallStore)
    //   .areas.value.areas([])
    //   .filter(c => c.cluster?.id === id);

    const getSelectedClusterAreas = () => {
      if (selectedCluster) {
        return oc(tgWallStore)
          .areas.value.areas([])
          .filter(c => c.cluster?.id === id)
          .map(c => ({
            x: +c.x * SIZE_AREA,
            y: +c.y * SIZE_AREA,
            id: c.id,
            width: SIZE_AREA,
            height: SIZE_AREA,
            RGB: c.pixel,
            images: c.imageCID
          }));
      }
      return [];
    };

    const areasCluster = getSelectedClusterAreas();

    const initialValues: AreaFormType = {
      title: oc(selectedCluster).item.title(''),
      link: oc(selectedCluster).item.link(''),
      tags: oc(selectedCluster).item.tags([])
    };

    const {
      values,
      setFieldValue,
      submitForm,
      isValid,
      errors,
      resetForm
    } = useFormik({
      initialValues,
      isInitialValid: false,
      validationSchema: schema,
      enableReinitialize: true,
      onSubmit: values => {
        const contentData = {
          title: values.title,
          link: values.link,
          tags: getCorrectTags(oc(values.tags)([])).join(',')
        };

        const setContentMulti = (id: [], content: string[]) => {
          wallStore.setContentMulti(id, content);
        };

        const areasCluster = getSelectedClusterAreas();
        const imagesData =
          values.image != null
            ? values.image.map(i => {
                return { ...i, ...areasCluster.find(j => i.id === j.id) };
              })
            : [];

        multiRequests(imagesData, contentData, id, setContentMulti);
      }
    });

    const resetFormWithImage = () => {
      resetForm();
      setResetImage(true);
    };

    // const clusterHaveImage = selectedClusterAreas.find(c =>
    //   oc(c).imageCID[0]('')
    // );

    const handleCancel = () => {
      wallStore.cancelStatus(id || '');
    };

    const closeEdit = () => {
      history.push('/my/clusters');
    };

    const handleFinish = () => {
      wallStore.cancelRentArea(id || '');
    };

    const isRentedOut =
      selectedCluster?.item?.status === 'Rented' &&
      oc(selectedCluster).item.tenant.id('') !== wallStore.currentdAddress;

    const isMyRentedOut =
      selectedCluster?.item?.status === 'Rented' &&
      oc(selectedCluster).item.tenant.id('') === wallStore.currentdAddress;

    if (selectedCluster === undefined) return null;

    return selectedCluster ? (
      <div className={s.right}>
        <div className={s.closeIcon} onClick={closeEdit}>
          <img src={closeIcon} alt="Close" />
        </div>
        <div className={s.top}>
          <TitleLink to="/my/clusters" title={t('settings')} />
          <ButtonBase {...bindToggle(popupState)} disableRipple>
            <div className={s.dots}>
              <div className={s.dot} />
              <div className={s.dot} />
              <div className={s.dot} />
            </div>
          </ButtonBase>
          <ActionsPopup
            popupState={popupState}
            clusterId={id}
            disabled={selectedCluster.item?.status === 'Rented'}
          />
        </div>
        <div className={s.form}>
          <div className={s.gotToRow}>
            <TextField
              value={t('cluster_default_name', {
                areas: oc(selectedCluster).areas.length(0)
              })}
              label={t('name')}
              multiline
              InputProps={{
                classes: { input: s.largeInput }
              }}
              disabled
            />
            <Link
              to={`/?clusterId=${selectedCluster.id}`}
              variant="body1"
              underline="none"
              component={RouterLink}
            >
              {t('go_to_cluster')}
            </Link>
          </div>
          <TextField
            value={values.title}
            onChange={e => setFieldValue('title', e.target.value)}
            error={!!values.title && !!errors.title}
            label={t('description')}
            placeholder={t('add')}
            multiline
            InputLabelProps={{
              shrink: true
            }}
            disabled={isRentedOut}
          />
          {selectedCluster.item?.status !== 'None' && (
            <div className={s.status}>
              <InputLabel shrink filled>
                {t('status')}
              </InputLabel>
              <div className={s.row}>
                {selectedCluster.item?.status === 'ForSale' && (
                  <Typography color="primary" className={s.label}>
                    {t('for_sale')}
                  </Typography>
                )}
                {selectedCluster.item?.status === 'ForRent' && (
                  <Typography color="primary" className={s.label}>
                    {t('for_rent')}
                  </Typography>
                )}
                {selectedCluster.item?.status === 'Rented' && (
                  <Typography color="primary" className={s.label}>
                    {t('rent_out_for', {
                      days:
                        parseInt(oc(selectedCluster).item.rentDuration('0')) /
                        86400,
                      date: null
                      // date: moment(
                      //   parseInt(
                      //     oc(selectedCluster).statusDetails.expirationTime('0')
                      //   ) * 1000
                      // ).format('DD.MM.YYYY')
                    })}
                  </Typography>
                )}
                {selectedCluster.item?.status !== 'Rented' && (
                  <ButtonBase disableRipple onClick={handleCancel}>
                    <Typography color="error">{t('unpublish')}</Typography>
                  </ButtonBase>
                )}
                {isMyRentedOut && (
                  <ButtonBase disableRipple onClick={handleFinish}>
                    <Typography color="error">{t('finish_rent')}</Typography>
                  </ButtonBase>
                )}
              </div>
            </div>
          )}
          <TextField
            value={values.link}
            onChange={e => setFieldValue('link', e.target.value)}
            error={!!values.link && !!errors.link}
            helperText={errors.link}
            label={t('link')}
            placeholder={t('add')}
            InputLabelProps={{
              shrink: true
            }}
            disabled={isRentedOut}
          />
          <Autocomplete
            value={values.tags}
            onChange={(e, value) =>
              setFieldValue('tags', getCorrectTags(value))
            }
            multiple
            freeSolo
            filterSelectedOptions
            id="tags-standard"
            options={tags}
            classes={{
              input: s.autocompleteInput,
              endAdornment: s.autocompleteEnd,
              tag: s.autocompleteTag
            }}
            disabled={isRentedOut}
            renderInput={params => (
              <TextField
                {...params}
                label={t('tags')}
                placeholder={t('add_tags')}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              />
            )}
          />
          {areasCluster.length ? (
            <UploadImage
              areas={getSelectedClusterAreas()}
              macroblocks={oc(wallStore).macroblocksData.value([])}
              resetImage={resetImage}
              setResetImage={setResetImage}
              onChange={value => setFieldValue('image', value)}
              imageUrl={imageUrl || ''}
              isCluster
              disabled={isRentedOut}
            />
          ) : null}
        </div>
        <div className={s.bottom}>
          <ButtonBase disableRipple onClick={resetFormWithImage}>
            <Typography variant="body1" className={s.cancel}>
              {t('cancel')}
            </Typography>
          </ButtonBase>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            disabled={!isValid || wallStore.isSetContentMultiRequesting}
            onClick={submitForm}
            size="large"
          >
            {wallStore.isSetContentMultiRequesting ? (
              <CircularProgress size={16} color="secondary" />
            ) : (
              <Typography variant="body1">
                <strong>{t('save')} </strong>
              </Typography>
            )}
          </Button>
        </div>
        <SetContentMultiSuccessDialog />
        <SetContentMultiErrorDialog />
      </div>
    ) : (
      <CircularProgress className={s.loader} />
    );
  });
};

export default MyClusterEdit;
