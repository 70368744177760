import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../Hooks/useStores';
import s from './Popups.scss';

export default () => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const newCluster = () => {
    if (!description) return;
    const contentData = {
      title: description
    };
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    fetch('/api/upload', {
      method: 'POST',
      body: JSON.stringify(contentData),
      headers: myHeaders
    })
      .then(res => res.text())
      .then(response => {
        wallStore.createNewCluster(
          response.replace(/^f/i, '0x02').replace('\n', '')
        );
        setOpen(false);
      })
      .catch(error => console.log('error', error));
  };

  return (
    <div className={s.newClusterDialog}>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        + {t('create_new')}
      </Button>
      <Dialog open={open} onClose={handleClose} className={s.dialogWindow}>
        <DialogTitle>{t('creating_new_cluster')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="DESCRIPTION"
            type="text"
            fullWidth
            onChange={e => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions className={s.dialogButtons}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            disableElevation
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={newCluster}
            color="primary"
            variant="contained"
            disableElevation
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
