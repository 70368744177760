import { observable, set } from 'mobx';
import { AxiosResponse } from 'axios';
import { oc } from 'ts-optchain';

export enum RemoteDataState {
  'INITIAL' = 'INITIAL',
  'REQUEST' = 'REQUEST',
  'SUCCESS' = 'SUCCESS',
  'FAILURE' = 'FAILURE'
}

interface RemoteData<T> {
  state: RemoteDataState;
  value: Nullable<T>;
}

class RemoteData<T> {
  @observable state = RemoteDataState.INITIAL;
  @observable value: Nullable<T> = null;
}

export const createRemoteData = <T>(initialValue?: T): RemoteData<T> => ({
  state: RemoteDataState.INITIAL,
  value: initialValue || null
});

export const handleTgRemoteData = async <T>(
  remoteData: RemoteData<T>,
  method: () => Promise<{ data: {} }>,
  transform: (response: {}) => T,
  onSuccess?: (response?: {}) => any
): Promise<T> => {
  set(remoteData, { state: RemoteDataState.REQUEST });
  try {
    let value = await method();
    if (onSuccess) {
      onSuccess(value.data);
    }
    set(remoteData, {
      value: transform ? transform(value.data) : value.data,
      state: RemoteDataState.SUCCESS
    });
    return null as any;
  } catch (e) {
    set(remoteData, { state: RemoteDataState.FAILURE });
    return null as any;
  }
};

export const handleTgRemoteDataPagination = async <T>(
  remoteData: RemoteData<T>,
  method: (skip: number) => Promise<{ data: {} }>,
  revImpl: Nullable<RevImplType>,
  checkMethod: (response: {}) => boolean,
  transform: (response: {}[]) => Nullable<T>,
  options: { name: string; first: number; skip: number },
  data: {}[]
): Promise<T> => {
  set(remoteData, { state: RemoteDataState.REQUEST });
  try {
    let value = await method(options.skip);
    const { configuration } = value.data as {
      configuration: { revImpl: RevImplType };
    };

    if (
      revImpl &&
      revImpl.hash !== configuration.revImpl.hash &&
      revImpl.id !== configuration.revImpl.id
    ) {
      handleTgRemoteDataPagination(
        remoteData,
        method,
        null,
        checkMethod,
        transform,
        { ...options, skip: 0 },
        []
      );
    }
    if (checkMethod(value.data)) {
      set(remoteData, {
        value: transform([...data, value.data]),
        state: RemoteDataState.SUCCESS
      });
    } else {
      data.push(value.data);
      handleTgRemoteDataPagination(
        remoteData,
        method,
        configuration.revImpl,
        checkMethod,
        transform,
        { ...options, skip: options.skip + options.first },
        data
      );
    }
    return null as any;
  } catch (e) {
    console.log('Error', e);
    set(remoteData, { state: RemoteDataState.FAILURE });
    return null as any;
  }
};

export const handleTgRemoteDataIdGt = async <T>(
  remoteData: RemoteData<T>,
  method: (
    idGt: string
  ) => Promise<{
    data: {
      // areas: AreaTgType[];
      items: ItemTgType[];
      configuration: { revImpl: RevImplType };
    };
  }>,
  revImpl: Nullable<RevImplType>,
  checkMethod: (response: {}) => boolean,
  transform: (response: {}[]) => Nullable<T>,
  options: { name: 'items'; first: number; idGt: string },
  data: {}[]
): Promise<T> => {
  set(remoteData, { state: RemoteDataState.REQUEST });
  try {
    let value = await method(options.idGt);
    const { configuration } = value.data as {
      configuration: { revImpl: RevImplType };
    };

    if (
      revImpl &&
      revImpl.hash !== configuration.revImpl.hash &&
      revImpl.id !== configuration.revImpl.id
    ) {
      handleTgRemoteDataIdGt(
        remoteData,
        method,
        null,
        checkMethod,
        transform,
        { ...options, idGt: '0' },
        []
      );
    }
    if (checkMethod(value.data)) {
      set(remoteData, {
        value: transform([...data, value.data]),
        state: RemoteDataState.SUCCESS
      });
    } else {
      const items = value.data[options.name];
      const idGt = items[items.length - 1].id;
      data.push(value.data);
      handleTgRemoteDataIdGt(
        remoteData,
        method,
        configuration.revImpl,
        checkMethod,
        transform,
        { ...options, idGt: idGt },
        data
      );
    }
    return null as any;
  } catch (e) {
    console.log('Error', e);
    set(remoteData, { state: RemoteDataState.FAILURE });
    return null as any;
  }
};

export const handleRemoteData = async <T>(
  remoteData: RemoteData<T>,
  method: () => Promise<AxiosResponse<T>>,
  onSuccess?: (response?: T) => any,
  transform?: (response?: T) => T
): Promise<T> => {
  set(remoteData, { state: RemoteDataState.REQUEST });
  try {
    let value = await method();
    if (onSuccess) {
      onSuccess(value.data);
    }
    set(remoteData, {
      value: transform ? transform(value.data) : value.data,
      state: RemoteDataState.SUCCESS
    });
    return value.data;
  } catch (e) {
    set(remoteData, { state: RemoteDataState.FAILURE });
    return null as any;
  }
};

export const handleRemoteDataToArray = async <T>(
  remoteData: RemoteData<T[]>,
  method: () => Promise<AxiosResponse<T>>,
  key: string,
  onSuccess?: (response?: T) => any,
  transform?: (response?: T) => T
): Promise<T> => {
  set(remoteData, { state: RemoteDataState.REQUEST });
  let value = await method();
  set(remoteData, {
    value: [
      ...oc(remoteData)
        .value([])
        .filter(
          (c: { [index: string]: any }) =>
            c[key] !== (value.data as { [index: string]: any })[key]
        ),
      transform ? transform(value.data) : value.data
    ],
    state: RemoteDataState.SUCCESS
  });

  if (onSuccess) {
    onSuccess(value.data);
  }

  return value.data;
};

export const handleMacroblocksRemoteData = async <T>(
  remoteData: RemoteData<T>,
  method: () => Promise<AxiosResponse<MacroblockDataType>>,
  transform: (response?: MacroblockDataType) => T,
  onSuccess?: (response?: MacroblockDataType) => any,
  onFailure?: (response?: any) => any
): Promise<T> => {
  set(remoteData, { state: RemoteDataState.REQUEST });
  try {
    let value = await method();
    if (onSuccess) {
      onSuccess(value.data);
    }
    const result = transform(value.data);
    set(remoteData, {
      value: result,
      state: RemoteDataState.SUCCESS
    });
    return result;
  } catch (e) {
    if (onFailure) {
      onFailure(e);
    }
    set(remoteData, { state: RemoteDataState.FAILURE });
    return null as any;
  }
};

export const handleContractRemoteData = <T>(
  remoteData: RemoteData<T>,
  error: Error,
  response: string,
  transactions?: TransactionHashStatus[]
) => {
  if (error) {
    set(remoteData, { state: RemoteDataState.FAILURE });
  } else {
    set(remoteData, { state: RemoteDataState.SUCCESS, value: response });
    if (transactions && transactions.length >= 0) {
      set(transactions, [...transactions, { hash: response, status: '0' }]);
    }
  }
};

export default RemoteData;
