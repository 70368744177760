import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';

import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import polygon_1 from '../../../Resources/Instructions/polygon_1.png';
import polygon_2 from '../../../Resources/Instructions/polygon_2.png';
import polygon_3 from '../../../Resources/Instructions/polygon_3.png';
import polygon_4 from '../../../Resources/Instructions/polygon_4.png';
import polygon_5 from '../../../Resources/Instructions/polygon_5.png';
import polygon_6 from '../../../Resources/Instructions/polygon_6.png';
import polygon_7 from '../../../Resources/Instructions/polygon_7.png';
import polygon_8 from '../../../Resources/Instructions/polygon_8.png';
import s from '../Instructions.scss';

const Polygon = () => {
  const { t } = useTranslation();
  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('how_to_connect_your_account_to_polygon')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_is_this_guide_about')}
            text={t('this_guide_is_about')}
          />
        </Grid>
        <Grid item md={6}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_polygon_is')}
            text={t('polygon_is_a_second_level_network')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={s.elementorSection}>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('what_is_needed')}</Typography>
          <ol>
            <li>
              <Typography variant="body1">{t('what_is_needed_1')}</Typography>
            </li>
            <li>
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{ __html: t('metamask_wallet') }}
                />
              </Typography>
            </li>
          </ol>
        </Grid>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('how_to_start')}</Typography>
          <Typography variant="body1">
            {t('launch_metamask_and_unblock')}
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} className={s.backgroundColor2}>
          <Infobox
            icon="faLightBulb"
            title={t('how_to_open_metamask_fullscreen')}
            text={t('select_the_expand_view_command')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">{t('step_by_step_instruction')}</Typography>
        <Grid container spacing={2}>
          <Grid item md={3} />
          <Grid item md={6}>
            <Infobox
              num={1}
              text={t('find_the_list_of_networks_in_the_settings')}
            />
          </Grid>
          <Grid item md={3} />
          <Grid item md={4}>
            <FancyBoxImage image={polygon_1} />
          </Grid>
          <Grid item md={4}>
            <FancyBoxImage image={polygon_2} />
          </Grid>
          <Grid item md={4}>
            <FancyBoxImage image={polygon_3} />
          </Grid>
          <Grid item md={6}>
            <FancyBoxImage image={polygon_4} />
          </Grid>
          <Grid item md={6}>
            <FancyBoxImage image={polygon_5} />
          </Grid>
          <Grid item md={6}>
            <Infobox num={2} text={t('if_you_have_purchased_twg_tokens')} />
          </Grid>
          <Grid item md={6}>
            <Infobox num={3} text={t('fill_in_the_form_to_add_a_twg_token')} />
          </Grid>
          <Grid item md={4}>
            <FancyBoxImage image={polygon_6} />
          </Grid>
          <Grid item md={4}>
            <FancyBoxImage image={polygon_7} />
          </Grid>
          <Grid item md={4}>
            <FancyBoxImage image={polygon_8} />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default Polygon;
