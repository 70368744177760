import React, { useContext } from 'react';
import cx from 'classnames';
import metamaskLogo from '../../Resources/metamaskLogo.svg';
import metamaskWallLogo from '../../Resources/wall_metamask.svg';
import s from './MetamaskInfo.scss';
import { Typography, ButtonBase, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppStateContext, { MetamaskState } from '../../Contexts/AppStateContext';
import { isMobile } from 'react-device-detect';

interface MetamaskInfoProps {
  handleAccountsChanged: (accounts: any[]) => void;
}

const MetamaskInfo: React.FC<MetamaskInfoProps> = ({
  handleAccountsChanged
}) => {
  const { t } = useTranslation();
  const { pluginState } = useContext(AppStateContext);

  const connect = async () => {
    const address = await window.ethereum.request({
      method: 'eth_requestAccounts'
    });
    if (address) {
      handleAccountsChanged([address]);
    }
  };

  if (isMobile && pluginState === MetamaskState.Installed) {
    connect();
  }

  return (
    <>
      {pluginState === MetamaskState.NotInstalled && (
        <div className={s.container}>
          <img src={metamaskLogo} alt="Metamask" />
          <div className={s.info}>
            <Link
              href="https://metamask.io/download.html"
              target="_blank"
              children={
                <span
                  dangerouslySetInnerHTML={{ __html: t('install_metamask') }}
                />
              }
              variant="h5"
              color="textPrimary"
              className={s.text}
              underline="none"
            />
          </div>
        </div>
      )}
      {pluginState === MetamaskState.Installed && (
        <div className={cx(s.container, s.connect)}>
          <Typography variant="h5" color="secondary" gutterBottom>
            {t('metamask_installed')}
          </Typography>
          <ButtonBase onClick={connect}>
            <Typography color="textPrimary" className={s.infoText}>
              {t('connect_metamask')}
            </Typography>
          </ButtonBase>
        </div>
      )}
      {pluginState === MetamaskState.Connected && (
        <div className={cx(s.container, s.connected)}>
          <img
            src={metamaskWallLogo}
            alt={t('metamask_connected')}
            className={s.logoAll}
          />
          <Typography variant="h5" color="textPrimary" className={s.text}>
            {t('metamask_connected')}
          </Typography>
        </div>
      )}
    </>
  );
};

export default MetamaskInfo;
