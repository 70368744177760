import React from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import s from './ShowMore.scss';
import './ShowMore.css';

interface ShowMoreProps {
  content: string;
}

const ShowMore: React.FC<ShowMoreProps> = ({ content }) => {
  return (
    <Accordion className={s.container}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} />
      <AccordionDetails>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ShowMore;
