/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import s from './UploadImage.scss';
import {
  Button,
  ButtonBase,
  InputLabel,
  Modal,
  Typography
} from '@material-ui/core';
import Konva from 'konva';
import { renderImage } from '../../Containers/TheWall/Elements/renderImage';
import {
  initStage,
  renderScene
} from '../../Containers/TheWall/Elements/renderScene';
import { renderSectors } from '../../Containers/TheWall/Elements/renderSectors';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import { useStores } from '../../Hooks/useStores';
import { SIZE_AREA, DESKTOP } from '../../Containers/TheWall/TheWall';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useObserver } from 'mobx-react-lite';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { debounce } from 'lodash';

const WIDTH = DESKTOP ? 800 : window.innerWidth;
const HEIGHT = DESKTOP ? 400 : window.innerHeight - 160; // .top, .bottom {height: 80px};
const MAX_SIZE_AREA_IMAGE = 100;

interface SaveResult {
  result: ImageResult[];
  preview: string;
}

const renderPreview = async (
  stage: Konva.Stage,
  selectedImage: string,
  areas: AreaCoordinate[],
  scale: number,
  btMode: boolean = false
) => {
  const layer = new Konva.Layer();
  stage.add(layer);

  const HEIGHT_MAX = 200;

  const image = (await renderImage(
    layer,
    {
      path: selectedImage
    },
    0,
    300,
    HEIGHT_MAX / scale,
    undefined,
    undefined,
    {
      draggable: true,
      opacity: 1,
      pixelRatio: 3
    }
  )) as Konva.Image;

  const availableGroup = new Konva.Group();

  areas.forEach(c => {
    const availableRect = new Konva.Rect({
      ...c,
      y: c.y + SIZE_AREA,
      fill: '#fff',
      stroke: '#fff',
      listening: false,
      scaleY: -1
    });

    availableGroup.add(availableRect);
  });

  availableGroup.cache();
  availableGroup.globalCompositeOperation('destination-atop');

  layer.add(availableGroup);

  const availableHintGroup = new Konva.Group();

  areas.forEach(c => {
    const availableRectArea = new Konva.Rect({
      ...c,
      y: c.y + SIZE_AREA,
      fill: 'rgba(0, 26, 255, 0.1)',
      listening: false,
      scaleY: -1
    });

    availableHintGroup.add(availableRectArea);
  });

  layer.add(availableHintGroup);
  availableHintGroup.moveToBottom();

  const imageAspectRatio = image.width() / image.height();

  if (image.height() > HEIGHT_MAX / scale) {
    image.height(HEIGHT_MAX / scale);
    image.width((HEIGHT_MAX / scale) * imageAspectRatio);
  }

  const lowY = orderBy(areas, ['y'], ['asc'])[0].y;
  const lowYClear = orderBy(areas, ['y'], ['asc'])[0].y;
  const lowX = orderBy(areas, ['x'], ['asc'])[0].x;
  const highY = orderBy(areas, ['y'], ['desc'])[0].y + SIZE_AREA;
  const highX = orderBy(areas, ['x'], ['desc'])[0].x + SIZE_AREA;

  image.x(lowX);
  image.y(highY);

  const lastSuccessScale = { scaleY: 0, scaleX: 0 };

  const transparentImage = (await renderImage(
    layer,
    {
      path: selectedImage
    },
    0,
    image.x(),
    image.y(),
    image.width(),
    image.height(),
    {
      opacity: 0.1,
      listening: false
    }
  )) as Konva.Image;

  image.on('dragmove', e => {
    const target = e.currentTarget as Konva.Image;
    transparentImage.x(target.x());
    transparentImage.y(target.y());
  });

  image.on('transform', e => {
    const target = e.currentTarget as Konva.Image;

    if (target && (target.scaleY() * -1 < 0 || target.scaleX() < 0)) {
      target.scaleX(lastSuccessScale.scaleX);
      target.scaleY(lastSuccessScale.scaleY);
    } else {
      lastSuccessScale.scaleX = target.scaleX();
      lastSuccessScale.scaleY = target.scaleY();
      transparentImage.scaleX(target.scaleX());
      transparentImage.scaleY(target.scaleY());
      transparentImage.x(target.x());
      transparentImage.y(target.y());
    }
  });

  const transformer = new Konva.Transformer({
    node: image as any,
    keepRatio: true,
    rotateEnabled: false,
    enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
    anchorSize: 5,
    draggable: true,
    anchorFill: '#000',
    anchorStroke: '#000',
    borderStrokeWidth: 2,
    borderStroke: 'rgba(0, 0, 0, 0.3)',
    id: 'transformer',
    borderDash: [5, 1],
    boundBoxFunc: function(oldBoundBox, newBoundBox) {
      if (
        Math.abs(newBoundBox.width) < 10 ||
        Math.abs(newBoundBox.height) < 10
      ) {
        return oldBoundBox;
      }

      return {
        ...newBoundBox
      };
    }
  });

  layer.add(transparentImage);
  layer.add(transformer);
  layer.draw();

  async function saveAreas(scale: number): Promise<SaveResult> {
    transformer.remove();
    transparentImage.remove();
    availableHintGroup.remove();

    const rect = availableGroup.getClientRect({});

    layer.cache({
      x: lowX,
      y: lowY,
      width: rect.width,
      height: rect.height,
      pixelRatio: scale
    });

    // this thing need to fix bug of empty screen
    layer.toDataURL({
      x: lowX,
      y: lowY,
      width: rect.width,
      height: rect.height,
      pixelRatio: scale
    });

    // @ts-ignore
    const cropCanvas = (sourceCanvas, left, top, width, height) => {
      let destCanvas = document.createElement('canvas');
      destCanvas.width = width;
      destCanvas.height = height;
      const ctx = destCanvas.getContext('2d');
      if (ctx) {
        ctx.scale(1, -1);
        ctx.drawImage(
          sourceCanvas,
          left,
          top,
          width,
          height, // source rect with content to crop
          0,
          0,
          width,
          height * -1
        ); // newCanvas, same size as source rect
      }
      return destCanvas;
    };

    const result: ImageResult[] = [];

    const makeBlobAsyn = (c: AreaCoordinate) => {
      const { name, type } = { name: '', type: 'image/jpeg' };
      fetch(makeBase64ForBT(c))
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], name, { type }))
        .then(file => {
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', 'image/jpeg');
          const formData = new FormData();
          formData.append('file', file);
          fetch('/api/upload', {
            method: 'POST',
            body: file,
            headers: myHeaders
          })
            .then(res => {
              if (res.ok) {
                return res.text();
              } else {
                setTimeout(() => {
                  window.location.reload();
                }, 3);
                alert(res.statusText);
                throw new Error(res.statusText);
              }
            })
            .then(response => {
              const imgCid = response.replace('\n', '');
              result.push({
                data: new Blob([imgCid], {
                  type: 'image/jpeg'
                }),
                id: c.id as string,
                btih: imgCid
              });
            })
            .catch(error => console.log('error', error));
        });
    };

    const makeBase64ForBT = (c: AreaCoordinate): string => {
      return cropCanvas(
        layer._getCanvasCache().scene._canvas,
        c.x * scale - lowX * scale,
        Math.abs(c.y * scale - lowYClear * scale),
        oc(c).width(0) * scale,
        oc(c).height(0) * scale
      ).toDataURL('image/jpeg', 0.8);
    };

    const makeBase64 = (c: AreaCoordinate): string => {
      return cropCanvas(
        layer._getCanvasCache().scene._canvas,
        c.x * scale - lowX * scale,
        Math.abs(c.y * scale - highY * scale),
        oc(c).width(0) * scale,
        oc(c).height(0) * scale
      ).toDataURL('image/jpeg', 0.8);
    };

    const isInBox = (c: AreaCoordinate): boolean => {
      return (
        c.x + SIZE_AREA >= transparentImage.x() &&
        c.y <= transparentImage.y() &&
        c.x <=
          transparentImage.x() +
            transparentImage.width() * transparentImage.scaleX() &&
        c.y >=
          transparentImage.y() -
            Math.abs(transparentImage.height() * transparentImage.scaleY()) -
            SIZE_AREA
      );
    };

    let notInBox = 0;
    for (let i = 0; i < areas.length; i++) {
      if (isInBox(areas[i])) {
        makeBlobAsyn(areas[i]);
      } else {
        notInBox++;
      }
    }

    let checkResultUpload = new Promise(resolve => {
      const checkResult = () => {
        setTimeout(() => {
          if (areas.length - notInBox === result.length) {
            resolve(true);
          } else {
            checkResult();
          }
        }, 200);
      };
      checkResult();
    });

    await checkResultUpload;

    const preview = makeBase64({
      x: lowX,
      y: highY,
      width: highX - lowX,
      height: highY - lowY
    });

    return { result, preview };
  }

  return { availableGroup, saveAreas };
};

export const getVisibleAreasData = (
  stage: Konva.Stage,
  width: number,
  height: number,
  offsetX: number,
  offsetY: number,
  defaultScale: number
): VisibleAreas => {
  const widthAreas = Math.abs(Math.round(width / (SIZE_AREA * stage.scaleX())));
  const heightAreas = Math.abs(
    Math.round(height / (SIZE_AREA * stage.scaleY()))
  );
  const x = offsetX - stage.x() / stage.scaleX();
  const y = offsetY - stage.y() / stage.scaleY();
  return {
    x: Math.round(x / SIZE_AREA - width / (SIZE_AREA * defaultScale * 2)),
    y:
      Math.round(y / SIZE_AREA + height / (SIZE_AREA * defaultScale * 2)) -
      heightAreas,
    width: widthAreas,
    height: heightAreas
  };
};

export const getVisibleAreas = (
  stage: Konva.Stage,
  areas: AreaTgType[],
  width: number,
  height: number,
  offsetX: number,
  offsetY: number,
  defaultScale: number
) => {
  const widthAreas = Math.abs(Math.round(width / (SIZE_AREA * stage.scaleX())));
  const heightAreas = Math.abs(
    Math.round(height / (SIZE_AREA * stage.scaleY()))
  );
  const x = offsetX - stage.x() / stage.scaleX();
  const y = offsetY - stage.y() / stage.scaleY();
  const x1 = Math.round(x / SIZE_AREA - width / (SIZE_AREA * defaultScale * 2));
  const x2 = x1 + widthAreas;
  const y1 =
    Math.round(y / SIZE_AREA + height / (SIZE_AREA * defaultScale * 2)) -
    heightAreas;
  const y2 = y1 + heightAreas;
  return areas.filter(i => +i.x >= x1 && +i.x < x2 && +i.y >= y1 && +i.y < y2);
};

const getDefaultScale = (areas: AreaCoordinate[]) => {
  // Auto zoom levels
  let defaultScale = 1;

  if (areas.length <= 16) {
    defaultScale = 4;
  }

  if (areas.length === 1) {
    defaultScale = 5;
  }
  return defaultScale;
};

interface UploadImageProps {
  areas: AreaCoordinate[];
  macroblocks: MacroblockScaleType[];
  resetImage: boolean;
  setResetImage: (value: boolean) => void;
  onChange?: (value: Nullable<ImageResult[]>) => void;
  imageUrl?: string;
  isCluster?: boolean;
  disabled?: boolean;
}

const UploadImage: React.FC<UploadImageProps> = ({
  areas,
  macroblocks,
  resetImage,
  setResetImage,
  onChange,
  imageUrl,
  isCluster,
  disabled
}) => {
  const { t } = useTranslation();
  const { wallStore, tgWallStore } = useStores();
  const [imageObject, setImageObject] = useState<Nullable<string>>(null);
  const [isModalOpen, setModalState] = useState(false);
  const [preview, setPreview] = useState<Nullable<any>>(null);
  const [savedAreas, setSavedAreas] = useState<ImageResult[]>([]);
  const [savedPreview, setSavedPreview] = useState<Nullable<string>>(null);
  const [scale, setScale] = useState(1);
  const [imageLoadingProcess, setImageLoadingProcess] = useState(false);
  const [originalUrl, setOriginalUrl] = useState(imageUrl);
  const [maxScaleSize, setMaxScale] = useState(1);
  const [isEditStarted, setEditStarted] = useState(false);
  const [isQualityMode, setQualityMode] = useState(false);
  const [stage, setStage] = useState<Nullable<Konva.Stage>>(null);
  const [btMode, SetBtMode] = useState(false);
  const modalRef = useRef(null);
  const fileRef = useRef(null);

  useEffect(() => {
    if (resetImage) {
      setResetImage(false);
      setOriginalUrl(imageUrl);
      setSavedPreview(null);
      setSavedAreas([]);
    }
  }, [resetImage]);

  const updateMacroblocksPreview = debounce((stage: Konva.Stage) => {
    const lowX = areas.length ? orderBy(areas, ['x'], ['asc'])[0].x : 0;
    const lowY = areas.length ? orderBy(areas, ['y'], ['asc'])[0].y : 0;

    const heightContainer = HEIGHT;
    const widthContainer = oc(modalRef as any).current.clientWidth(WIDTH);

    const ownedAreas = oc(tgWallStore).areas.value.areas([]);

    const visibleData = getVisibleAreasData(
      stage,
      widthContainer,
      heightContainer,
      lowX,
      lowY,
      getDefaultScale(areas)
    );

    renderSectors(stage, macroblocks, visibleData, ownedAreas);
  }, 1000);

  useEffect(() => {
    if (imageObject && areas.length > 0) {
      setModalState(true);
      const lowX = areas.length ? orderBy(areas, ['x'], ['asc'])[0].x : 0;
      const lowY = areas.length ? orderBy(areas, ['y'], ['asc'])[0].y : 0;

      setTimeout(() => {
        const heightContainer = HEIGHT;
        const widthContainer = oc(modalRef as any).current.clientWidth(WIDTH);

        const defaultScale = getDefaultScale(areas);

        if (stage) {
          updateMacroblocksPreview(stage);
        }

        const macroblockScaleList = wallStore.getMacroblockScaleList();
        if (!isEditStarted && macroblockScaleList.length) {
          const newStage = initStage({
            width: widthContainer,
            height: heightContainer,
            container: 'editor',
            draggable: true,
            scaleY: -1
          });

          renderScene(newStage, {
            width: +oc(tgWallStore).wall.value.wallWidth('0'),
            height: +oc(tgWallStore).wall.value.wallHeight('0'),
            macroblockScaleList
          });

          newStage.on('xChange', () => {
            updateMacroblocksPreview(newStage);
          });

          renderPreview(
            newStage,
            imageObject,
            areas,
            defaultScale,
            btMode
          ).then(props => {
            const rect = props.availableGroup.getClientRect({});
            newStage.offsetX(
              (-widthContainer / 2 +
                lowX * defaultScale +
                (rect.width / 2) * defaultScale) /
                defaultScale
            );
            newStage.offsetY(
              (heightContainer / 2 +
                lowY * defaultScale +
                (rect.height / 2) * defaultScale) /
                defaultScale
            );
            newStage.scaleY(-defaultScale);
            newStage.scaleX(defaultScale);
            newStage.draw();
            setPreview(props);
          });

          setStage(newStage);
          setEditStarted(true);
        }
      }, 100);
    }
  }, [imageObject, isEditStarted, macroblocks]);

  useEffect(() => {
    setOriginalUrl(imageUrl);
    setSavedPreview(null);
  }, [imageUrl]);

  useEffect(() => {
    setScale(Math.round(maxScaleSize));
  }, [maxScaleSize]);

  const updateSave = async (s: number) => {
    stage && stage.draw();
  };

  const updateSaveDebounced = useMemo(
    () => AwesomeDebouncePromise(updateSave, 1000),
    [preview]
  );

  useEffect(() => {
    if (isQualityMode && scale > 0) {
      updateSaveDebounced(scale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQualityMode, scale]);

  const handleFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    btMode = false
  ) => {
    SetBtMode(btMode);
    if (e.target.files && e.target.files.length > 0) {
      setImageObject(URL.createObjectURL(e.target.files[0]));
      var img = new Image();
      img.addEventListener('load', function() {
        const maxScale =
          (this.naturalWidth < this.naturalHeight
            ? this.naturalWidth
            : this.naturalHeight) / SIZE_AREA;
        setMaxScale(
          maxScale < MAX_SIZE_AREA_IMAGE / SIZE_AREA
            ? maxScale
            : MAX_SIZE_AREA_IMAGE / SIZE_AREA
        );
      });
      img.src = URL.createObjectURL(e.target.files[0]);
      if (fileRef.current) {
        // @ts-ignore
        fileRef.current.value = '';
      }
    }
  };

  const cleanState = () => {
    setImageObject(null);
    setModalState(false);
    setEditStarted(false);
    setQualityMode(false);
    setPreview(null);
    wallStore.clearImageFee();
  };

  const handleSave = async () => {
    if (preview) {
      setImageLoadingProcess(true);
      const result = (await preview.saveAreas(scale)) as SaveResult;
      setImageLoadingProcess(false);
      setSavedAreas(result.result);
      setSavedPreview(result.preview);
      if (onChange) {
        onChange(result.result);
      }
      cleanState();
    }
  };

  const handleCancel = () => {
    cleanState();
  };

  const handleDelete = () => {
    setSavedAreas([]);
    setSavedPreview(null);
    setImageObject(null);
    setOriginalUrl('');
    setPreview(null);
    if (onChange) {
      onChange(
        areas.map(i => ({
          btih: '',
          id: i.id,
          data: {} as Blob
        })) as ImageResult[]
      );
    }
  };

  return useObserver(() => {
    return (
      <>
        {!originalUrl && savedAreas.length === 0 && (
          <>
            <div className={s.container}>
              <InputLabel shrink>
                {isCluster ? t('cluster_image') : t('area_image')}
              </InputLabel>
              <input
                accept="image/*"
                className={s.uploadInput}
                id="uploadareaBt"
                multiple
                type="file"
                onChange={e => handleFile(e, true)}
                ref={fileRef}
                disabled={disabled || (isCluster && !areas.length)}
              />
              <label htmlFor="uploadareaBt">
                <div className={s.placeholder}>
                  <Typography className={s.placeholderLabel}>
                    {t('add_image')}
                  </Typography>
                </div>
              </label>
            </div>
          </>
        )}
        {(originalUrl || savedAreas.length > 0) && (
          <div className={s.imageContainer}>
            <div className={s.image}>
              <img src={savedPreview || originalUrl || ''} alt="" />
            </div>
            {!disabled && (
              <div className={s.controlBottom}>
                <ButtonBase
                  disableRipple
                  onClick={handleDelete}
                  disabled={disabled}
                >
                  <Typography variant="caption" className={s.delete}>
                    {t('delete')}
                  </Typography>
                </ButtonBase>
              </div>
            )}
          </div>
        )}
        <Modal className={s.modal} open={isModalOpen}>
          <div className={s.modalContainer} ref={modalRef}>
            <div className={s.top}>
              <Typography variant="h5" color="secondary" className={s.title}>
                {t('image_upload')}
              </Typography>
            </div>
            <div className={s.editor} id="editor" />
            <div className={s.bottom}>
              <div className={s.buttons}>
                <ButtonBase disableRipple onClick={handleCancel}>
                  <Typography variant="body1" className={s.cancel}>
                    {t('cancel')}
                  </Typography>
                </ButtonBase>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleSave}
                  disabled={imageLoadingProcess}
                >
                  <Typography variant="body1">
                    {imageLoadingProcess ? (
                      <CircularProgress size={16} color="secondary" />
                    ) : (
                      <Typography variant="body1">
                        <strong>{t('save')} </strong>
                      </Typography>
                    )}
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  });
};

export default UploadImage;
