import { renderGrid } from './renderGrid';
import Konva from 'konva';
// import { renderImage } from './renderImage';
import {
  HandleObjectMouseType,
  HandleObjectTouchendType,
  HandleWheelMouseType,
  MAX_SCALE,
  SCALE_BY,
  SIZE_AREA
} from '../TheWall';
import { oc } from 'ts-optchain';
// import { StageConfig } from 'konva/types/Stage';
import { debounce } from 'lodash';

const URI_IPFS = window.config.URI_IPFS;

export const RESTRICTED_LAYER_ID = 'restricted_layer';
export const MACROBLOCK_GROUP_ID = 'macroblock_group';
const IMAGES_LAYER_ID = 'images_layer';
const CLUSTERS_LAYER_ID = 'clusters_layer';
const AREAS_LAYER_ID = 'areas_lalyer';
export const SELECT_LAYER_ID = 'select_lalyer';

export const initStage = ({ width, height, ...props }: any) => {
  (Konva as any).hitOnDragEnabled = true;
  return new Konva.Stage({
    width,
    height,
    offsetX: -(width || 0) / 2,
    offsetY: -(height || 0) / 2,
    ...props
  });
};

export const handleZoom = (
  stage: Nullable<Konva.Stage>,
  value: boolean,
  manual?: boolean
) => (): Nullable<number> => {
  if (stage) {
    const oldScale = stage.scaleX();
    const pointerPositionX = manual
      ? stage.width() / 2
      : oc(stage.getPointerPosition()).x(0);
    const pointerPositionY = manual
      ? stage.height() / 2
      : oc(stage.getPointerPosition()).y(0);

    const mousePointTo = {
      x: pointerPositionX / oldScale - stage.x() / oldScale,
      y: pointerPositionY / oldScale - stage.y() / oldScale
    };

    let newScale = value
      ? oldScale - (manual ? SCALE_BY : SCALE_BY / 10)
      : oldScale + (manual ? SCALE_BY : SCALE_BY / 10);

    if (newScale > 1) {
      newScale = value
        ? oldScale - (manual ? SCALE_BY : SCALE_BY / 2)
        : oldScale + (manual ? SCALE_BY : SCALE_BY / 2);
    }

    if (newScale > 4) {
      newScale = value
        ? oldScale - (manual ? SCALE_BY : SCALE_BY)
        : oldScale + (manual ? SCALE_BY : SCALE_BY);
    }

    const x = -(mousePointTo.x - pointerPositionX / newScale) * newScale;
    const y = -(mousePointTo.y - pointerPositionY / newScale) * newScale;

    if (newScale >= 0.07 && newScale <= MAX_SCALE) {
      stage.scale({ x: newScale, y: -newScale });
      stage.position({ x, y });
      stage.batchDraw();
      return newScale;
    }

    return oldScale;
  }

  return null;
};

interface RenderSceneOptions {
  width: number;
  height: number;
  macroblockScaleList: number[];
  onFieldTouchend?: HandleObjectTouchendType;
  onImageClick?: HandleObjectMouseType;
  onFieldClick?: HandleObjectMouseType;
  onWheel?: HandleWheelMouseType;
  onDragStart?: () => void;
  getMultipleState?: () => boolean;
  onMultipleSelect?: (value: AreaCoordinate) => void;
  renderClusterBorder?: (x: number, y: number, stage: Konva.Stage) => boolean;
}

export interface SceneOutput {
  hideHighlightBox: () => void;
}

const getLayerWithBorderCluster = (
  clusters: ClusterTgType[],
  areas: AreaTgType[],
  layer: Konva.Layer,
  hintAreaId?: string
) => {
  const checkLeft = (clusterAreas: AreaTgType[], p: { x: string; y: string }) =>
    clusterAreas.filter(c => +c.x === +p.x - 1 && +c.y === +p.y).length;
  const checkRight = (
    clusterAreas: AreaTgType[],
    p: { x: string; y: string }
  ) => clusterAreas.filter(c => +c.x === +p.x + 1 && c.y === p.y).length;
  const checkBottom = (
    clusterAreas: AreaTgType[],
    p: { x: string; y: string }
  ) => clusterAreas.filter(c => c.x === p.x && +c.y === +p.y - 1).length;
  const checkTop = (clusterAreas: AreaTgType[], p: { x: string; y: string }) =>
    clusterAreas.filter(c => c.x === p.x && +c.y === +p.y + 1).length;

  const getLeftLine = (p: { x: string; y: string }) => [
    +p.x * SIZE_AREA,
    (+p.y + 1) * SIZE_AREA + 0.5,
    +p.x * SIZE_AREA,
    (+p.y + 1) * SIZE_AREA - SIZE_AREA - 0.5
  ];
  const getBottomLine = (p: { x: string; y: string }) => [
    +p.x * SIZE_AREA,
    (+p.y + 1) * SIZE_AREA - SIZE_AREA,
    +p.x * SIZE_AREA + SIZE_AREA,
    (+p.y + 1) * SIZE_AREA - SIZE_AREA
  ];
  const getRightLine = (p: { x: string; y: string }) => [
    +p.x * SIZE_AREA + SIZE_AREA,
    (+p.y + 1) * SIZE_AREA - SIZE_AREA - 0.5,
    +p.x * SIZE_AREA + SIZE_AREA,
    (+p.y + 1) * SIZE_AREA + 0.5
  ];
  const getTopLine = (p: { x: string; y: string }) => [
    +p.x * SIZE_AREA + SIZE_AREA,
    (+p.y + 1) * SIZE_AREA,
    +p.x * SIZE_AREA,
    (+p.y + 1) * SIZE_AREA
  ];

  clusters.forEach(c => {
    const clusterAreas = areas.filter(a => a.cluster?.id === c.id);
    if (clusterAreas.length > 0) {
      const lineOptions = (c: AreaTgType) => ({
        stroke: '#001AFF',
        hitStrokeWidth: 1,
        strokeWidth: hintAreaId === c.cluster?.id ? 1 : 0,
        id: 'clusterBorder_' + c.cluster?.id
      });

      clusterAreas.forEach(c => {
        if (!checkLeft(clusterAreas, c)) {
          const line = new Konva.Line({
            points: getLeftLine(c),
            ...lineOptions(c)
          });
          layer.add(line);
        }
        if (!checkRight(clusterAreas, c)) {
          const line = new Konva.Line({
            points: getRightLine(c),
            ...lineOptions(c)
          });
          layer.add(line);
        }
        if (!checkBottom(clusterAreas, c)) {
          const line = new Konva.Line({
            points: getBottomLine(c),
            ...lineOptions(c)
          });
          layer.add(line);
        }
        if (!checkTop(clusterAreas, c)) {
          const line = new Konva.Line({
            points: getTopLine(c),
            ...lineOptions(c)
          });
          layer.add(line);
        }
        const box = new Konva.Rect({
          x: +c.x * SIZE_AREA,
          y: (+c.y + 1) * SIZE_AREA,
          width: SIZE_AREA,
          height: SIZE_AREA,
          opacity: 1,
          scaleY: -1,
          id: 'cluster_' + c.cluster?.id
        });
        layer.add(box);
      });
    }
  });
  return layer;
};

export const showBorderCluster = (
  stage: Konva.Stage,
  clusters: ClusterTgType[],
  areas: AreaTgType[],
  hintAreaId?: string,
  setInfoState?: () => void
) => {
  stage.getLayers().forEach(c => {
    if (c.id() === CLUSTERS_LAYER_ID) {
      c.destroy();
    }
  });

  if (clusters && clusters.length) {
    let layer = new Konva.Layer({
      id: CLUSTERS_LAYER_ID
    });

    layer.destroyChildren();

    layer.on('click touchend', e => {
      layer.destroy();
      setInfoState && setInfoState();
    });

    layer = getLayerWithBorderCluster(clusters, areas, layer, hintAreaId);

    stage.add(layer);
    layer.moveToTop();
    layer.batchDraw();
    const layerGrid: Konva.Layer = stage.findOne('#grid_layer');
    layerGrid.batchDraw();
  }
};

export const resetClusterBorder = debounce(
  (stage: Konva.Stage) => {
    stage.getLayers().forEach(c => {
      if (c.id() === CLUSTERS_LAYER_ID) {
        c.destroy();
      }
    });
  },
  500,
  {
    leading: true,
    trailing: false
  }
);

export const renderClusters = (
  stage: Konva.Stage,
  clusters: ClusterTgType[],
  areas: AreaTgType[],
  onImageClick?: HandleObjectMouseType,
  hintAreaId?: string
) => {
  stage.getLayers().forEach(c => {
    if (c.id() === CLUSTERS_LAYER_ID) {
      c.destroy();
    }
  });

  if (clusters && clusters.length) {
    let layer = new Konva.Layer({
      id: CLUSTERS_LAYER_ID
    });

    layer.destroyChildren();

    layer.on('mouseover', function(e: any) {
      let box = e.target;
      const clusterId = box.id().split('_')[1];
      const clastersCollection = stage.find(`#clusterBorder_${clusterId}`);
      if (clastersCollection.length) {
        for (let i = 0; i < clastersCollection.length; i++) {
          let border = clastersCollection[i] as Konva.Image;
          border.strokeWidth(1);
          border.moveToTop();
        }
        layer.draw();
      }
    });

    layer.on('mouseout', function(e: any) {
      let box = e.target;
      const clusterId = box.id().split('_')[1];
      const clastersCollection = stage.find(`#clusterBorder_${clusterId}`);
      if (clastersCollection.length) {
        for (let i = 0; i < clastersCollection.length; i++) {
          let border = clastersCollection[i] as Konva.Image;
          border.strokeWidth(0);
        }
        layer.draw();
      }
    });

    layer.on('click', e => {
      hideHighlightBox(stage);
      if (onImageClick) {
        onImageClick(e);
      }
    });

    layer = getLayerWithBorderCluster(clusters, areas, layer, hintAreaId);

    stage.add(layer);
    layer.moveToTop();
    layer.draw();
  }
};

export const renderAreaBorder = (
  stage: Konva.Stage,
  area?: AreaTgType,
  setInfoState?: () => void
) => {
  stage.getLayers().forEach(c => {
    if (c.id() === SELECT_LAYER_ID) {
      c.destroy();
    }
  });

  if (area) {
    const layer = new Konva.Layer({
      id: SELECT_LAYER_ID
    });

    stage.add(layer);

    layer.on('click touchend', e => {
      layer.destroy();
      setInfoState && setInfoState();
    });

    const box = new Konva.Rect({
      x: +area.x * SIZE_AREA,
      y: +area.y * SIZE_AREA,
      width: SIZE_AREA,
      height: SIZE_AREA,
      stroke: '#001AFF',
      strokeWidth: 1,
      id: 'area_' + area.id
    });

    layer.add(box);
    layer.batchDraw();
    const layerGrid: Konva.Layer = stage.findOne('#grid_layer');
    layerGrid.batchDraw();
  }
};

export const renderImages = (
  stage: Konva.Stage,
  zoomLevel: number,
  areas: AreaTgType[],
  onImageClick?: HandleObjectMouseType,
  hintAreaId?: string
) => {
  return new Promise(resolve => {
    let layer: Konva.Layer = stage.findOne('#' + IMAGES_LAYER_ID);

    if (areas && areas.length) {
      if (!layer) {
        layer = new Konva.Layer({
          id: IMAGES_LAYER_ID
        });

        layer.on('click', e => {
          hideHighlightBox(stage);
          if (onImageClick) {
            onImageClick(e);
          }
        });

        layer.on('mouseover', function(e: any) {
          var box = e.target as Konva.Image;
          const areaId = box.id().split('_')[1];
          const area = areas.find(c => c.id === areaId);
          if (!oc(area).cluster.id('')) {
            box.strokeWidth(1);
            box.moveToTop();
            layer.draw();
          }
        });

        layer.on('mouseout', function(e: any) {
          var box = e.target as Konva.Image;
          const areaId = box.id().split('_')[1];
          const area = areas.find(c => c.id === areaId);
          if (!oc(area).cluster.id('')) {
            box.strokeWidth(0);
            layer.draw();
          }
        });
        stage.add(layer);
      }

      const callRenderImg = (i: number) => {
        let image: Konva.Image = stage.findOne(`#area_${areas[i].id}`);

        let selectedImage = `${URI_IPFS}/${areas[i].imageCID[0]}`;
        areas[i].imageCID.forEach(image => {
          // to do
          // if (oc(image).width(0) < zoomLevel * SIZE_AREA * 5) {
          //   selectedImage = image;
          // }
        });

        if (image) {
          if (image.attrs.path === selectedImage) {
            if (i < areas.length - 1) {
              if (i % 100 === 0) {
                layer.draw();
              }
              callRenderImg(i + 1);
            } else {
              layer.draw();
              resolve(true);
            }
            return;
          }
          image.destroy();
        }

        // renderImage(
        //   layer,
        //   selectedImage,
        //   zoomLevel,
        //   +areas[i].x * SIZE_AREA,
        //   (+areas[i].y + 1) * SIZE_AREA,
        //   SIZE_AREA,
        //   SIZE_AREA,
        //   {
        //     id: `area_${areas[i].id}`,
        //     strokeWidth: hintAreaId === areas[i].id ? 2 : 0,
        //     imageOriginalWidth: selectedImage.width,
        //     path: selectedImage.path
        //   },
        //   false
        // ).then(img => {
        //   if (i < areas.length - 1) {
        //     if (i % 100 === 0) {
        //       layer.draw();
        //     }
        //   } else {
        //     layer.draw();
        //     resolve(true);
        //   }
        // });

        // if (i < areas.length - 1) {
        //   setTimeout(() => {
        //     callRenderImg(i + 1);
        //   }, 20);
        // }

        if (i < areas.length - 1) {
          callRenderImg(i + 1);
        }
      };

      callRenderImg(0);
    } else {
      resolve(true);
    }
  });
};

export const updateImagesScale = debounce(
  (stage: Konva.Stage, zoomLevel: number, areas: AreaTgType[]) => {
    let layer: Konva.Layer = stage.findOne('#' + IMAGES_LAYER_ID);
    if (!layer) {
      return;
    }

    for (let area of areas) {
      // to do url ifps
      if (area.imageCID.length > 1) {
        let selectedImage = area.imageCID[0];
        const prevImage: Konva.Image = stage.findOne(`#area_${area.id}`);
        if (!prevImage) continue;
        const prevImageWidth = prevImage.getAttr('imageOriginalWidth');

        for (let image of area.imageCID) {
          // to do
          // if (oc(image).width(0) > zoomLevel * SIZE_AREA) {
          //   selectedImage = image;
          // }
        }

        // if (prevImageWidth !== oc(selectedImage).width(0)) {
        //   prevImage.destroy();

        //   renderImage(
        //     layer,
        //     selectedImage,
        //     zoomLevel,
        //     +area.x * SIZE_AREA,
        //     (+area.y + 1) * SIZE_AREA,
        //     SIZE_AREA,
        //     SIZE_AREA,
        //     {
        //       id: `area_${area.id}`,
        //       imageOriginalWidth: selectedImage.width
        //     }
        //   );
        // }
      }
    }

    layer.batchDraw();
  },
  500
);

export const renderRestrictedAreas = (
  stage: Konva.Stage,
  areas: AreaCoordinate[],
  onImageClick?: HandleObjectMouseType,
  hintAreaId?: string
) => {
  stage.getLayers().forEach(c => {
    if (c.id() === RESTRICTED_LAYER_ID) {
      c.destroy();
    }
  });

  if (areas && areas.length) {
    const layer = new Konva.Layer({
      id: RESTRICTED_LAYER_ID
    });

    layer.on('click', e => {
      hideHighlightBox(stage);
      if (onImageClick) {
        onImageClick(e);
      }
    });

    layer.on('mouseover', function(e: any) {
      var box = e.target as Konva.Image;
      box.strokeWidth(1);
      box.moveToTop();
      layer.draw();
    });

    layer.on('mouseout', function(e: any) {
      var box = e.target as Konva.Image;
      box.strokeWidth(0);
      layer.draw();
    });

    stage.add(layer);

    areas.forEach(c => {
      const box = new Konva.Rect({
        x: c.x * SIZE_AREA,
        y: c.y * SIZE_AREA,
        width: SIZE_AREA,
        height: SIZE_AREA,
        fill: 'rgba(0, 0, 0, 0.4)',
        stroke: 'orange',
        strokeWidth: hintAreaId === c.id ? 1 : 0,
        id: 'area_' + c.id
      });

      layer.add(box);
    });

    layer.batchDraw();
  }
};

export const renderAreas = (
  stage: Konva.Stage,
  areas: AreaTgType[],
  onImageClick?: HandleObjectMouseType,
  hintAreaId?: string
) => {
  stage.getLayers().forEach(c => {
    if (c.id() === AREAS_LAYER_ID) {
      c.destroy();
    }
  });

  if (areas && areas.length) {
    const layer = new Konva.Layer({
      id: AREAS_LAYER_ID
    });

    layer.on('click', e => {
      hideHighlightBox(stage);
      if (onImageClick) {
        onImageClick(e);
      }
    });

    layer.on('mouseover', function(e: any) {
      var box = e.target as Konva.Image;
      const areaId = box.id().split('_')[1];
      const area = areas.find(c => c.id === areaId);
      if (!oc(area).cluster.id('')) {
        box.strokeWidth(1);
        box.moveToTop();
        layer.draw();
      }
    });

    layer.on('mouseout', function(e: any) {
      var box = e.target as Konva.Image;
      const areaId = box.id().split('_')[1];
      const area = areas.find(c => c.id === areaId);
      if (!oc(area).cluster.id('')) {
        box.strokeWidth(0);
        layer.draw();
      }
    });
    stage.add(layer);

    areas.forEach(c => {
      const box = new Konva.Rect({
        x: +c.x * SIZE_AREA,
        y: +c.y * SIZE_AREA,
        width: SIZE_AREA,
        height: SIZE_AREA,
        fill: 'rgba(0, 0, 0, 0)',
        stroke: 'green',
        strokeWidth: hintAreaId === c.id ? 1 : 0,
        id: 'area_' + c.id
      });

      layer.add(box);
    });

    layer.batchDraw();
  }
};

export const hideHighlightBox = (stage: Konva.Stage) => {
  const boxes = stage.find((c: Konva.Node) =>
    c.id().startsWith('hightlightboxes')
  );
  if (boxes.length) {
    boxes.forEach(c => {
      const boxLayer = c.getLayer();
      c.destroy();
      if (boxLayer) {
        boxLayer.batchDraw();
      }
    });
  }
};

export const renderScene = (
  stage: Konva.Stage,
  options: RenderSceneOptions
): void => {
  const handleWheel = (e: Konva.KonvaEventObject<WheelEvent>) => {
    e.evt.preventDefault();
    const zoom = handleZoom(stage, e.evt.deltaY > 0)();
    if (options.onWheel) {
      options.onWheel(e, zoom, stage);
    }
  };

  const macroblocksLayer = new Konva.Layer({
    id: `macroblocksLayer`
  });
  options.macroblockScaleList.forEach(i => {
    const group = new Konva.Group({
      id: `${MACROBLOCK_GROUP_ID}_${i}`
    });
    macroblocksLayer.add(group);
  });
  stage.add(macroblocksLayer);

  renderGrid(
    stage,
    options.width,
    options.height,
    options.onFieldTouchend,
    options.onFieldClick,
    options.getMultipleState,
    options.onMultipleSelect,
    options.renderClusterBorder
  );

  stage.on('wheel', handleWheel);
  stage.on('dragstart', e => {
    if (options.onDragStart) {
      options.onDragStart();
    }
    if (options.getMultipleState && options.getMultipleState()) {
      return;
    }
    hideHighlightBox(stage);
  });
};
