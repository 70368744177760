export const distanceSort = (
  x1: number,
  x2: number,
  y1: number,
  y2: number
) => {
  return (x1 - x2) ** 2 + (y1 - y2) ** 2;
};

export const getCurrentTime = (timeCorrection: number) => {
  return Math.round(Date.now() / 1000) + timeCorrection;
};

export const decimalToHexadecimal = (value: string | number): string => {
  return `0x${Number(value).toString(16)}`;
};

export const mergeArrWithReplacement = <T extends { id: string }>(
  arr1: T[],
  arr2: T[]
) => {
  const result: T[] = [];
  arr1.forEach(i => {
    const match = arr2.find(j => j.id === i.id);
    if (match) {
      result.push(match);
    } else {
      result.push(i);
    }
  });

  arr2.forEach(i => {
    const match = result.find(j => j.id === i.id);
    if (!match) {
      result.push(i);
    }
  });
  return result;
};
