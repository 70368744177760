import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';

import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import how_to_set_an_avatar_1_1 from '../../../Resources/Instructions/how_to_set_an_avatar_1_1.png';
import how_to_set_an_avatar_2_1 from '../../../Resources/Instructions/how_to_set_an_avatar_2_1.png';
import how_to_set_an_avatar_3_1 from '../../../Resources/Instructions/how_to_set_an_avatar_3_1.png';
import how_to_set_an_avatar_3_2 from '../../../Resources/Instructions/how_to_set_an_avatar_3_2.png';
import s from '../Instructions.scss';

const HowToSetAvatarUsername = () => {
  const { t } = useTranslation();
  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('how_to_set_avatar_and_username')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_is_this_guide_about')}
            text={t('what_this_guide_is_about_set_avatar_username')}
          />
        </Grid>
        <Grid item md={6}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_the_wall_global')}
            text={t('the_wall_is_a_virtual')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={s.elementorSection}>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('what_is_needed')}</Typography>
          <ol>
            <li>
              <Typography variant="body1">{t('what_is_needed_1')}</Typography>
            </li>
            <li>
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{ __html: t('what_is_needed_2') }}
                />
              </Typography>
            </li>
            <li>
              <Typography variant="body1">{t('what_is_needed_3')}</Typography>
            </li>
          </ol>
        </Grid>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('how_to_start')}</Typography>
          <Typography variant="body1" className={s.infobox2}>
            <span dangerouslySetInnerHTML={{ __html: t('how_to_start_1') }} />
          </Typography>
          <Typography variant="body1">{t('how_to_start_2')}</Typography>
        </Grid>
        <Grid item md={4} xs={12} className={s.backgroundColor2}>
          <Infobox
            icon="faLightBulb"
            title={t('good_to_know')}
            text={t('click_on_a_screenshot_to_zoom')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">{t('step_by_step_instruction')}</Typography>
        <Grid container spacing={2}>
          <Grid item md={3} />
          <Grid item md={6}>
            <Infobox num={1} text={t('how_to_set_an_avatar_1')} />
            <FancyBoxImage image={how_to_set_an_avatar_1_1} />
          </Grid>
          <Grid item md={3} />
          <Grid item md={3} />
          <Grid item md={6}>
            <Infobox num={2} text={t('how_to_set_an_avatar_2')} />
            <FancyBoxImage image={how_to_set_an_avatar_1_1} />
          </Grid>
          <Grid item md={3} />
          <Grid item md={3} />
          <Grid item md={6}>
            <Infobox num={3} text={t('how_to_set_an_avatar_3')} />
          </Grid>
          <Grid item md={3} />
          <Grid item md={6}>
            <FancyBoxImage image={how_to_set_an_avatar_3_1} />
          </Grid>
          <Grid item md={6}>
            <FancyBoxImage image={how_to_set_an_avatar_3_2} />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default HowToSetAvatarUsername;
