import React from 'react';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import { observer } from 'mobx-react-lite';
import { NavLink as RouterLink } from 'react-router-dom';
import { useStores } from '../../../Hooks/useStores';

interface AreaCollateralLinkProps {
  areaId: number | null;
}

const AreaCollateralLink: React.FC<AreaCollateralLinkProps> = ({ areaId }) => {
  const { t } = useTranslation();
  const { tgWallStore } = useStores();

  if (!areaId) return null;
  const areaCollateral = oc(tgWallStore)
    .areas.value.areas([])
    .find(i => +i.id === areaId);
  if (areaCollateral) {
    return (
      <Link
        to={`/?areaId=${areaId}`}
        variant="body1"
        underline="none"
        component={RouterLink}
      >
        {t('area_at')} ({areaCollateral.x}, {areaCollateral.y})
      </Link>
    );
  }
  return null;
};

export default observer(AreaCollateralLink);
