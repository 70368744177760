import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Rawline',
    fontSize: 14,
    htmlFontSize: 16,
    body1: {
      lineHeight: 1.5,
      fontWeight: 500
    },
    h3: {
      fontWeight: 700
    },
    h6: {
      fontWeight: 700
    },
    button: {
      fontSize: '0.75rem',
      textTransform: 'none',
      fontWeight: 700
    }
  },
  palette: {
    text: {
      primary: '#000',
      secondary: 'rgba(0,0,0,0.7)'
    },
    primary: {
      main: '#001AFF'
    },
    secondary: {
      main: '#EFEFEF'
    }
  },
  overrides: {
    MuiChip: {
      root: {
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: 0
      },
      label: {
        paddingLeft: 8,
        paddingRight: 8
      },
      colorSecondary: {
        backgroundColor: '#fff',
        color: '#000'
      },
      deleteIcon: {
        color: 'rgba(255, 255, 255, 0.7)',
        width: 14,
        marginLeft: 0,
        '&:hover': {
          color: 'rgba(255, 255, 255, 1)'
        }
      }
    },
    MuiInputBase: {
      root: {
        color: '#000'
      },
      input: {
        fontWeight: 500
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 700,
        borderRadius: 0,
        minHeight: 40,
        minWidth: 100
      },
      outlined: {
        borderRadius: 0,
        borderWidth: 2,
        borderColor: '#DDD',
        color: '#000'
      },
      outlinedPrimary: {
        border: '2px solid #001AFF',
        '&:hover': {
          border: '2px solid #001AFF'
        }
      },
      containedPrimary: {
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: '#fff'
        }
      },
      containedSecondary: {
        color: '#001AFF',
        textTransform: 'uppercase'
      },
      containedSizeLarge: {
        fontSize: '1rem'
      },
      containedSizeSmall: {
        minWidth: 100
      },
      contained: {
        '&$disabled': {
          backgroundColor: 'rgba(255, 255, 255, 0.5)'
        }
      }
    },
    MuiInput: {
      colorSecondary: {
        '&.MuiInput-underline:after': {
          borderBottomColor: '#000'
        }
      },
      underline: {
        '&:hover:not(.Mui-disabled):before': {
          borderColor: '#000'
        },
        '&:before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
        }
      }
    },
    MuiExpansionPanel: {
      root: {
        backgroundColor: 'transparent',
        '&:before': {
          backgroundColor: 'none'
        },
        '&.Mui-expanded': {
          margin: 0
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
        '&.Mui-expanded': {
          minHeight: 48
        }
      },
      content: {
        padding: 0,
        '&.Mui-expanded': {
          marginTop: 12
        }
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        paddingRight: 0,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        borderLeft: '1px solid rgba(0,0,0,0.1)'
      }
    },
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '0.75rem',
        lineHeight: '1rem'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }
    }
  }
});

export default theme;
