import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OffersItem from './OffersItem/OffersItem';
import { RemoteDataState } from '../../../../Utils/RemoteData';
import { oc } from 'ts-optchain';
import { useStores } from '../../../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import DescriptionPanel from '../../../../Components/Defi/DescriptionPanel/DescriptionPanel';
import s from './MyLoans.scss';
import sDefi from '../../Defi.scss';

interface MyLoansProps {}

const MyLoans: React.FC<MyLoansProps> = () => {
  const { t } = useTranslation();
  const { wallStore, tgLoanStore } = useStores();

  const disableTransactions =
    !wallStore.currentdAddress || !wallStore.networkVersionTrue;

  useEffect(() => {
    if (wallStore.currentdAddress) {
      tgLoanStore.getBorrowerOffers(wallStore.currentdAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallStore.currentdAddress]);

  useEffect(() => {
    if (
      tgLoanStore.borrowerOffers.state === RemoteDataState.SUCCESS ||
      tgLoanStore.borrowerOffers.state === RemoteDataState.FAILURE
    ) {
      setTimeout(
        () =>
          tgLoanStore.getBorrowerOffers(wallStore.currentdAddress as string),
        60000
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgLoanStore.borrowerOffers.state]);

  const offers = oc(tgLoanStore).borrowerOffers.value([]);

  return (
    <div className={s.main}>
      <div className={s.left}>
        <div className={s.tableContent}>
          {offers.length ? (
            offers.map(i => (
              <OffersItem offer={i} disabled={disableTransactions} key={i.id} />
            ))
          ) : (
            <div className={sDefi.empty}>
              <Typography variant="h6">
                {t('your_loans_positions_will_appear_here')}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div className={sDefi.rightPageContent}>
        <DescriptionPanel content={t('my_loans_description')} />
      </div>
    </div>
  );
};

export default observer(MyLoans);
